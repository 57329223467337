import React, {useEffect, useState} from 'react';
import Header from "../../components/header/Header";
import {
    areNumberedInTicketList, areResaleInTicketList,
    checkTicketsAvailableAndGoToBuy,
    loadEventDetail,
    loadTicketEventByDate
} from "../../solutions/controllers/EventDetailsController";
import {IEventDetails} from "../../solutions/interfaces/IEventDetails";
import styles from './EventDetails.module.css';
import SimpleMap from "../../components/map/Map";
import {Button, Col, Form, Input, message, Row, Select, Space} from "antd";
import {ITicketQuantities} from "../../solutions/interfaces/ITicketQuantities";
import locationImage from '../../assets/grey_location.svg';
import calendarImage from '../../assets/material-symbols_calendar-month.svg';
import clockImage from '../../assets/tabler_clock-hour-1.svg';
import ticketImage from '../../assets/ion_ticket.svg';
import addImage from '../../assets/add.svg';
import {useDispatch} from "react-redux";
import {useLocation, useMatches, useNavigate} from "react-router-dom";
import {IEventDate} from "../../solutions/interfaces/IEventDate";
import {ITicketByEventDate} from "../../solutions/interfaces/ITicketByEventDate";
import {
    calculateMoneyAmountToBuy,
    checkIBuyButtonIsAvailable,
    getTicketListPerQuantity,
    setBuyFormValueIsValid,
    updateTicketQuantities
} from "../../solutions/controllers/BuyController";
import {ITicket} from "../../solutions/interfaces/ITicket";
import {
    capitalizeTitle,
    formatDateToString,
    formateDatesToString,
    formatHourToString,
    generateEventStructuredData
} from "../../solutions/Utils";
import minusBtn from '../../assets/minusBtn.png';
import plusBtn from '../../assets/plusBtn.png';
import {Seat} from "../../components/seatReservation/model/Seat";
import {SeatReservation} from "../../components/seatReservation/SeatReservation";
import {INewsType} from "../../solutions/interfaces/INewsType";
import OfferNews from "../../components/offerNews/OfferNews";
import {checkSeatRules} from "../../components/seatReservation/action";
import {imagesPath} from "../../Controller/Settings";
import ReactPixel from "react-facebook-pixel";
import { Helmet } from 'react-helmet';

export default function EventDetails() {

    const [event, setEvent] = useState<IEventDetails | undefined>(undefined);
    const [dates, setDates] = useState<IEventDate[]>([])
    const [selectedDate, setSelectedDate] = useState<IEventDate | undefined>(undefined);
    const [eventDateFormatted, setEventDateFormatted] = useState('');
    const [ticketQuantities, setTicketQuantities] = useState<ITicketQuantities>({});
    const [numberedTickets, setNumberedTickets] = useState<Seat[]>([])
    const [seatsBySector, setSeatsBySector] = useState({});
    const [ticketEventByDates, setTicketEventByDates] = useState<ITicketByEventDate | undefined>(undefined)
    const [canBuy, setCanBuy] = useState(false);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const initialMatches: any = useMatches();
    const navigate = useNavigate();
    const [areNumbered, setAreNumbered] = useState(false);
    const [form] = Form.useForm();
    const [presaleCode, setPresaleCode] = useState("");
    const [loading, setLoading] = useState(true);
    const [hasResaleTickets, setHasResaleTickets] = useState(false);

    useEffect(() => {
        loadEventDetail(initialMatches[0].params.eventId, setEvent, dispatch);
    }, [initialMatches[0].params.eventId]);

    useEffect(() => {
        if (event) {
            if(event.hidden){
                navigate("/", {})
            }
            else{
                if(event.dates){
                    setDates(event.dates)
                    const selectedDateObj = event.dates[0];
                    if (selectedDateObj) {
                        setSelectedDate(selectedDateObj);
                    }
                    formateDatesToString(event.dates, setEventDateFormatted)
                }
            }

            try{
                if(event.pixelId){
                    ReactPixel.init(event.pixelId, undefined, {
                        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                        debug: true, // enable logs
                    });
                    ReactPixel.pageView(); // For tracking page view
                }

            }catch (e){
                console.error("Error in react pixel event detail success ",e)
            }
        }

    }, [event])

    useEffect(() => {
        if (dates) {
            const selector = document.querySelector('.ant-select-selector');
            const selectorArrow = document.querySelector('.ant-select-arrow');
            if (selector && selectorArrow) {
                // @ts-ignore
                selector.style.background = '#2A2727';
                // @ts-ignore
                selector.style.color = 'white';
                // @ts-ignore
                selectorArrow.style.color = 'white';
            }
        }
    }, [dates]);

    useEffect(() => {
        if (selectedDate && selectedDate.eventdateid) {
            //@ts-ignore
            loadTicketEventByDate(selectedDate.eventdateid, ticketEventByDates, setTicketEventByDates, dispatch);
        }
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate && selectedDate.eventdateid && ticketEventByDates && ticketEventByDates[selectedDate.eventdateid]) {
            //@ts-ignore
            areNumberedInTicketList(setAreNumbered, ticketEventByDates[selectedDate.eventdateid]);
            areResaleInTicketList(selectedDate.eventdateid, setHasResaleTickets, dispatch);
            setLoading(false);
        }
    }, [ticketEventByDates])

    useEffect(() => {
        if (ticketEventByDates && ticketQuantities && selectedDate) {
            checkIBuyButtonIsAvailable(ticketQuantities, numberedTickets, setCanBuy)
            //@ts-ignore
            const tickets: ITicket[] = Object.keys(ticketEventByDates).flatMap(value => ticketEventByDates[value]);
            let amountToPay = calculateMoneyAmountToBuy(tickets, ticketQuantities, numberedTickets);
            setTotal(amountToPay)
        } else {
            setTotal(0)
        }
    }, [ticketQuantities, numberedTickets])


    const buyTicket = () => {
        if(checkSeatRules(numberedTickets, seatsBySector)){
            checkTicketsAvailableAndGoToBuy(ticketQuantities, numberedTickets, (uuid: string) => {
                if (uuid) {
                    setBuyFormValueIsValid(false);
                    goToBuy(uuid);
                }
            }, dispatch)
        }
    }

    const goToBuy = (uuid: string) => {
        //@ts-ignore
        const tickets: ITicket[] = Object.keys(ticketEventByDates!).flatMap(value => ticketEventByDates![value]);
        let ticketList = getTicketListPerQuantity(tickets, ticketQuantities);

        navigate("/buyGlobal", {
            state:
                {
                    eventId: event?.eventId,
                    pixelId: event?.pixelId,
                    fromResale: false,
                    rrpp:initialMatches[0].params.rrpp ? initialMatches[0].params.rrpp:null,
                    paymentDetails: event?.paymenttypedetails,
                    tickets: ticketList,
                    numberedTickets: numberedTickets.map((numTicket) => ({
                        //TODO hay que mapearlo porque si se recarga la página no deja acceder a las propiedades publicas
                        id: numTicket.id,
                        price: numTicket.price,
                        commission: numTicket.commission,
                        description: numTicket.description,
                        nominative: numTicket.nominative,
                        ticketeventid: numTicket.ticketeventid,
                        handicapped: numTicket.handicapped,
                        //@ts-ignore
                        ticketeventextras: tickets.filter(t => t.id === numTicket.ticketeventid)[0].ticketeventextras
                    })),
                    uuid: uuid
                }
        })
    }

    const incrementTicketQuantity = (ticket: ITicket) => {
        let previousQuantity = ticketQuantities[ticket.id] || 0;

        if(previousQuantity >= (ticket.maxticketsperbuy ? ticket.maxticketsperbuy : 10)){
            message.warning("No puede seleccionar más entradas del mismo tipo.")
        }
        else{
            previousQuantity++;
            updateTicketQuantities(ticket.id, previousQuantity, setTicketQuantities);
        }
    };

    const decrementTicketQuantity = (ticket: ITicket) => {
        let previousQuantity = ticketQuantities[ticket.id] || 0;
        if (previousQuantity > 0) {
            previousQuantity--;
            updateTicketQuantities(ticket.id, previousQuantity, setTicketQuantities);
        }
    };

    function seatReservationChange(seats: Seat[]) {
        setNumberedTickets(seats)
    }
    type FieldType = {
        code?:string;
    };
    function getEventDetails() {
        return <>
            <Row style={{display: "flex", alignItems: "center"}} className={styles.boxContainer}>
                <Col xs={4} sm={4} md={3} lg={3} xl={2} xxl={2} style={{height: 25}}>
                    <img alt="example" src={locationImage} className={styles.boxImage}/>
                </Col>
                <Col xs={20} sm={20} md={21} lg={21} xl={22} xxl={22}>
                    <p className={styles.textBox}>{event?.town.town}</p>
                </Col>
            </Row>
            <Row style={{display: "flex", alignItems: "center"}} className={styles.boxContainer}>
                <Col xs={4} sm={4} md={3} lg={3} xl={2} xxl={2} style={{height: 25}}>
                    <img alt="example2" src={clockImage} className={styles.boxImage}/>
                </Col>
                <Col xs={20} sm={20} md={21} lg={21} xl={22} xxl={22}>
                    <p className={styles.textBox}>{selectedDate ? formatHourToString(selectedDate.date) : ''}</p>
                </Col>
            </Row>
            <Row style={{display: "flex", alignItems: "center"}} className={styles.boxContainer}>
                <Col xs={4} sm={4} md={3} lg={3} xl={2} xxl={2} style={{height: 25}}>
                    <img alt="example3" src={calendarImage} className={styles.boxImage}/>
                </Col>
                <Col xs={20} sm={20} md={21} lg={21} xl={22} xxl={22}>
                    <p className={styles.textBox}>{eventDateFormatted}</p>
                </Col>
            </Row>
        </>;
    }

    function manageCode() {
        form.validateFields().then((values)=> {
            //@ts-ignore
            if(selectedDate && selectedDate.inPresale){
                //@ts-ignore
                if(values.code !== selectedDate.presaleCode){
                    message.error("El código de acceso anticipado introducido no es válido.")
                }
                setPresaleCode(values.code)
            }
        }).catch(reason => message.error("Debe rellenar el campo para el acceso anticipado"))
    }

    function goToResaleTickets(eventId: any) {
        navigate('/buyResaleTickets/'+eventId);
    }
    const location = useLocation();

    return (
        event ?
            <div className={`${styles.background} footerSeparation`}>
                <Helmet>
                    <title>{capitalizeTitle(event?.title)}</title>
                    <meta name="description" content={"Comprar entradas para " + capitalizeTitle(event?.title) + " el " + formatDateToString(event?.dates[0].date)} />
                    <script type="application/ld+json">
                        {JSON.stringify(generateEventStructuredData(event, `${window.location.origin}${location.pathname}`))}
                    </script>
                </Helmet>
                <Row style={{paddingBottom: 30, paddingTop: 0}} justify={'center'}>
                    <Col span={24}>
                        <OfferNews newsType={INewsType.EVENT_DETAILS} eventId={event.eventId}></OfferNews>
                        <Header showFilters={false}></Header>
                    </Col>
                </Row>

                <Row style={{marginTop: 0}} justify={'center'}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{textAlign: "center"}}>
                        <p className={styles.eventTitle}>{event.title}</p>
                    </Col>
                </Row>
                <Row style={{marginTop: 20}} className={styles.centralRow}>
                    {/*//Vista para ordenador*/}
                    <Col xs={{span: 0, offset: 0}}
                         sm={{span: 0, offset: 0}}
                         md={{span: 20, offset: 0}}
                         lg={{span: 18, offset: 0}}
                         xl={{span: 16, offset: 0}}
                         xxl={{span: 14, offset: 0}}
                    >
                        <Row justify={"center"} style={{alignItems: "center", marginBottom: 30}}>
                            <Col className={styles.imageContainer} >
                                <img alt="example"
                                     src={event ? (imagesPath + event.image) : ''}
                                     className={styles.eventImage}/>
                            </Col>
                            <Col
                                 md={{span: 9, offset: 4}}
                                 lg={{span: 8, offset: 5}}
                                 xl={{span: 7, offset: 6}}
                                 xxl={{span: 6, offset: 8}}
                                 style={{paddingLeft: 15, paddingRight: 15}}>
                                {getEventDetails()}
                            </Col>
                        </Row>
                    </Col>

                    {/*//Vista para movil*/}
                    <Col xs={{span: 23, offset: 1}} sm={{span: 23, offset: 1}} md={{span: 0, offset: 0}}
                         lg={{span: 0, offset: 0}} xl={{span: 0, offset: 0}} xxl={{span: 0, offset: 0}}>
                        <Row justify={"center"} style={{alignItems: "center", marginBottom: 30}}>
                            <Col span={10}>
                                <img alt="example"
                                     src={event ? (imagesPath + event.image) : ''}
                                     className={styles.eventImageMobile}/>
                            </Col>
                            <Col span={14} style={{paddingLeft: 15, paddingRight: 15}}>
                                {getEventDetails()}
                            </Col>
                        </Row>
                    </Col>

                    <Col
                        xs={{span: 22, offset: 0}} sm={{span: 21, offset: 1}} md={{span: 15, offset: 0}}
                        lg={{span: 14, offset: 0}} xl={{span: 12, offset: 0}} xxl={{span: 11, offset: 0}}>

                        {loading && <Row className={styles.ticketBox}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{marginTop: 10, paddingLeft: 15}}>
                                <p style={{width: '100% !important'}} className={styles.ticketTitle}>Cargando entradas...</p>
                            </Col>
                        </Row>
                        }
                        {!loading &&
                        <>
                            {(dates.length > 1 && selectedDate) ? (
                                <Row className={styles.dateBox}>
                                    <p className={styles.ticketTitle}>Seleccione fecha</p>
                                    <Select
                                        defaultValue={selectedDate.eventdateid}
                                        onChange={(value, option) => {
                                            if ("dates" in event) {
                                                const selectedDateObj = event.dates.find(dateObj => dateObj.eventdateid === value);
                                                setSelectedDate(selectedDateObj);
                                            }
                                        }}
                                        // className={styles.selectDateInput}
                                        style={{width: '100%'}}
                                        options={dates.map((d) => {
                                            return {
                                                label: formatDateToString(d.date),
                                                value: d.eventdateid
                                            }
                                        })}
                                    />
                                </Row>
                            ) : ''}

                            {selectedDate && (!ticketEventByDates || !ticketEventByDates[selectedDate.eventdateid] || ticketEventByDates[selectedDate.eventdateid].length === 0) ?
                                <Row className={styles.ticketBox}>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} style={{marginTop: 10, paddingLeft: 15}}>
                                        <p style={{width: '100% !important'}} className={styles.ticketTitle}>No hay
                                            entradas para la fecha seleccionada.</p>
                                    </Col>
                                </Row> : <></>}

                            {hasResaleTickets?(
                                <Row className={styles.ticketBox} style={{paddingBottom: 0}}>
                                    <Row style={{ alignItems: "center", width:'100%' }}>
                                        <Col xs={{offset:1,span:2}} sm={{offset:1,span:2}} md={{offset:1,span:2}} lg={{offset:1,span:2}} xl={{offset:1,span:2}}  xxl={{offset:1,span:2}}>
                                            <img alt="ticketImg" src={ticketImage} className={styles.boxImage}
                                                 style={{marginLeft: 0}}/>
                                        </Col>
                                        <Col xs={{span:11,offset:0}} sm={{span:15,offset:0}} md={{span:15,offset:0}} lg={{span:15,offset:0}} xl={{span:13,offset:0}}
                                             xxl={{span:13,offset:0}}>
                                            <p className={styles.ticketTitle}>ENTRADAS REVENTA VERIFICADAS</p>
                                        </Col>
                                        {event?(
                                            <Col style={{paddingRight:5, display:"inline-table"}} xs={{span: 8, offset: 2 }} sm={{span: 3, offset: 2 }} md={{span: 3, offset: 1 }} lg={{span: 3, offset: 2 }} xl={{span: 5, offset: 2 }}  xxl={{ span: 5, offset: 2 }}>
                                                <Button style={{marginBottom:0}}  type={'default'} className={'defaultButton'} onClick={()=>goToResaleTickets(event.eventId)}>Ver entradas
                                                </Button>
                                            </Col>
                                        ):(<></>)}
                                    </Row>
                                </Row>
                            ):(<></>)}

                            {/*//TODO hacer esto bien sin repetir codigo, no me molesto porque hay que inscrustar aqui el estadio*/}
                            {areNumbered && selectedDate && ticketEventByDates && ticketEventByDates[selectedDate.eventdateid] &&
                                <Row className={styles.ticketBox}>
                                    <Col span={24} style={{paddingBottom: 20}}>
                                        <SeatReservation
                                            fromResale={false}
                                            maxSeatsSelection={10}
                                            eventdate={selectedDate.eventdateid}
                                            onSeatReservationChanged={seatReservationChange}
                                            onCloseSector={(seats) => {
                                                if(seats && Object.keys(seats).length > 0) {
                                                    try {
                                                        setSeatsBySector({...seatsBySector,  [Object.keys(seats)[0]] : seats[Object.keys(seats)[0]]})
                                                    }catch (e){
                                                        console.error(e)
                                                    }
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            }

                            {
                                selectedDate
                                //@ts-ignore
                                && (selectedDate.inPresale && (selectedDate.presaleCode !== presaleCode))
                                && <Row className={styles.ticketBox} style={{paddingTop:10,paddingBottom:20}}>
                                    <Form
                                        form={form}
                                        name="registerForm"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width:'100%' }}
                                        initialValues={{ remember: true }}
                                        autoComplete="off"
                                    >
                                        <Row justify={'center'} style={{width:'100%'}}>
                                            <Col xs={0} sm={0} md={18} lg={18} xl={18} xxl={18} >
                                                <Form.Item<FieldType>

                                                    label={<p className={styles.label}>Introduce tu código de acceso a preventa</p>}
                                                    name="code"
                                                    style={{ width: '100%' }}
                                                    rules={[{ message: 'Por favor introduce un código válido', type:'string' }]}
                                                >
                                                    <Input className={styles.input} placeholder={''} style={{width:'100%'}} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={22} sm={22} md={0} lg={18} xl={0} xxl={0} >
                                                <Form.Item<FieldType>

                                                    label={<p className={styles.label} style={{fontSize:12}}>Introduce tu código de acceso a preventa</p>}
                                                    name="code"
                                                    style={{ width: '100%' }}
                                                    rules={[{ message: 'Por favor introduce un código válido', type:'string' }]}
                                                >
                                                    <Input className={styles.input} placeholder={''} style={{width:'100%', fontSize: 16}}/>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                        <Row justify={'center'}>
                                            <Col xs={22} sm={22} md={18} lg={18} xl={18} xxl={18}>
                                                <Button type={"primary"} htmlType="submit" className={styles.codeButton} onClick={manageCode}>Acceder</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Row>
                            }

                            {!areNumbered
                            && selectedDate
                            //@ts-ignore
                            && (!selectedDate.inPresale || (selectedDate.inPresale && selectedDate.presaleCode === presaleCode))
                            && ticketEventByDates
                            && ticketEventByDates[selectedDate.eventdateid] ? (
                                    ticketEventByDates[selectedDate.eventdateid].map((ticket: any, index: number) => (
                                        <Row className={styles.ticketBox}>
                                            <Row style={{ alignItems: "center", width:'100%' }}>
                                                <Col xs={{offset:1,span:2}} sm={{offset:1,span:2}} md={{offset:1,span:2}} lg={{offset:1,span:2}}
                                                     xl={{offset:1,span:2}}
                                                     xxl={{offset:1,span:2}}>
                                                    <img alt="ticketImg" src={ticketImage} className={styles.boxImage}
                                                         style={{marginLeft: 0}}/>
                                                </Col>
                                                <Col xs={{span:15,offset:0}} sm={{span:15,offset:0}} md={{span:15,offset:0}} lg={{span:15,offset:0}}
                                                     xl={{span:15,offset:0}}
                                                     xxl={{span:15,offset:0}}>
                                                    <p className={styles.ticketTitle}>{ticket.ticketname}</p>
                                                </Col>
                                                <Col xs={{span: 6, offset: 0 }}
                                                     sm={{span: 3, offset: 2 }}
                                                     md={{span: 5, offset: 1 }} lg={{span: 3, offset: 2 }}
                                                     xl={{span: 4, offset: 2 }}
                                                     xxl={{ span: 4, offset: 2 }}>
                                                    <p style={{width: '100%', textAlign: "center"}} className={styles.ticketPrice}>{ticket.price}€</p>
                                                </Col>
                                            </Row>
                                            <Row style={{  width:'100%' }} align={'middle'} >
                                                <Col xs={{span:14,offset:3}} sm={{span:15,offset:3}} md={{span:15,offset:3}} lg={{span:15,offset:3}} xl={{span:15,offset:3}} xxl={{span:15,offset:3}} >
                                                    <p className={styles.ticketMoreInfo} >{ticket.description}   </p>
                                                </Col>

                                                {!ticket.numbered ?(
                                                    (ticket.status === 'AVAILABLE' || ticket.status === 'LAST_UNITS')?(
                                                        <Col xs={{span:6,offset:1}} sm={{span:5,offset:1}} md={{span:6,offset:0}} lg={{span:5,offset:1}}
                                                             xl={{span:4,offset:2}} xxl={{span:4,offset:2}} style={{display: "flex", flexDirection: "column", alignContent:"center", flexWrap: "wrap"}}>
                                                            <Space style={{marginTop:6}} >

                                                                <Button type="text" className={styles.addLessTouchable}
                                                                        onClick={() => decrementTicketQuantity(ticket)}>
                                                                    <img className={styles.addText} src={minusBtn}/>
                                                                </Button>

                                                                <span className={styles.ticketQuantity}>{ticketQuantities[ticket.id] || 0}</span>

                                                                <Button type="text" className={styles.addLessTouchable}
                                                                        onClick={() => incrementTicketQuantity(ticket)}>
                                                                    <img className={styles.addText} src={plusBtn}/>
                                                                </Button>
                                                            </Space>
                                                        </Col>
                                                    ):(
                                                        <Col  style={{display: "flex", flexDirection: "column", alignContent:"center", flexWrap: "wrap", paddingRight:5}}
                                                              xs={{span:6,offset:1}}
                                                              sm={{span:5,offset:1}}
                                                              md={{span:6,offset:0}}
                                                              lg={{span:5,offset:1}}
                                                              xl={{span:4,offset:2}}
                                                              xxl={{span:4,offset:2}}
                                                        >
                                                            {ticket.status === 'ALL_RESERVED' ? <p className={styles.ticketReserved}>RESERVADAS</p> : <p className={styles.ticketExhausted}>AGOTADAS</p>}
                                                        </Col>
                                                    )

                                                ):<></>}
                                            </Row>
                                        </Row>
                                    ))
                                )
                                : (
                                    ''
                                )}

                            {selectedDate
                            && ticketEventByDates
                                //@ts-ignore
                            && (!selectedDate.inPresale || (selectedDate.inPresale && selectedDate.presaleCode === presaleCode))
                            && ticketEventByDates[selectedDate.eventdateid]
                            && ticketEventByDates[selectedDate.eventdateid].length > 0
                                ? (
                            <Row className={styles.buttonBox} justify={'center'}>
                                <Col xs={{span:14,offset:1}} sm={14} md={14} lg={12} xl={10} xxl={8} style={{textAlign: "center"}}>
                                    <Button  type={'default'} disabled={!canBuy} onClick={buyTicket}
                                             className={canBuy?'defaultButton':'buttonNotVisible'}
                                             style={!canBuy ? { color: 'white', backgroundColor: 'grey', borderColor: 'grey' } : {}}
                                            >{total.toFixed(2)}€ - Continuar
                                    </Button>
                                </Col>
                            </Row>) : <></>
                            }
                        </>}
                    </Col>
                </Row>

                <Row justify={'center'} style={{marginTop: 30}}>
                    <Col xs={{span: 23}} sm={{span: 23}} md={{span: 21}} lg={{span: 19}} xl={{span: 17}}
                         xxl={{span: 16}} className={styles.descriptionBox}>
                        <div dangerouslySetInnerHTML={{__html: event.description ? event.description : `<p></p>`}}
                             className={styles.descriptionContent}></div>
                    </Col>
                </Row>

                <Row justify={'center'} style={{marginTop: 30}}>
                    <Col xs={{span: 23}} sm={{span: 23}} md={{span: 21}} lg={{span: 19}} xl={{span: 17}}
                         xxl={{span: 16}} className={styles.descriptionBox}>
                        <p className={styles.descriptionContent}>Dirección completa: {event.address}</p>
                    </Col>
                </Row>

                <Row justify={'center'} style={{marginTop: 30}}>
                    <Col xs={{span: 23}} sm={{span: 23}} md={{span: 21}} lg={{span: 19}} xl={{span: 17}}
                         xxl={{span: 16}}>
                        <SimpleMap longitude={event.longitude} latitude={event.latitude}/>
                    </Col>
                </Row>

                <Row style={{paddingBottom: 0}} justify={'center'} align={'middle'}>
                    <Col xxl={1}>
                        <div className={styles.addImageContainer}>
                            <img width={24} height={24} src={addImage} alt={'addImage'}/>
                        </div>

                    </Col>
                    <Col xs={{span: 18}} sm={{span: 16}} md={{span: 14}} lg={{span: 12}} xl={{span: 10}}
                         xxl={{span: 8}}>
                        <h1 className={styles.questionText}>¿Quieres conocer más eventos similares
                            de {event.promoterName}?</h1>
                    </Col>
                </Row>
            </div>
            : <></>
    );
}
