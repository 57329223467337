import styles from "./ChangeTicketOwnership.module.css";
import {Button, Checkbox, Col, Form, message, Row, Space} from "antd";
import React, {useEffect, useRef, useState} from "react";
import warningImage from "../../assets/bi_exclamation.svg";
import RegisterForm from "../../components/registerForm/RegisterForm";
import {useNavigate, useParams} from "react-router-dom";
import {
    changeTicketOwnership,
    changeTicketOwnershipInsite,
    changeTicketOwnershipRedirect,
    loadTicketDetails
} from "../../solutions/controllers/TickeDetailsController";
import {useDispatch, useSelector} from "react-redux";
import {ITicketProfileDetails} from "../../solutions/interfaces/ITicketProfileDetails";
import {ChangeOwnershipDTO} from "../../solutions/interfaces/DTO/ChangeOwnershipDTO";
import {calculateAmountToPay, manageIsRenameFree} from "../../solutions/controllers/ChangeTicketOwnershipController";
import {
    calculateAmountToPayWithCommission,
    deleteUuid,
} from "../../solutions/Utils";
import {TaxType} from "../../solutions/interfaces/ITax";
import {IBuySummary} from "../../solutions/interfaces/IBuySummary";
import BuySummary from "../../components/buySummary/BuySummary";
import arrowImage from "../../assets/arrow.svg";
import {getRandomOrderId, orderId, setBuyFormValueIsValid} from "../../solutions/controllers/BuyController";
import {unsubscribe} from "../../Controller/redux/websocket/WebSocketController";
import {
    destroyBuyProccessMsg,
    openWebSocketRedsysResponse,
    processInsitePayment,
    SaleType
} from "../../solutions/PayUtils";
import RedsysForm from "../buy/RedsysForm";
import {ITicketDetails} from "../../solutions/interfaces/ITicketDetails";
import {IPaymentTypeDetail} from "../../solutions/interfaces/IPaymentTypeDetail";
import {getPaymentTypeDetails} from "../../solutions/controllers/EventDetailsController";

export default function ChangeTicketOwnership(){
    const [form] = Form.useForm();

    const navigate = useNavigate();
    const { ticketId } = useParams();
    const dispatch = useDispatch();
    const [isFree,setIsFree] = useState(false);
    const [originalData, setOriginalData] = useState<ITicketProfileDetails>();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const websocketSelector = useSelector((state : any) => state.websocket);

    const [amountToPay,setAmountToPay] = useState<number>(0);
    const [amountToPayWithCommision,setAmountToPayWithCommision] = useState<number>(0);
    const [dataForSummary,setDataForSummary] = useState<IBuySummary>();

    const [paymentMethods, setPaymentMethods] = useState<IPaymentTypeDetail[]>([]);
    const [paymentTypeSelected, setPaymentTypeSelected] = useState<IPaymentTypeDetail | null>(null);

    const [idOper, setIdOper] = useState<string | null>(null)
    const [checkPayment, setCheckPayment] = useState(false);
    const [activeSection, setActiveSection] = useState<string>('');

    const sectionRefV1 = useRef(null);
    const sectionRefV2 = useRef(null);

    const threeDSMethodIframeEl = useRef<HTMLIFrameElement>();
    let threeDSMethodFormEl =  useRef<HTMLFormElement>()
    let threeDSMethodDataEl = useRef<HTMLInputElement>();

    let challengeV1IframeEl =  useRef<HTMLIFrameElement>();
    let challengeV1FormEl = useRef<HTMLFormElement> ();
    let challengeV1MDEl = useRef<HTMLInputElement>();
    let challengeV1PaReqEl = useRef<HTMLInputElement> ();
    let challengeV1TermUrlEl = useRef<HTMLInputElement>();

    let challengeV2IframeEl = useRef<HTMLIFrameElement>();
    let challengeV2FormEl= useRef<HTMLFormElement>();
    let challengeV2CreqEl= useRef<HTMLInputElement>();


    let redsysForm =  useRef<HTMLFormElement>();
    let dsSignatureVersionEl = useRef<HTMLInputElement>();
    let dsMerchantParametersEl = useRef<HTMLInputElement> ();
    let dsSignatureEl = useRef<HTMLInputElement>();

    let merchantValidate = false;
    let timerID: string | number | NodeJS.Timeout | null | undefined = null;

    function onReceiveRedsysMessage(event: any) {
        if (event?.data?.idOper) {
            setIdOper(event.data.idOper);
        } else if (event?.data?.error) {
            message.error("Se ha producido un error con sus datos de pago: " + event.data.error + ", revise sus datos o contacte con el servicio técnico")
        }

        if (event?.data == 'merchantValidation') {
            if (!merchantValidate) {
                merchantValidate = true;
                //@ts-ignore
                storeIdOper(event, 'token', 'errorCode');
            }
        }
    }

    useEffect(()=>{
        if (ticketId) {
            // @ts-ignore
            loadTicketDetails(parseInt(ticketId, 10), auth, setOriginalData, (results : ITicketDetails) => {
                if(results && results.eventId){
                    getPaymentTypeDetails(results.eventId, (results: IPaymentTypeDetail[]) => {
                        setPaymentMethods(results)
                    }, dispatch)
                }
            }, dispatch);
        }
        else {
            navigate('/profile');
        }
    },[auth])

    useEffect(()=>{
        if (originalData){
            if (!originalData.allowRename || originalData.status==='RESALE'){
                navigate('/');
                message.error('Esta entrada no tiene permitido el cambio de titular.');
            }else{
                calculateAmountToPay(originalData.renameprice,setAmountToPay);
                calculateAmountToPayWithCommission(originalData.renameprice,originalData.renamecommission,TaxType.FIXED,setAmountToPayWithCommision);
                manageIsRenameFree(originalData.renameprice,originalData.renamecommission,setIsFree);

            }
        }
    },[originalData])

    useEffect(()=>{
        if (amountToPayWithCommision !== null && amountToPay !== null && originalData) {
            const summary: IBuySummary = {
                total: amountToPayWithCommision,
                commission: originalData.renamecommission || 0,
                title: 'Cambio de nombre: ' + amountToPay.toString() + '€',
                buyDetails: [],
            };
            setDataForSummary(summary);
        }
    },[amountToPay,amountToPayWithCommision])

    useEffect(() => {
        setBuyFormValueIsValid(false);

        window.removeEventListener('message', onReceiveRedsysMessage);
        window.addEventListener('message', onReceiveRedsysMessage);

        return () => {
            window.removeEventListener('message', onReceiveRedsysMessage);
            if(idOper){
                unsubscribe("refresh-redsys-pay-" + idOper);
            }
            if(timerID != null){
                clearTimeout(timerID);
            }
        }
    }, []);

    useEffect(() => {
        if(idOper && idOper !== '-1' && paymentTypeSelected){
            openWebSocketRedsysResponse(idOper, dispatch);
            //Iniciar proceso de compra
            console.log("Iniciando proceso de compra")
            onBuy(paymentTypeSelected)
        }
        else if(idOper && idOper === '-1'){
            message.error("Se ha producido un error al obtener los datos de tu tarjeta, recargue la página e inténtelo de nuevo",0)
        }
    }, [idOper]);

    useEffect(() => {
        // console.log("websocketSelector ",websocketSelector.messages)
        if(websocketSelector){
            let messageInt = websocketSelector.messages?.filter((message: { content: { topic: string; }; }) =>
                message.content
                &&
                message.content.topic
                &&
                message.content.topic === ("refresh-redsys-pay-" + idOper)
            )[0]

            if(messageInt && messageInt.content && messageInt.content.data){
                successBuy()
            }
            else if(messageInt && messageInt.content && !messageInt.content.data){
                errorBuy();
            }
        }

    }, [websocketSelector]);

    function successBuy(){
        destroyBuyProccessMsg()

        message.success({
            key: "msgKey",
            onClick:(() => message.destroy('msgKey')),
            content: <Row justify={"center"}>Entradas cambiada de nombre correctamente, revise su email. (haga click en el mensaje para cerrarlo)</Row>,
            duration: 0,
            style: {
                marginTop: '20vh',
            },
        })

        deleteUuid();
        navigate("/profile", {})
    }

    function errorBuy(errorMessage?: string){
        destroyBuyProccessMsg()
        message.error({
            key: "msgKey",
            onClick:(() => message.destroy('msgKey')),
            content: <Row justify={"center"}>{errorMessage ? errorMessage : "Error cambiando entrada de nombre, vuelva a intentarlo"} {" (haga click en el mensaje para cerrarlo)"}</Row>,
            duration: 0,
            style: {
                marginTop: '20vh',
            },
        })
        deleteUuid();
    }


    function goToProfile(){
        window.history.back();
    }
    function onChangeOwnership() {

        form.validateFields().then(value => {
            if (originalData){
                const changeOwnershipDTO:ChangeOwnershipDTO = {
                    name: value.firstName,
                    email: value.email,
                    birthdate: value.birthdate.getTime(),
                    zipCode: value.postalCode,
                    phone: value.phoneNumber,
                }
                const callback = ()=>{
                    navigate('/profile');
                }
                changeTicketOwnership(originalData.ticketsoldid, changeOwnershipDTO, auth, callback);
            }
        }).catch(reason => {
            message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago")
        })
    }

    function onBuy(paymentMethod: IPaymentTypeDetail) {
        form.validateFields().then(value => {
            if (!value.terms) {
                message.error("Debe aceptar los términos y condiciones");
                return;
            }

            if (originalData){
                const changeOwnershipDTO:ChangeOwnershipDTO = {
                    name: value.firstName,
                    email: value.email,
                    birthdate: value.birthdate.getTime(),
                    zipCode: value.postalCode,
                    phone: value.phoneNumber,
                }
                const callback = ()=>{
                    navigate('/profile');
                }

                if(paymentMethod.insite){
                    changeTicketOwnershipInsite(originalData.ticketsoldid, changeOwnershipDTO, paymentMethod.paymenttypedetailid,  orderId!, idOper!, amountToPayWithCommision,  async (preauthResponse: any) => {
                        if(preauthResponse.done){
                            successBuy();
                            return;
                        }
                        else{
                            await processInsitePayment(SaleType.RENAME, preauthResponse, threeDSMethodFormEl,
                                threeDSMethodIframeEl,
                                threeDSMethodDataEl,
                                challengeV1IframeEl,
                                challengeV1FormEl,
                                challengeV1MDEl,
                                challengeV1PaReqEl,
                                challengeV1TermUrlEl,
                                challengeV2FormEl,
                                challengeV2CreqEl,
                                idOper!,
                                amountToPayWithCommision,
                                timerID,
                                setActiveSection,
                                setCheckPayment,
                                successBuy,
                                errorBuy,
                                dispatch);
                        }
                    }, auth, dispatch);
                }
                else{
                    changeTicketOwnershipRedirect(originalData!.ticketsoldid, changeOwnershipDTO, paymentMethod.paymenttypedetailid,  getRandomOrderId(), amountToPayWithCommision,  async (response: any) => {
                        if(!response?.dsSignatureVersion || !response.dsMerchantParameters || !response.dsSignature){
                            message.error("Error obteniendo datos para la pasarela de pago, inténtelo de nuevo o contacte con el servicio técnico.")
                            return;
                        }

                        dsSignatureVersionEl.current!.value = response.dsSignatureVersion;
                        dsMerchantParametersEl.current!.value = response.dsMerchantParameters;
                        dsSignatureEl.current!.value = response.dsSignature;

                        redsysForm.current!.submit();
                    }, auth, dispatch);
                }
            }


        }).catch(reason => {
            message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago")
        })
    }

    return (

        <div className={`${styles.container} footerPaddingSeparation`}>
            <div hidden={true}>
                <iframe
                    // @ts-ignore
                    ref={threeDSMethodIframeEl}
                    name="threeDSMethodIframe" />

                <form
                    // @ts-ignore
                    ref={threeDSMethodFormEl}
                    method='post'
                    target="threeDSMethodIframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={threeDSMethodDataEl}
                        name="threeDSMethodData"
                        type="hidden" />
                </form>

                <form
                    //@ts-ignore
                    ref={redsysForm}
                    name="from"
                    // action="https://sis.redsys.es/sis/realizarPago" method="POST">}
                    action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" >
                    <input
                        //@ts-ignore
                        ref={dsSignatureVersionEl} type="hidden" name="Ds_SignatureVersion"/>

                    <input
                        //@ts-ignore
                        ref={dsMerchantParametersEl} type="hidden" name="Ds_MerchantParameters"/>

                    <input
                        //@ts-ignore
                        ref={dsSignatureEl} type="hidden" name="Ds_Signature"/>
                </form>
            </div>

            <div ref={sectionRefV1} hidden={activeSection !== 'challenge-v1'}>
                {/* Challenge form */}
                <iframe
                    // @ts-ignore
                    ref={challengeV1IframeEl}
                    name="challengeV1Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV1FormEl}
                    method='post'
                    target="challengeV1Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV1MDEl}
                        type="hidden"
                        name="MD" />
                    <input
                        // @ts-ignore
                        ref={challengeV1PaReqEl}
                        type="hidden"
                        name="PaReq" />
                    <input
                        // @ts-ignore
                        ref={challengeV1TermUrlEl}
                        type="hidden"
                        name="TermUrl" />
                </form>
            </div>

            <div ref={sectionRefV2} hidden={activeSection !== 'challenge-v2'}>
                <iframe
                    // @ts-ignore
                    ref={challengeV2IframeEl}
                    name="challengeV2Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV2FormEl}
                    method='post'
                    target="challengeV2Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV2CreqEl}
                        type="hidden"
                        name="creq" />
                </form>
            </div>

            {originalData && originalData.allowRename && originalData.status!=='RESALE'?(
                <>
                    <Row align={'middle'}>
                        <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                            <img src={arrowImage} alt="Arrow image" onClick={goToProfile} style={{cursor:'pointer'}}/>
                        </Col>
                        <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                            <h1 className={'comeBackTitle'}  onClick={goToProfile}>
                                VOLVER
                            </h1>
                        </Col>
                    </Row>

                    <Row justify={'center'}>
                        <Space direction={'vertical'}>
                            <h1 className={styles.title}>CAMBIO DE TITULAR</h1>
                            <Space direction={'horizontal'}>
                                <img src={warningImage} alt={'Warning image'} className={styles.warningIcon}/>
                                <h1 className={styles.warningTitle}>Advertencia</h1>
                            </Space>

                        </Space>
                    </Row>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                            <h1 className={styles.warningMessage}>Si realiza el cambio de nombre, sus entradas dejarán de ser válidas y se le enviarán otras al nuevo titular.</h1>
                        </Col>
                    </Row>

                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <h1 className={styles.title}>NUEVO TITULAR</h1>
                        </Col>
                    </Row>

                    <RegisterForm form={form} isBuy={true} ></RegisterForm>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <h1 className={styles.title}>RESUMEN DE TU PEDIDO</h1>
                        </Col>
                    </Row>

                    {dataForSummary?(
                        <BuySummary data={dataForSummary}/>
                    ):(<></>)}
                    <Row justify={'center'} style={{marginBottom:20}}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Form
                        form={form}
                        name="checkboxForm"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ width:'100%' }}
                        initialValues={{ remember: true }}

                        autoComplete="off"
                    >
                        <Row justify="center">

                            <Form.Item label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                                <Checkbox ><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                            </Form.Item>
                        </Row>
                    </Form>
                    <Row justify={'center'} style={{paddingBottom:0}}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={8} xxl={4}>
                            {isFree?(
                                    <Button
                                        type={'default'}
                                        className={'defaultButton'}
                                        onClick={onChangeOwnership}
                                    >
                                        FINALIZAR
                                    </Button>
                                )
                                :
                                paymentMethods.length > 0 ?
                                        <>
                                            {paymentMethods.map(paymentMethod => {
                                                return (
                                                    <Button type={'default'} className={'defaultButton'} onClick={() => {
                                                        setPaymentTypeSelected(paymentMethod);

                                                        if(!paymentMethod.insite){
                                                            onBuy(paymentMethod);
                                                        }
                                                    }}>
                                                        {paymentMethod.description}
                                                    </Button>
                                                )
                                            })}
                                        </>
                                        :<></>
                            }
                        </Col>
                    </Row>
                    {paymentTypeSelected && paymentTypeSelected.insite &&
                        <Row justify={"center"}>
                            <RedsysForm callback={(idOper: string, orderId: string) => console.log("Recibiendo callback de redsys idOper "+idOper+", orderId "+orderId)}></RedsysForm>
                        </Row>
                    }
                </>
            ):(<></>)}

        </div>
    )
}