import styles from './ProfileHeader.module.css';
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {IUser} from "../../solutions/interfaces/IUser";
import {
    getFirstBackgroundImage,
    getProfile,
    getProfileBackgroundImage, getFirstBackgorundImage,
} from "../../solutions/controllers/UserController";
import {Button, Col, message, Row} from "antd";
import calendarImage from '../../assets/calendar_profile.svg';
import editImage from '../../assets/edit_profile.svg';
import helpImage from '../../assets/help_profile.svg';
import {logout} from "../../Controller/redux/auth/actions";
import {useNavigate} from "react-router-dom";

export default function ProfileHeader(){
    const dispatch = useDispatch();
    const [profile, setProfile] = useState<IUser>();
    const auth = useSelector((state : any) => state.auth);
    const navigate = useNavigate();
    const [backgroundImage, setBackgroundImage] = useState<string | undefined>();

    function onLogOut() {
        //@ts-ignore
        dispatch(logout())
        navigate("/")
    }

    function goToEditProfile() {
        navigate("/profile/editProfile")
    }
    useEffect(() => {
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);

        }else{
            navigate('/');
        }
    }, [auth]);
    useEffect(()=>{
        if (profile && !backgroundImage){
            if (profile.profilePhoto){
                getProfileBackgroundImage(profile.profilePhoto, setBackgroundImage)
            }else{
                getFirstBackgorundImage(setBackgroundImage);
            }
        }
    },[profile])

    return (
        <div className={styles.container}>
            <Row >

                    <Col xs={{span:8,offset:1}} sm={0} md={{span:2,offset:1}} lg={{span:2,offset:1}} xl={{span:2,offset:1}} style={{marginTop:15}}>
                        <a onClick={() => navigate("/")}><p className={styles.logo}>TIXXLAB</p></a>
                    </Col>
                    <Col xs={0} sm={0} md={{span:18,offset:0}} lg={{span:18,offset:0}} xl={{span:18,offset:0}} style={{textAlign:'center',marginTop:15}} >
                        <p className={styles.welcomeText} >¡BIENVENIDO {profile?.name.toUpperCase()}!</p>
                    </Col>
                    <Col xs={{span:8,offset:6}} sm={0} md={{span:2,offset:0}} lg={{span:2,offset:0}} xl={{span:2,offset:0}} style={{marginTop:15}}>
                        <Button onClick={onLogOut} type={'default'} className={styles.logoutButton}>Salir
                        </Button>
                    </Col>
            </Row>

            <Row align="middle" >
                {backgroundImage?(
                    <Col xs={{span:24,offset:0}} sm={{span:24,offset:0}} md={{span:24,offset:0}} lg={{span:4,offset:0}} xl={{span:4,offset:0}}
                    >
                        <img className={styles.profileImage} alt={'profile image'} src={backgroundImage}/>
                    </Col>
                ):(
                    <Col xs={{span:24,offset:0}} sm={{span:24,offset:0}} md={{span:24,offset:0}} lg={{span:4,offset:0}} xl={{span:4,offset:0}}
                    >
                    </Col>
                )}
                <Col xs={{span:23,offset:1}} sm={{span:24,offset:0}} md={{span:16,offset:2}} lg={{span:16,offset:2}} xl={{span:16,offset:2}} >
                    <Row >
                        <Col xs={{span:11,offset:0}} sm={{span:6}} md={{span:6}} lg={{span:6}} xl={{span:6}} className={styles.profileButton} style={{marginLeft:0}} onClick={goToEditProfile}>
                            <Button
                                type="text"
                                className={styles.button}
                                icon={<img src={editImage} alt="Editar" className={styles.buttonIcon} />}
                            >
                                <span style={{marginTop:1}}>Editar perfil</span>
                            </Button>
                        </Col>
                        <Col xs={{span:11,offset:0}} sm={{span:6}} md={{span:6}} lg={{span:6}} xl={{span:6}}  className={styles.profileButton} >
                            <Button
                                type="text"
                                icon={<img src={calendarImage} alt="Calendar image" className={styles.buttonIcon} />}
                                onClick={() => message.warning("Calendario deshabilitado temporalmente")}
                                className={styles.button}
                            >
                                <span style={{marginTop:1}}>Calendario</span>
                            </Button>
                        </Col>
                        <Col xs={{span:22}} sm={{span:6}} md={{span:6}} lg={{span:6}} xl={{span:6}} className={styles.profileButton} >
                            <Button
                                type="text"
                                icon={<img src={helpImage} alt="Help image" className={styles.buttonIcon} />}
                                onClick={() => navigate("/help")}
                                className={styles.button}
                            >
                                <span style={{marginTop:1}}>Ayuda</span>
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:18,offset:2}} sm={{span:18,offset:2}} md={0} lg={0} xl={0} style={{marginTop:10,textAlign:'center'}} >
                            <p className={styles.welcomeText} >¡BIENVENIDO {profile?.name.toUpperCase()}!</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:18,offset:2}} sm={{span:18,offset:2}} md={{span:18,offset:0}} lg={{span:18,offset:0}} xl={{span:18,offset:0}} style={{marginTop:10,textAlign:'center'}} >
                            <p className={styles.welcomeText} >AQUÍ TIENES TUS ENTRADAS</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}