import {Col,  Form, FormInstance, Input, InputNumber, Row, Select} from "antd";
import styles from "./RegisterForm.module.css";
import React, {useEffect, useState} from "react";
import DatePicker from "react-date-picker";
import "./DatePicker.css";
import "react-calendar/dist/Calendar.css";

const dateFormat = 'DD/MM/YYYY';


type FieldType = {
    firstName?: string;
    birthdate?: string;
    email?: string;
    email2?: string;
    phoneNumber?: number;
    postalCode?: number;
    password?:string;
    repeatPassword?:string;
};
export default function RegisterForm({ form, isBuy, onBlur}: { form: FormInstance,isBuy:boolean, onBlur?: Function }) {

    useEffect(()=>{
        const datePickerInput = document.querySelector('#registerForm_birthdate');
        if(datePickerInput){
            // @ts-ignore
            datePickerInput.style.color= 'white';
        }
    })
    // @ts-ignore
    return (
        <Form
            form={form}
            name="registerForm"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width:'100%' }}
            initialValues={{ remember: true }}
            //@ts-ignore
            onBlur={onBlur}
            autoComplete="off"
        >
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                        name="email"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Por favor introduce un email válido', type:'email' }]}
                    >
                        <Input className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>REPITE CORREO ELECTRÓNICO</span>}
                        name="email2"
                        style={{ width: '100%' }}
                        rules={[
                            { required: true, message: 'Debe repetir el email',  type:'email' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("email") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Los emails no coinciden'));
                                },
                            })
                        ]}
                        dependencies={['email']}
                        hasFeedback
                    >
                        <Input className={styles.input} placeholder={''} style={{width:'100%', backgroundColor: '#2A2727 !important'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>NOMBRE Y APELLIDOS</span>}
                        name="firstName"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Debe rellenar el campo nombre y apellidos' }]}
                    >
                        <Input className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>FECHA DE NACIMIENTO</span>}
                        name="birthdate"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Debe rellenar la fecha de nacimiento' }]}
                    >
                        <DatePicker   className={styles.datepicker} />
                        {/*<DatePicker locale={locale} format={dateFormat} placeholder={''} className={styles.datepicker}  style={{width:'100%', color:'white'}}/>*/}
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>TELÉFONO</span>}
                        name="phoneNumber"
                        style={{ width: '100%', color:'white' }}
                        rules={[{ required: true, message: 'Debe rellenar el número de teléfono' }]}
                    >
                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%',color:'white'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>CÓDIGO POSTAL</span>}
                        name="postalCode"
                        style={{ width: '100%' }}
                        rules={[{ required: true, message: 'Debe rellenar el código postal' }]}
                    >
                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            {!isBuy?(
                    <>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>CONTRASEÑA</span>}
                                    name="password"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Debe rellenar la contraseña' }]}
                                >
                                    <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={styles.label}>REPITA CONTRASEÑA</span>}
                                    name="repeatPassword"
                                    style={{ width: '100%' }}
                                    rules={[
                                        { required: true, message: 'Debe repetir la contraseña' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Las contraseñas no coinciden'));
                                            },
                                        })
                                    ]}
                                    dependencies={['password']}
                                    hasFeedback
                                >
                                    <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
            ):null}

        </Form>
    );
}