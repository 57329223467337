import React, {useState} from 'react';
import Modal from 'react-modal';
import { Row, Col, Button } from 'antd';
import styles from './TicketResale.module.css';
Modal.setAppElement('#root');

interface TicketResaleProps {
    isOpen: boolean;
    onClose: () => void;
    onOk:()=>void;
}
const TicketResale: React.FC<TicketResaleProps> = ({ isOpen, onClose, onOk }) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={onClose}
                className={styles.customModal}
                overlayClassName={styles.customOverlay}
            >
                <Row>
                    <Col span={24}>
                        <h2 className={styles.modalTitle}>
                            ¿ESTÁS SEGURO DE QUE DESEAS RETIRAR TU ENTRADA DE LA REVENTA?
                        </h2>
                    </Col>
                </Row>
                <Row className={styles.buttonContainer}>
                    <Col span={12} className={styles.modalYesActions} style={{borderRightWidth:1,borderStyle:"solid",borderColor:"black",height:"100%"}}>
                        <Button className={styles.resaleButton}  type="text" onClick={onOk}>
                            SÍ
                        </Button>

                    </Col>
                    <Col span={12} className={styles.modalNoActions}>
                        <Button className={styles.resaleButton}  type="text" onClick={onClose}>
                            NO
                        </Button>

                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default TicketResale;
