import {Row} from "antd";
import styles from "./BuySummary.module.css";
import React  from "react";
import {IBuySummary} from "../../solutions/interfaces/IBuySummary";

export default function BuySummary({data}:{data:IBuySummary}){

    return (
      <>

          <Row justify={"center"}>
              <p className={styles.whiteTitle}>{data.title} </p>
          </Row>
          {data.buyDetails && data.buyDetails.length !== 0 && (
              data.buyDetails.map((buyDetail) =>
                  <Row justify={'center'} key={buyDetail.name}>
                      <p style={{ fontSize: 20 }} className={styles.whiteTitle}>{buyDetail.quantity} x {buyDetail.name} {(buyDetail.price).toFixed(2)}€ </p>
                  </Row>
              )
          )}
          {data.commission?(
              <Row justify={"center"}>
                  <p className={styles.whiteTitle}>Gastos de gestión: {data.commission.toFixed(2)}€ </p>
              </Row>
          ):<></>}

          <Row justify={"center"}>
              <p className={styles.whiteTitle}>Total: {data.total.toFixed(2)}€ </p>
          </Row>
      </>
    );
}