import GoogleMapReact from 'google-map-react';
import React from 'react';

// @ts-ignore
export default function SimpleMap({latitude, longitude }: {latitude: number, longitude: number}){


    const defaultProps = {
        center: {
            lat: latitude,
            lng: longitude
        },
        zoom: 11
    };

    // @ts-ignore
    function renderMarkers(map, maps) {
        let marker = new maps.Marker({
            position: { lat:  latitude, lng:  longitude },
            map,
            title: 'Hello World!'
        });
    }

    return (
        <GoogleMapReact
            style={{width:'100%', height:'30vh'}}
            bootstrapURLKeys={{ key: "AIzaSyDF3XswckhVNkqzvW7KsP9gEk2FsV3Jeak" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            onGoogleApiLoaded={({map, maps}) => renderMarkers(map, maps)}
            onClick={() => window.open("https://maps.google.com/?q=" + latitude + "," + longitude, "_blank")}
        >

        </GoogleMapReact>
    );
}