import {MESSAGE_CLEAN, MESSAGE_RECEIVED} from "./actions"

const initialState = {
    messages: []
}

export const websocket = (state = initialState, action) => {
    switch (action.type) {
        case MESSAGE_RECEIVED:
            return {
                ...state,
                messages: [...state.messages, action.message]
            }

        case MESSAGE_CLEAN:
            console.log("Limpiando mensajes....")
            return {
                ...state,
                messages: []
            }

        default:
            return state;
    }
}
