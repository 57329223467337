

export  function isTicketEventExtraSelected(ticketList: any[],index:number,ticketeventextra:any) {
    let result:boolean = false;

    if (ticketList[index].ticketeventextrasadded){
        result=ticketList[index].ticketeventextrasadded.some((t:any)=>t.ticketeventextraid===ticketeventextra.ticketeventextraid);
    }

    return result;
}

export function addTicketEventExtraToSummary(originalTotalPrice:number, discount: number, ticketList:any[],globalState:any,setGlobalState:Function){
    let newPrice= originalTotalPrice - discount;

    if (ticketList){
        const ticketEventExtraList: any[] = ticketList.flatMap(t => t.ticketeventextrasadded || []);

        if (ticketEventExtraList && ticketEventExtraList.length>0){
            ticketEventExtraList.forEach((tex:any)=>{

                if (tex && tex.quantity) {
                    newPrice += tex.quantity;
                }

            });

        }

        setGlobalState((prev: any) => ({ ...prev, total: newPrice, selectedTicketExtras:ticketEventExtraList }));
    }else{
        setGlobalState((prev: any) => ({ ...prev, total: newPrice, selectedTicketExtras:[] }));
    }
}

export function addTicketEventExtraToShoppingCar(ticketList:any,setTicketList: Function, ticketeventextra: any,index:number) {
    const newTicketList = [...ticketList];
    const ticketSearched = {...newTicketList[index]};

    if (ticketSearched.ticketeventextrasadded && ticketSearched.ticketeventextrasadded.length>0){

        if (!ticketSearched.ticketeventextrasadded.some((t:any)=>t.ticketeventextraid===ticketeventextra.ticketeventextraid)){

            ticketSearched.ticketeventextrasadded.push(ticketeventextra);
        }

    }else{
        ticketSearched.ticketeventextrasadded = [ticketeventextra];
        newTicketList[index]=ticketSearched;
    }
    setTicketList(newTicketList)
}

export function removeTicketEventExtraFromShoppingCar(ticketList: any, setTicketList: Function, ticketeventextra: any, index: number) {
    const newTicketList = [...ticketList];
    const ticketSearched = { ...newTicketList[index] };

    if (ticketSearched.ticketeventextrasadded && ticketSearched.ticketeventextrasadded.length > 0) {

        const filteredExtras = ticketSearched.ticketeventextrasadded.filter((t: any) => t.ticketeventextraid !== ticketeventextra.ticketeventextraid);
        ticketSearched.ticketeventextrasadded = filteredExtras;
        newTicketList[index] = ticketSearched;
        setTicketList(newTicketList);

    }
}
