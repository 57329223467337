import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { IEvent } from "../../solutions/interfaces/IEvent";
import { ICategory } from "../../solutions/interfaces/ICategory";
import { useDispatch } from "react-redux";
import { getPromoterEventAndCategories } from "../../solutions/controllers/EventController";
import { Col, Row } from "antd";
import RestClient from "../../Controller/rest/RestClient";
import { rootServiceURL } from "../../Controller";
import { IPromoter } from "../../solutions/interfaces/IPromoter";
import EventsList from "../../components/eventsList/EventsList";
import Header from "../../components/header/Header";
import { imagesPath } from "../../Controller/Settings";

export default function EventPromoter() {
    const dispatch = useDispatch();
    const { promoterId } = useParams();
    const navigate = useNavigate();

    const [promoter, setPromoter] = useState<IPromoter | undefined>(undefined);
    const [originalEventData, setOriginalEventData] = useState<IEvent[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const [imageExists, setImageExists] = useState(false);

    useEffect(() => {
        getPromoter();
        getPromoterEventAndCategories(setOriginalEventData, setCategories, dispatch, promoterId);
    }, []);

    useEffect(() => {
        if (promoter) {
            const imageUrl = `${imagesPath}images/promoter/${promoter.promoterid}`;
            checkImage(imageUrl).then(exists => {
                // @ts-ignore
                setImageExists(exists);
            });
        }
    }, [promoter]);

    function getPromoter() {
        const url = rootServiceURL() + "completegraph/promoter/getPromoter/" + promoterId;
        const rc = new RestClient(dispatch).url(url);
        rc.doGet((result: IPromoter) => {
            result && setPromoter(result);
        });
    }

    const handleEventClick = (eventid: number) => {
        navigate("/eventDetails/" + eventid);
    };

    async function checkImage(url: string) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => resolve(true);
            img.onerror = () => resolve(false);
            img.src = url;
        });
    }

    return (
        <div className={originalEventData.length > 2 ? 'footerSeparation' : 'footerPaddingSeparation'} style={originalEventData.length > 2 ? {} : { height: "88%" }}>
            <Row style={{ paddingBottom: 0, paddingTop: 0 }} justify={'center'}>
                <Col span={24} style={{ position: 'relative' }}>
                    <Row style={{ marginTop: 10 }}></Row>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <div style={{ paddingBottom: 15 }}>
                <Row justify="center" style={{ marginTop: 10 }}>
                    <Col xs={23} sm={18} md={16} lg={14} xl={12} xxl={10} style={{ textAlign: 'center' }}>
                        {imageExists && promoter && (
                            <img
                                src={`${imagesPath}images/promoter/${promoter.promoterid}`}
                                alt="Promoter Logo"
                                style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover', marginBottom: '10px' }}
                            />
                        )}
                        <h2 style={{ color: '#ffffff', marginBlockStart: 0, marginBlockEnd: "2.5em" }}>{promoter?.publicname}</h2>
                    </Col>
                </Row>
                <EventsList eventList={originalEventData} handleEventClick={handleEventClick} />
            </div>
        </div>
    );
}
