import React, {useEffect} from 'react';
import './App.css';
// @ts-ignore
import {
    BrowserRouter as Router,
    createBrowserRouter,
    createRoutesFromElements,
    Route, RouterProvider,

} from 'react-router-dom';
import Events from "./pages/events/Events";
import EventDetails from "./pages/eventDetails/EventDetails";
import Collection from "./pages/collection/Collection";
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./store";
import LoginRegisterUser from "./pages/loginRegisterUser/LoginRegisterUser";
import BuyTest from "./pages/buy/BuyTest";
import TermAndConditions from "./pages/termAndConditions/TermAndConditions";
import LegalAdvice from "./pages/legalAdvice/LegalAdvice";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import CookiesPolicy from "./pages/cookiesPolicy/CookiesPolicy";
import BuyGlobal from "./pages/buy/BuyGlobal";
import Profile from "./pages/profile/Profile";
import TicketDetails from "./pages/ticketDetails/TicketDetails";
import ChangeTicketOwnership from "./pages/changeTicketOwnership/ChangeTicketOwnership";
import ImproveTicket from "./pages/improveTicket/ImproveTicket";
import BuyTestRedirection from "./pages/buy/BuyTestRedirection";
import BuySuccess from "./pages/buy/BuySuccess";
import BuyError from "./pages/buy/BuyError";
import ImproveTicketSummary from "./pages/improveTicketSummary/ImproveTicketSummary";
import EditProfile from "./pages/editProfile/EditProfile";
import SendRecoveryEmail from "./pages/sendRecoveryEmail/SendRecoveryEmail";
import ResetPassword from "./pages/resetPassword/ResetPassword";
import {ConfigProvider} from "antd";
import BuyResaleTickets from "./pages/buyResaleTickets/BuyResaleTickets";
import ResaleTicket from "./pages/resaleTicket/ResaleTicket";
import ContactForm from "./pages/contactForm/ContactForm";
import Help from "./pages/help/Help";
import EventPromoter from "./pages/eventPromoter/EventPromoter";
import EventPublicRelations from "./pages/eventPublicRelations/EventPublicRelations";
import Queue from "./pages/queue/Queue";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path="/"  element={<Events/>}/>
            <Route path="/collection" element={<Collection/>} />
            <Route path="/loginRegister" element={<LoginRegisterUser/>} />
            {/*<Route path="/buy" element={<Buy/>} />*/}
            <Route path="/buyTest" element={<BuyTest/>} />
            <Route path="/buyTestRedirection" element={<BuyTestRedirection/>} />
            <Route path="/queue" element={<Queue/>} />
            <Route path="/eventDetails/:eventId/:rrpp?/:rrppName?" element={<EventDetails/>} />
            <Route path="/buyGlobal" element={<BuyGlobal/>} />
            <Route path="/paySuccess/:orderId/:transactionId" element={<BuySuccess/>} />
            <Route path="/payError/:orderId/:transactionId" element={<BuyError/>} />
            <Route path="/termAndConditions" element={<TermAndConditions/>} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy/>} />
            <Route path="/legalAdvice" element={<LegalAdvice/>} />
            <Route path="/cookiesPolicy" element={<CookiesPolicy/>} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/profile/editProfile" element={<EditProfile />} />
            <Route path="/ticketDetails/:ticketId" element={<TicketDetails/>} />
            <Route path="/changeTicketOwnership/:ticketId" element={<ChangeTicketOwnership/>} />
            <Route path="/improveTicket/:ticketId" element={<ImproveTicket/>} />
            <Route path="/improveTicket/summary" element={<ImproveTicketSummary/>} />
            <Route path="/sendRecoveryEmail" element={<SendRecoveryEmail/>} />
            <Route path="/resetPassword/:token" element={<ResetPassword/>} />
            <Route path="/buyResaleTickets/:ticketId" element={<BuyResaleTickets/>} />
            <Route path="/resaleTicket/:ticketId" element={<ResaleTicket/>} />
            <Route path="/contactForm" element={<ContactForm/>} />
            <Route path="/help" element={<Help/>} />
            <Route path={"/eventPromoter/:promoterId/:promoterName"} element={<EventPromoter/>}/>
            <Route path={"/rrpp/:publicRelationsUserId/:publicRelationsName"} element={<EventPublicRelations/>}/>
        </>
    ),
);

function App() {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Menu: {
                        itemSelectedColor:'black',
                        colorPrimary:'white',
                        colorBgBase:'#1CEDAE',
                        algorithm: true,
                    }
                },
                token: {
                    colorPrimary: '#000000'
                }
            }}
        >
        <Provider store={store}>
            <PersistGate persistor={persistor} loading={null}>
                <RouterProvider  router={router}/>
            </PersistGate>
        </Provider>
        </ConfigProvider>
    );
}

export default App;
