import styles from "./BuyResaleTickets.module.css";
import {Button, Col, ConfigProvider, Row, Pagination} from "antd";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    areNumberedInTicketList, checkTicketsAvailableAndGoToBuyResale,
    loadEventDetail, loadResaleTicketsByEvent
} from "../../solutions/controllers/EventDetailsController";
import {IEventDetails} from "../../solutions/interfaces/IEventDetails";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import ticketImage from "../../assets/ion_ticket.svg";
import {ITicketByEventDate} from "../../solutions/interfaces/ITicketByEventDate";
import {ITicket} from "../../solutions/interfaces/ITicket";
import {
    addTicketToShoppingCar,
    applyFilters,
    cleanFilterMaxPrice,
    cleanFilterMinPrice,
    cleanFilters, cleanTicketType,
    filterByMaxPrice,
    filterByMinPrice,
    filterByTicketType,
    isTicketSelected,
    removeTicketToShoppingCar,
    toogleAscending
} from "../../solutions/controllers/TicketResaleController";
import {useParams} from "react-router-dom";
import ResaleTicketPriceFilter from "../../components/resaleTicketPriceFilter/ResaleTicketPriceFilter";
import {closeModal} from "../../solutions/Utils";
import TicketTypeResaleTicketFilter from "../../components/ticketTypeResaleTicketFilter/TicketTypeResaleTicketFilter";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CloseOutlined,
    DownOutlined,
} from '@ant-design/icons';
import Header from "../../components/header/Header";
import { FloatButton } from 'antd';
import {IFilterResaleTickets} from "../../solutions/interfaces/IFilterResaleTickets";
import {imagesPath} from "../../Controller/Settings";
import {
    calculateMoneyAmountToBuy,
    checkIBuyButtonIsAvailable,
    getTicketListPerQuantity, setBuyFormValueIsValid
} from "../../solutions/controllers/BuyController";
import {ITicketQuantities} from "../../solutions/interfaces/ITicketQuantities";
import {SeatReservation} from "../../components/seatReservation/SeatReservation";
import {Seat} from "../../components/seatReservation/model/Seat";
import {checkSeatRules} from "../../components/seatReservation/action";
import {getOriginalCounterPart} from "react-multi-carousel/lib/utils";

export default function BuyResaleTickets(){
    const [event, setEvent] = useState<IEventDetails | undefined>(undefined);
    const dispatch = useDispatch();
    const [ticketEventByDates, setTicketEventByDates] = useState<ITicketByEventDate | undefined>(undefined)
    const [tickets,setTickets] = useState<ITicket[]>();
    const [filteredTickets,setFilteredTickets] = useState<ITicket[]>();
    const [isMinPriceFilterVisible,setIsMinPriceFilterVisible] = useState(false);
    const [isTicketTypeFilterVisible,setIsTicketTypeFilterVisible] = useState(false);
    const [isMaxPriceFilterVisible,setIsMaxPriceFilterVisible] = useState(false);
    const [isAscending,setIsAscending] = useState(true);
    const navigate = useNavigate();

    const [selectedTickets, setSelectedTickets] = useState<ITicket[]>([]);
    const [areNumbered, setAreNumbered] = useState(false);
    const [numberedTickets, setNumberedTickets] = useState<Seat[]>([])
    const [seatsBySector, setSeatsBySector] = useState({});

    const [canBuy, setCanBuy] = useState(false);
    const [total, setTotal] = useState(0);
    const { ticketId } = useParams();
    const initialFilters:IFilterResaleTickets = {
        minPrice: 0,
        maxPrice: Infinity,
        ticketType: null,
        isAscending:true,
    };
    const [filters, setFilters] = useState<IFilterResaleTickets>(initialFilters);
    const [minPriceInput, setMinPriceInput] = useState<string | number | null>('0');
    const [maxPriceInput, setMaxPriceInput] = useState<string | number | null>('0');
    const [ticketTypeSelectedOption, setTicketTypeSelectedOption] = useState<string | number | undefined>(0);
    const [ticketNameSelectedOption, setTicketNameSelectedOption] = useState<string>('Todas');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const paginatedTickets = filteredTickets ? filteredTickets.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage) : [];

    useEffect(()=>{
        if (ticketId){
            loadEventDetail(ticketId, setEvent, dispatch);
        }
    },[ticketId])

    useEffect(()=>{
        if (event){
            if (event.dates && event.dates[0] && event.dates[0].eventdateid){
                // @ts-ignore
                loadResaleTicketsByEvent(event.dates[0].eventdateid, ticketEventByDates, setTicketEventByDates, dispatch);
            }
        }
    },[event]);
    useEffect(()=>{
        if (ticketEventByDates && event){
            areNumberedInTicketList(setAreNumbered, ticketEventByDates[event.dates[0].eventdateid]);
            setTickets(ticketEventByDates[event.dates[0].eventdateid]);
            setFilteredTickets(ticketEventByDates[event.dates[0].eventdateid]);
        }
    },[ticketEventByDates])

    useEffect(() => {
        if (ticketEventByDates && (selectedTickets || numberedTickets)) {
            const ticketQuantities : ITicketQuantities = {};

            selectedTickets.forEach((t: ITicket, index: number) => {
                ticketQuantities[t.id] = 1;
            })

            checkIBuyButtonIsAvailable(ticketQuantities, numberedTickets, setCanBuy)

            let amountToPay = calculateMoneyAmountToBuy(selectedTickets, ticketQuantities, numberedTickets);
            setTotal(amountToPay)
        } else {
            setTotal(0)
        }
    }, [selectedTickets, numberedTickets])

    useEffect(()=>{
        if (filters && tickets){
            applyFilters(tickets,filters,setFilteredTickets);
        }
    },[filters])

    function checkIsTicketSelected(ticket: ITicket) {
        return isTicketSelected(ticket,selectedTickets);
    }
    function onAddTicket(ticket: ITicket) {
        addTicketToShoppingCar(setSelectedTickets,ticket);

    }
    function onRemoveTicket(ticket: ITicket) {
        removeTicketToShoppingCar(selectedTickets,setSelectedTickets,ticket);
    }

    function onFilterByMinPrice(minPrice:number) {
        if (tickets){
            filterByMinPrice(setFilters,minPrice);
        }
    }
    function onFilterByMaxPrice(maxPrice:number) {
        if (tickets){
            filterByMaxPrice(setFilters,maxPrice);
        }
    }
    function onFilterByTicketType(ticketId:number | null){
        if (tickets){
            filterByTicketType(setFilters,ticketId);
        }
    }

    function onCleanFilters() {
        cleanFilters(setFilters,initialFilters,setMaxPriceInput,setMinPriceInput,setTicketTypeSelectedOption,setTicketNameSelectedOption);
    }
    function onCleanTicketType(){
        cleanTicketType(setFilters,setTicketTypeSelectedOption,setTicketNameSelectedOption);
    }

    function onOrderByPrice() {
        toogleAscending(setFilters,filters);
    }
    function scrollToContinueButton() {
        const continueButton = document.getElementById("continueButton");
        if (continueButton) {
            continueButton.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleFilterToggle = ({filterSetter, otherFilterSetters,}: { filterSetter: Dispatch<SetStateAction<boolean>>; otherFilterSetters: Dispatch<SetStateAction<boolean>>[]; }): void => {
        filterSetter((prev) => !prev);
        otherFilterSetters.forEach((setter) => {
            setter(false);
        });
    };


    function onCleanMaxPrice() {
        cleanFilterMaxPrice(setFilters,setMaxPriceInput);
    }
    function onCleanMinPrice() {
        cleanFilterMinPrice(setFilters,setMinPriceInput);
    }

    function onBuyResaleTickets() {
        if(checkSeatRules(numberedTickets, seatsBySector)){
            const ticketsQuantities : ITicketQuantities = {};
            console.log("Selected Tickets ",selectedTickets)
            selectedTickets.forEach((t: ITicket, index: number) => {
                ticketsQuantities[t.ticketsoldid] = 1;
            });

            checkTicketsAvailableAndGoToBuyResale(true, ticketsQuantities, numberedTickets, (uuid: string) => {
                if (uuid) {
                    setBuyFormValueIsValid(false);
                    goToBuy(uuid);
                }
            }, dispatch)
        }
    }

    const goToBuy = (uuid: string) => {
        //@ts-ignore
        const tickets: ITicket[] = Object.keys(ticketEventByDates!).flatMap(value => ticketEventByDates![value]);

        const ticketsQuantities : ITicketQuantities = {};

        selectedTickets.forEach((t: ITicket, index: number) => {
            ticketsQuantities[t.id] = 1;
        })

        let ticketList = getTicketListPerQuantity(tickets,ticketsQuantities);

        //TODO Mostrar los que tenga el ticket original
        ticketList.forEach(t => t.ticket.ticketeventextras = [])
        navigate("/buyGlobal", {
            state:
                {
                    eventId: event?.eventId,
                    fromResale: true,
                    rrpp: null,
                    paymentDetails: event?.paymenttypedetails,
                    tickets: ticketList,
                    numberedTickets: numberedTickets.map((numTicket) => ({
                        //TODO hay que mapearlo porque si se recarga la página no deja acceder a las propiedades publicas
                        id: numTicket.id,
                        price: numTicket.price,
                        commission: numTicket.commission,
                        description: numTicket.description,
                        nominative: numTicket.nominative,
                        ticketeventid: numTicket.ticketeventid,
                        handicapped: numTicket.handicapped,
                        ticketsoldid: numTicket.ticketsoldid,
                        //@ts-ignore
                        ticketeventextras: []//TODO Mostrar los que tenga la entrada original
                    })),
                    uuid: uuid
                }
        })
    }

    function seatReservationChange(seats: Seat[]) {
        setNumberedTickets(seats)
    }

    return (
        <div className={`footerPaddingSeparation`}>
            {event?(
                <>
                    <ConfigProvider
                        theme={{
                            components: {
                                FloatButton: {
                                    colorBgBase:'#1CEDAE',
                                    algorithm: true,
                                }
                            }
                        }}
                    >
                        <FloatButton onClick={scrollToContinueButton} icon={<DownOutlined />} type="default"  className={styles.greenFloatButton} />
                    </ConfigProvider>
                    <Row style={{paddingBottom:30, paddingTop: 30}} justify={'center'}>
                        <Col span={24}>
                            <Header showFilters={false}></Header>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <h1 className={styles.title}>{event.title}</h1>
                        </Col>

                    </Row>
                    <Row justify={'center'} style={{marginTop:30}} align={'middle'}>
                        <Col>
                            <img alt="event image"
                                 src={event ? (imagesPath + event.image) : ''} //TODO ARREGLAR ESTO
                                 className={styles.eventImage}/>
                        </Col>
                        <Col  >
                            <h1 className={styles.title}>REVENTA OFICIAL</h1>
                        </Col>
                    </Row>
                    {!areNumbered &&
                        <Row justify={'center'} style={{marginTop:30,marginBottom:30}}>
                            <Col xs={{span:23,offset:0}} sm={{span:20,offset:0}} md={{span:17,offset:0}} lg={{span:14,offset:0}} xl={{span:12,offset:0}} xxl={{span:11,offset:0}}>
                                <Row justify={'center'}>
                                    <Col xs={{span:11,offset:0}} sm={{span:11,offset:0}} md={{span:8,offset:0}} lg={{span:6,offset:0}} xl={{span:5,offset:0}} xxl={{span:5,offset:0}}>
                                        <Button className={`defaultButton ${styles.button}`} type={'default'}
                                                onClick={()=>handleFilterToggle(
                                                    {filterSetter:setIsTicketTypeFilterVisible,
                                                        otherFilterSetters:[setIsMinPriceFilterVisible,setIsMaxPriceFilterVisible]})}
                                        >TIPO DE ENTRADA
                                        </Button>
                                        {ticketNameSelectedOption && ticketNameSelectedOption!=='Todas'?(
                                            <Button  type={'default'}  className={`defaultButton ${styles.greyButton}`} onClick={onCleanTicketType}
                                                     icon={<CloseOutlined  />}>
                                                {ticketNameSelectedOption}
                                            </Button>

                                        ):(<></>)}
                                    </Col >
                                    <Col xs={{span:11,offset:0}} sm={{span:11,offset:0}} md={{span:8,offset:0}} lg={{span:6,offset:0}} xl={{span:4,offset:0}} xxl={{span:4,offset:0}} className={styles.buttonContainer}>
                                        <Button  type={'default'} className={`defaultButton ${styles.button}`}
                                                 onClick={()=>handleFilterToggle(
                                                     {filterSetter:setIsMaxPriceFilterVisible,
                                                         otherFilterSetters:[setIsMinPriceFilterVisible,setIsTicketTypeFilterVisible]})}>PRECIO MAX
                                        </Button>
                                        {filters.maxPrice!==0 && filters.maxPrice!==Infinity?(
                                            <Button  type={'default'}  className={`defaultButton ${styles.greyButton}`} onClick={onCleanMaxPrice}
                                                     icon={<CloseOutlined />}>
                                                {filters.maxPrice}€
                                            </Button>

                                        ):(<></>)}

                                    </Col>
                                    <Col xs={{span:11,offset:0}} sm={{span:11,offset:0}} md={{span:8,offset:0}} lg={{span:6,offset:0}} xl={{span:4,offset:0}} xxl={{span:4,offset:0}} className={styles.buttonContainer}>
                                        <Button  type={'default'} className={`defaultButton ${styles.button}`}
                                                 onClick={()=>handleFilterToggle(
                                                     {filterSetter:setIsMinPriceFilterVisible,
                                                         otherFilterSetters:[setIsMaxPriceFilterVisible,setIsTicketTypeFilterVisible]})}>PRECIO MIN
                                        </Button>
                                        {filters.minPrice!==0?(
                                            <Button  type={'default'}  className={`defaultButton ${styles.greyButton}`} onClick={onCleanMinPrice}
                                                     icon={<CloseOutlined  />}> {filters.minPrice}€
                                            </Button>

                                        ):(<></>)}
                                    </Col>
                                    <Col xs={{span:11,offset:0}} sm={{span:11,offset:0}} md={{span:8,offset:0}} lg={{span:8,offset:0}} xl={{span:6,offset:0}} xxl={{span:5,offset:0}} className={styles.buttonContainer}>
                                        <Button  type={'default'} className={`defaultButton ${styles.button}`}  icon={filters.isAscending?<ArrowUpOutlined />:<ArrowDownOutlined />} onClick={onOrderByPrice}>{filters.isAscending?'ORDEN ASCENDENTE':'ORDEN DESCENDENTE'}
                                        </Button>
                                    </Col>
                                    <Col  xs={{span:11,offset:0}} sm={{span:11,offset:0}} md={{span:8,offset:0}} lg={{span:6,offset:0}} xl={{span:4,offset:0}} xxl={{span:4,offset:0}} className={styles.buttonContainer}>
                                        <Button  type={'default'}  className={`defaultButton ${styles.greyButton}`} style={{cursor:"pointer"}} onClick={onCleanFilters}
                                                 icon={<CloseOutlined />}>LIMPIAR FILTROS
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>
                    }
                    <ResaleTicketPriceFilter title={'Introduzca precio máximo:'}
                                             setPriceInput={setMaxPriceInput}
                                             priceInput={maxPriceInput}
                                             isOpen={isMaxPriceFilterVisible}
                                             onFilter={onFilterByMaxPrice}
                                             onClose={()=>closeModal(setIsMaxPriceFilterVisible)}></ResaleTicketPriceFilter>
                    <ResaleTicketPriceFilter title={'Introduzca precio mínimo:'}
                                             setPriceInput={setMinPriceInput}
                                             priceInput={minPriceInput}
                                             isOpen={isMinPriceFilterVisible}
                                             onFilter={onFilterByMinPrice}
                                             onClose={()=>closeModal(setIsMinPriceFilterVisible)}></ResaleTicketPriceFilter>
                    {tickets?(
                        <TicketTypeResaleTicketFilter selectedOption={ticketTypeSelectedOption}
                                                      setSelectedOption={setTicketTypeSelectedOption}
                                                      options={tickets}
                                                      isOpen={isTicketTypeFilterVisible}
                                                      onFilter={onFilterByTicketType}
                                                      onClose={()=>setIsTicketTypeFilterVisible(false)}
                                                      setSelectedNameOption={setTicketNameSelectedOption}
                        ></TicketTypeResaleTicketFilter>
                    ):<></>}
                    {tickets && tickets.length>=1?(
                        <>
                            {filteredTickets && filteredTickets.length >=1 ? (
                                    !areNumbered ? paginatedTickets.map((ticket:any,index:number)=>(
                                            <Row justify={'center'}  key={index}>
                                                <Col className={checkIsTicketSelected(ticket)?styles.ticketBoxSelected:styles.ticketBox}   xs={{span:23,offset:0}} sm={{span:20,offset:0}} md={{span:17,offset:0}} lg={{span:14,offset:0}} xl={{span:12,offset:0}} xxl={{span:11,offset:0}}>
                                                    <Row style={{ alignItems: "center", width:'100%' }}>
                                                        <Col xs={{offset:1,span:2}} sm={{offset:1,span:2}} md={{offset:1,span:2}} lg={{offset:1,span:2}} xl={{offset:1,span:2}}  xxl={{offset:1,span:2}}>
                                                            <img alt="ticketImg" src={ticketImage} className={styles.boxImage}
                                                                 style={{marginLeft: 0}}/>
                                                        </Col>
                                                        <Col xs={{span:16,offset:0}} sm={{span:15,offset:0}} md={{span:15,offset:0}} lg={{span:15,offset:0}} xl={{span:15,offset:0}}
                                                             xxl={{span:15,offset:0}}>
                                                            <p className={styles.ticketTitle} style={{marginLeft:5}}>{ticket.ticketname}</p>
                                                        </Col>
                                                        <Col xs={{span: 4, offset: 0 }} sm={{span: 3, offset: 2 }} md={{span: 3, offset: 1 }} lg={{span: 3, offset: 2 }} xl={{span: 3, offset: 2 }}  xxl={{ span: 3, offset: 2 }}>
                                                            <p className={styles.ticketTitle} style={{ fontSize: 30, marginBottom:0, marginTop:0}}>{ticket.price}€</p>
                                                        </Col>
                                                    </Row>
                                                    <Row style={{  width:'100%' }} align={'middle'} >
                                                        <Col xs={{span:14,offset:3}} sm={{span:15,offset:3}} md={{span:15,offset:3}} lg={{span:15,offset:3}} xl={{span:15,offset:3}} xxl={{span:15,offset:3}} >
                                                            <p className={styles.ticketMoreInfo} >{ticket.description}  </p>
                                                        </Col>

                                                        <Col style={{marginLeft:35}} xs={0} sm={0} md={{span: 3, offset: 2 }} lg={{span: 3, offset: 2 }} xl={{span: 4, offset: 1 }}  xxl={{ span: 4, offset: 1 }}>

                                                            {checkIsTicketSelected(ticket)?(
                                                                <p className={styles.add} onClick={() => onRemoveTicket(ticket)} style={{backgroundColor:'grey', cursor:'pointer',}}>
                                                                    <CloseOutlined   style={{ marginRight: 5 }} /> SELECCIONADA
                                                                </p>
                                                            ):(
                                                                ticket.status === 'AVAILABLE' ?
                                                                <p className={styles.add} onClick={()=>onAddTicket(ticket)}>
                                                                    AÑADIR
                                                                </p> :
                                                                    <p className={styles.ticketReserved}>RESERVADAS</p>
                                                            )}

                                                        </Col>
                                                        <Col style={{marginLeft:35}} xs={{span: 3, offset: 2 }} sm={{span: 3, offset: 2 }} md={0} lg={0} xl={0}  xxl={0}>
                                                            {checkIsTicketSelected(ticket)?(
                                                                <p className={styles.add} onClick={() => onRemoveTicket(ticket)} style={{backgroundColor:'grey', cursor:'pointer',}}>
                                                                    <CloseOutlined   style={{ marginRight: 5 }} /> SEL.
                                                                </p>
                                                            ):(
                                                                ticket.status === 'AVAILABLE' ?
                                                                    <p className={styles.add} onClick={()=>onAddTicket(ticket)}>
                                                                        AÑADIR
                                                                    </p> :
                                                                    <p className={styles.ticketSmallReserved}>RESERVADAS</p>
                                                            )}

                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )):
                                        <Row justify={'center'} style={{marginTop:30,marginBottom:30}}>
                                            <Col xs={{span:23,offset:0}} sm={{span:20,offset:0}} md={{span:17,offset:0}} lg={{span:14,offset:0}} xl={{span:12,offset:0}} xxl={{span:11,offset:0}}>
                                                <Row className={styles.ticketBox}>

                                                    <Col span={24} style={{paddingBottom: 20}}>
                                                        <SeatReservation
                                                            fromResale={true}
                                                            maxSeatsSelection={10}
                                                            eventdate={event.dates[0].eventdateid}
                                                            onSeatReservationChanged={seatReservationChange}
                                                            onCloseSector={(seats) => {
                                                                if(seats && Object.keys(seats).length > 0) {
                                                                    try {
                                                                        setSeatsBySector({...seatsBySector,  [Object.keys(seats)[0]] : seats[Object.keys(seats)[0]]})
                                                                    }catch (e){
                                                                        console.error(e)
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                ):
                                <>
                                    <h1 className={styles.title}>No hay entradas disponibles para los filtros seleccionados</h1>
                                </>
                            }
                        </>
                    ):(
                        <>
                            <h1 className={styles.title}>No hay entradas disponibles para este evento</h1>
                        </>
                    )}
                    <Row justify={'center'} style={{paddingTop:30,paddingBottom:30}}>
                        <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={4} style={{ display: 'flex', justifyContent: 'center' }}>
                                <Pagination
                                    current={currentPage}
                                    pageSize={itemsPerPage}
                                    total={filteredTickets ? filteredTickets.length : 0}
                                    onChange={(page) => setCurrentPage(page)}
                                    itemRender={(page, type, originalElement) => {
                                        const isActive = page === currentPage;
                                        return (
                                        <div style={{backgroundColor:'black', marginBottom:'10px'}}>
                                            <Button
                                                type='default'
                                                className="defaultButton"
                                                style={isActive ? {borderColor: "white", borderWidth: "3px"} : {}}
                                                onClick={() => {
                                                    setCurrentPage(page)
                                                }}
                                            >
                                                {originalElement}
                                            </Button>
                                        </div>
                                    )}}
                                />
                        </Col>
                    </Row>

                    <Row justify={'center'} style={{paddingTop:30,paddingBottom:0}}>
                        <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                            <Button
                                id="continueButton"
                                type="default"
                                onClick={onBuyResaleTickets}
                                className="defaultButton"
                                style={!canBuy ? { color: 'white', backgroundColor: 'grey', borderColor: 'grey' } : {}}
                                disabled={!canBuy}
                            >
                                {`${total.toFixed(2)}€ - Continuar`}
                            </Button>
                        </Col>
                    </Row>
                </>
            ):<></>}

        </div>
    );
}