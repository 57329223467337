import React from "react";
import {calculateMoneyToReceiveOrPay} from "./TaxController";
import {closeModal, openModal} from "../Utils";
import {ITicketProfileDetails} from "../interfaces/ITicketProfileDetails";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {dispatch} from "../../Controller/redux/websocket/WebSocketController";
import {ITicket} from "../interfaces/ITicket";
import {ResaleTicketDTO} from "../interfaces/DTO/ResaleTicketDTO";
import {IFilterResaleTickets} from "../interfaces/IFilterResaleTickets";


export function calculatePriceRange(actualPrice:number,setMinPrice:React.Dispatch<React.SetStateAction<number>>, setMaxPrice:React.Dispatch<React.SetStateAction<number>>){
    setMinPrice(actualPrice);
    setMaxPrice(actualPrice+5);
}

export function openTicketResalePriceModal(setTicketResaleModalOpen:React.Dispatch<boolean>,setTicketResalePriceModalOpen:React.Dispatch<boolean>){
    closeModal(setTicketResaleModalOpen);
    openModal(setTicketResalePriceModalOpen);
}

export default function controlInputNumber(maxPrice:number, text:string,
                                           setPriceInput:React.Dispatch<React.SetStateAction<string>>,
                                           setTaxes:React.Dispatch<React.SetStateAction<string>>,
                                           setTotalToReceive:React.Dispatch<React.SetStateAction<number>>
                                           ) {
    const num = parseInt(text);

    if (isNaN(num) && maxPrice) {
        setPriceInput('');
    } else {
        const restrictedNum = Math.min(Math.max(num, 0), maxPrice);
        setPriceInput(restrictedNum.toString());
        calculateMoneyToReceiveOrPay(restrictedNum,setTaxes, setTotalToReceive,false);

    }
}
export  function setResaleData(originalData:ITicketProfileDetails,setMinPrice:React.Dispatch<number>,setMaxPrice:React.Dispatch<number>,setCommission:React.Dispatch<number>,setCommissionType:React.Dispatch<string>,){
    setMinPrice(originalData.resaleminprice);
    setMaxPrice(originalData.resalemaxprice);
    setCommission(originalData.resalecommission);
    setCommissionType(originalData.resalecommissiontype);
}
export function calculateTax(setTaxesApplied:React.Dispatch<number>,ticket:ITicketProfileDetails, inputPrice:number){
    let type = ticket.resalecommissiontype;
    let commission = ticket.resalecommission;
    let taxes = 0;
    if(ticket.resaleminprice && ticket.resalemaxprice){
        if(inputPrice>=ticket.resaleminprice && inputPrice<=ticket.resalemaxprice){
            if (type==='PERCENTAGE'){
                taxes= (commission/100)*inputPrice;
            }else{
                taxes = commission;
            }
        }
    }else{
        if (type==='PERCENTAGE'){
            taxes= (commission/100)*inputPrice;
        }else{
            taxes = commission;
        }
    }
    taxes = parseFloat(taxes.toFixed(2));
    setTaxesApplied(taxes);
}
export function calculatePriceLessTax(price:number,tax:number,setAmountToPayWithCommision:React.Dispatch<number>){
    let result = price - tax;
    if (result<0){
       result=0;
    }
    setAmountToPayWithCommision(result);

}
export function resaleTicket(originalData:ITicketProfileDetails,dto:ResaleTicketDTO, auth: any, callback:Function){
    if (originalData.resaleminprice && originalData.resalemaxprice){
        if (!(dto.priceResale>=originalData.resaleminprice && dto.priceResale<=originalData.resalemaxprice)){
            callback && callback('Fije un precio en el umbral dado.');
            return;
        }
    }
    let url = rootServiceURL() + "completegraph/ticket/resaleTicket";
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doPost(dto, (results: any) => {
        if(rc.isSuccess()){
            callback && callback("");
        }
        else{
            callback && callback('Error en la venta del ticket.');
        }}, false)

}
export function cancelTicketResale(originalData: ITicketProfileDetails, auth: any, callback: Function) {
    if (originalData) {
        let url = rootServiceURL() + 'completegraph/ticket/cancelResaleTicket/' + originalData.ticketsoldid;
        let rc = new RestClient(dispatch).url(url).token(auth.token);
        // @ts-ignore
        rc.doGet((results: any) => {
            if (results) {
                callback && callback("");
            } else {
                callback && callback(results.getErrors());
            }
        });
    }
}
export  function isTicketSelected(ticket: ITicket,selectedTickets:ITicket[]) {
    return selectedTickets.some((selectedTicket) => selectedTicket.id === ticket.id);
}
export function  calculateResaleTicketsPrice(selectedTickets:ITicket[],setTotal:React.Dispatch<number>){

    let totalPrice = 0;
    if (selectedTickets && selectedTickets.length!==0){
       selectedTickets.forEach(t=>{
           totalPrice+=t.price;
       })
    }
    setTotal(totalPrice);

}
export function canBuyResaleTickets(selectedTickets:ITicket[],setCanBuy:React.Dispatch<boolean>){
    setCanBuy(selectedTickets.length!==0);
}
export function filterByMinPrice(setFilters: React.Dispatch<IFilterResaleTickets>, minPrice: number): void {
    // @ts-ignore
    setFilters((prevFilters) => ({ ...prevFilters, minPrice: parseFloat(minPrice)}));
}
export function filterByMaxPrice(setFilters: React.Dispatch<IFilterResaleTickets>, maxPrice: number){
    // @ts-ignore
    setFilters(prevFilters => ({ ...prevFilters, maxPrice: parseFloat(maxPrice)}));
}
export function filterByTicketType(setFilters: React.Dispatch<IFilterResaleTickets>, ticketType: number | null){
    // @ts-ignore
    setFilters(prevFilters => ({ ...prevFilters, ticketType: ticketType }));
}

export function toogleAscending(setFilters:React.Dispatch<IFilterResaleTickets>,filters:IFilterResaleTickets){
    // @ts-ignore
    setFilters(prev=>({...prev,isAscending:!filters.isAscending}));
}
export function cleanFilters(setFilters:React.Dispatch<IFilterResaleTickets>,
                             initialFilters:IFilterResaleTickets,
                             setMaxPriceInput:React.Dispatch<string | number | null>,
                             setMinPriceInput:React.Dispatch<string | number | null>,
                             setTicketTypeSelectedOption:React.Dispatch<string | number | undefined>,
                             setTicketNameSelectedOption:React.Dispatch<string>){
    setFilters(initialFilters);
    setMaxPriceInput(0);
    setMinPriceInput(0);
    setTicketTypeSelectedOption(0);
    setTicketNameSelectedOption('Todas');
}
export function addTicketToShoppingCar(setSelectedTickets: Function, ticket: any) {
    setSelectedTickets((prevSelectedTickets: any[]) => [...prevSelectedTickets, ticket]);
}
export function removeTicketToShoppingCar(selectedTickets:any,setSelectedTickets: Function, ticket: any){
    // @ts-ignore
    const updatedSelectedTickets = selectedTickets.filter((selectedTicket) => selectedTicket !== ticket);
    setSelectedTickets(updatedSelectedTickets);
}

export function applyFilters(tickets:ITicket[],filters:IFilterResaleTickets,setFilteredTickets:React.Dispatch<ITicket[]>){
    let filteredTickets = tickets.filter(ticket => {
        const isMinPriceValid = filters.minPrice === 0 || ticket.price >= filters.minPrice;
        const isMaxPriceValid = filters.maxPrice === 0 || ticket.price <= filters.maxPrice;
        const isTicketTypeValid = filters.ticketType === null || filters.ticketType === 0 || ticket.id === filters.ticketType;
        return (
            isMinPriceValid &&
            isMaxPriceValid &&
            isTicketTypeValid
        );
    });
    const sortedTickets = [...filteredTickets];
    if (filters.isAscending) {
        sortedTickets.sort((a, b) => a.price - b.price);
    } else {
        sortedTickets.sort((a, b) => b.price - a.price);
    }
    setFilteredTickets(sortedTickets);

}
export function cleanFilterMaxPrice(setFilters:React.Dispatch<IFilterResaleTickets>,setMaxPriceInput:React.Dispatch<string | number | null>){
    // @ts-ignore
    setFilters(prevFilters => ({ ...prevFilters, maxPrice: 0 }));
    setMaxPriceInput(0);
}
export function cleanFilterMinPrice(setFilters:React.Dispatch<IFilterResaleTickets>,setMinPriceInput:React.Dispatch<string | number | null>){
    // @ts-ignore
    setFilters(prevFilters => ({ ...prevFilters, minPrice: 0 }));
    setMinPriceInput(0);
}
export function cleanTicketType(setFilters:React.Dispatch<IFilterResaleTickets>,setTicketTypeSelectedOption:React.Dispatch<string | number | undefined>,setTicketNameSelectedOption:React.Dispatch<string>){
    // @ts-ignore
    setFilters(prev=>({...prev,ticketType:null}));
    setTicketNameSelectedOption('Todas');
    setTicketTypeSelectedOption(undefined);
}