import {Col, Row} from "antd";
import styles from './ImproveTicketBox.module.css';
import ticketImage from "../../assets/ion_ticket.svg";
import React, {useEffect, useState} from "react";
import {ITicketAvailable} from "../../solutions/interfaces/DTO/TicketImproveInfoDTO";

export default function ImproveTicketBox({onSelectTicket,name,price,description,ticket,isSelected}:{onSelectTicket?:Function,name:string,price:number,description:string,ticket?:ITicketAvailable,isSelected:boolean}){
    const [boxStyle, setBoxStyle] = useState<string>(styles.ticketBoxUnselected);

    useEffect(() => {
        if (isSelected) {
            setBoxStyle(`${styles.ticketBoxSelected}`);
        } else {
            setBoxStyle(styles.ticketBoxUnselected);
        }
    }, [isSelected])
    return (
        <Row justify={'center'}>
            <Col className={boxStyle} onClick={()=>(onSelectTicket && onSelectTicket(ticket))}  xs={{span:23,offset:0}} sm={{span:20,offset:0}} md={{span:17,offset:0}} lg={{span:14,offset:0}} xl={{span:11,offset:0}} xxl={{span:8,offset:0}}>
                <Row style={{ alignItems: "center", width:'100%' }}>
                    <Col xs={{offset:1,span:2}} sm={{offset:1,span:2}} md={{offset:1,span:2}} lg={{offset:1,span:2}} xl={{offset:1,span:2}}  xxl={{offset:1,span:2}}>
                        <img alt="ticketImg" src={ticketImage} className={styles.boxImage}
                        style={{marginLeft: 0}}/>
                    </Col>
                    <Col xs={{span:16,offset:0}} sm={{span:15,offset:0}} md={{span:15,offset:0}} lg={{span:15,offset:0}} xl={{span:15,offset:0}}
                    xxl={{span:15,offset:0}}>
                        <p className={styles.ticketTitle} style={{marginLeft:5}}>{name}</p>
                    </Col>
                    <Col xs={{span: 4, offset: 0 }} sm={{span: 3, offset: 2 }} md={{span: 3, offset: 1 }} lg={{span: 3, offset: 2 }} xl={{span: 3, offset: 2 }}  xxl={{ span: 3, offset: 2 }}>
                        <p className={styles.ticketTitle} style={{ fontSize: 30, marginBottom:0, marginTop:0}}>{price}€</p>
                    </Col>
                </Row>
                <Row style={{  width:'100%' }} align={'middle'} >
                    <Col xs={{span:14,offset:3}} sm={{span:15,offset:3}} md={{span:15,offset:3}} lg={{span:15,offset:3}} xl={{span:15,offset:3}} xxl={{span:15,offset:3}} >
                        <p className={styles.ticketMoreInfo} >{description}   </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}