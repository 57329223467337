import Marquee from "react-fast-marquee";
import styles from "./OfferNews.module.css";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {getNews} from "../../solutions/controllers/EventController";
import {INewsType} from "../../solutions/interfaces/INewsType";
import {NewsDTO} from "../../solutions/interfaces/DTO/NewsDTO";
import {INews} from "../../solutions/interfaces/INews";

export default function OfferNews({newsType,eventId,promoterId}:{newsType:INewsType,eventId?:number,promoterId?:number}){
    const [news, setNews] = useState<INews>();
    const dispatch = useDispatch();
    useEffect(()=>{
       if (!news){
           const dto:NewsDTO = {
               type:newsType,
               eventId:eventId,
               promoterId:promoterId
           }
           getNews(dto,setNews,dispatch);
       }
    })
    return (
        <>
            {news ? (
                news.link?(
                    <a href={news.link}  rel="noopener noreferrer">
                        <Marquee className={styles.newsContainer}>
                            <h1 className={styles.news}>{news.description}</h1>
                        </Marquee>
                    </a>
                ): news.description ? (
                    <Marquee className={styles.newsContainer}>
                        <h1 className={styles.news}>{news.description}</h1>
                    </Marquee>
                ) : <div style={{paddingTop: 30}}></div>

            ) : (
                <div style={{paddingTop: 30}}></div>
            )}
        </>
    )
}