import styles from "./TicketDetails.module.css";
import {Button, Col, message, Row, Space} from "antd";
import Header from "../../components/header/Header";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {downloadTicketPdf, loadTicketDetails, loadTicketQR} from "../../solutions/controllers/TickeDetailsController";
import {ITicketProfileDetails} from "../../solutions/interfaces/ITicketProfileDetails";

import {
    closeModal,
    formatDateToString, formatHourToString,
    openModal,
    refreshQR
} from "../../solutions/Utils";
import {
    cancelTicketResale,
    setResaleData
} from "../../solutions/controllers/TicketResaleController";
import QRCode from 'react-qr-code';

import calendarImage from '../../assets/calendar_white_profile.svg';
import clockImage from '../../assets/white_clock.svg';
import downloadImage from '../../assets/white_download.png';
import locationImage from '../../assets/white_location.svg';
import {useNavigate, useParams} from "react-router-dom";
import {getProfile} from "../../solutions/controllers/UserController";
import {IUser} from "../../solutions/interfaces/IUser";
import TicketResale from "../../components/ticketResale/TicketResale";
import {getImproveTickets} from "../../solutions/controllers/ImproveTicketController";
import {TicketImproveInfoDTO} from "../../solutions/interfaces/DTO/TicketImproveInfoDTO";
import {imagesPath} from "../../Controller/Settings";
export default function TicketDetails(){
    const [originalData, setOriginalData] = useState<ITicketProfileDetails>();
    const dispatch = useDispatch();
    const { ticketId } = useParams();
    const navigate = useNavigate(); // hook para navegar
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [seconds, setSeconds] = useState(-5);
    const [ticketDateFormatted, setTicketDateFormatted] = useState('');
    const [profile,setProfile] = useState<IUser | undefined>();
    const [isTicketResaleModalOpen, setTicketResaleModalOpen] = useState(false);
    const [isTicketResalePriceModalOpen, setTicketResalePriceModalOpen] = useState(false);
    const [maxPrice, setMaxPrice] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [commission, setCommission] = useState(0);
    const [commissionType, setCommissionType] = useState('');
    const [improveTicketData, setImproveTicketData] = useState<TicketImproveInfoDTO>();
    function loadTicketInfo() {
        if (auth && auth.logged) {
            getProfile(auth, setProfile, dispatch);
            //TODO comprobar si el ticket pertenece al usuario, si no
            if (ticketId) {
                // @ts-ignore
                loadTicketDetails(parseInt(ticketId, 10), auth, setOriginalData, (results) => {}, dispatch);
            } else {
                navigate('/');
            }
        } else {
            navigate('/');
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() =>{
        loadTicketInfo();
        // @ts-ignore

    }, [auth])
    function onChangeTicketOwnership(){
        navigate('/changeTicketOwnership/'+originalData?.ticketsoldid);
    }
    useEffect(() => {
        if(seconds !== -5 && originalData?.accessType === 'DYNAMIC_QR'){
            refreshQR(seconds, originalData.qrRefreshInterval, setSeconds, () =>{
                // @ts-ignore
                loadTicketQR(ticketId, originalData, auth, setOriginalData, dispatch);
            });
        }
    }, [seconds]);

    useEffect(() =>{
        if (originalData){
            setSeconds(originalData.qrRefreshInterval)
            setResaleData(originalData,setMinPrice,setMaxPrice,setCommission,setCommissionType);
            // @ts-ignore
            setTicketDateFormatted(formatDateToString(originalData.date))
        }
    }, [originalData]);
    useEffect(()=>{
        if (improveTicketData && originalData){
            if (improveTicketData.ticketsAvailable.length!==0){
                navigate('/improveTicket/'+originalData.ticketsoldid);
            }else{
                message.error('No hay tickets a los que se pueda mejorar.')
            }
        }
    },[improveTicketData])

    function downloadTicket() {
        if (originalData){
            function callback (msg:string){
                if (msg===''){
                    message.success("Entrada descargada.");
                }else{
                    message.error("Error al descargar su entrada: "+msg);
                }
            }
            downloadTicketPdf(auth,originalData.ticketName, originalData.eventName, originalData.ticketsoldid,callback,dispatch)
        }
    }
    function onResale() {
       if (originalData){
            navigate('/resaleTicket/'+ticketId);
        }
    }
    function onImproveTicket(){
        if (originalData){
            getImproveTickets(auth,()=>{},setImproveTicketData, originalData.ticketsoldid);
        }
    }

    function onCancelResale() {
        if (originalData){
            cancelTicketResale(originalData,auth,(msg:string)=>{
                if (msg===''){
                    message.success('Reventa cancelada.')
                    window.location.reload()
                }else{
                    message.error('Error: '+msg);
                }
                setTicketResaleModalOpen(false)
            });
        }else{
            message.error('Error en el proceso de cancelar entrada en reventa.')
        }
    }

    function openMap() {
        if (originalData  && originalData.address ) {
            const mapUrl = `https://www.google.com/maps?q=${originalData.address}`;
            window.open(mapUrl, '_blank');
        } else {
            message.info('La ubicación no está disponible');
        }
    }

    return (
        <div className={`${styles.background} footerSeparation`}>
            <Row style={{paddingBottom: 30, paddingTop: 30}} justify={'center'}>
                <Col span={24}>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>

            <Row justify={'center'}>
                {ticketId && originalData?(
                    <>
                        <TicketResale  isOpen={isTicketResaleModalOpen} onClose={() => closeModal(setTicketResaleModalOpen)} onOk={onCancelResale} />
                    </>
                ):<></>}
                <Col xs={20} sm={14} md={11} lg={9} xl={7} xxl={5} className={styles.container}>
                    {originalData?(
                        <>
                            <Row justify={'center'}>
                                <p className={styles.eventName}>{originalData.eventName}</p>
                            </Row>
                            <Row justify={'center'}>
                                {!originalData.nftSource && <img className={styles.defaultTicketImage} src={imagesPath + originalData.image} alt={'Ticket image'}/>}
                                {/*{originalData.nftType === 'IMAGE' && <img className={styles.defaultTicketImage} src={"data:image/png;base64,"+originalData.nftSource} alt={'Ticket image'}/>}*/}
                                {originalData.nftType === 'VIDEO' &&
                                    <p style={{color:'white'}}>NFT VIDEO...</p>
                                }
                                {originalData.nftType === 'GIF' &&
                                    <p style={{color:'white'}}>NFT GIF...</p>
                                }
                            </Row>
                            <Row justify={'center'}>
                                <p className={styles.ticketName}>{originalData.ticketName}</p>
                            </Row>


                            <Row justify={'center'}>
                                <p className={styles.subtitle} >DESCRIPCIÓN</p>
                            </Row>

                            <Row justify={'center'} >
                                <p className={styles.ticketDescription}>{originalData.ticketDescription}</p>
                            </Row>

                            {originalData.ticketeventextras && originalData.ticketeventextras.length>0?(
                                <Row justify={'center'}>
                                    <p className={styles.subtitle} >COMPLEMENTOS</p>
                                </Row>
                            ):<></>}

                            {originalData.ticketeventextras ?originalData.ticketeventextras.map((tex,texIndex)=>{
                                return (
                                    <Row justify={'center'} key={texIndex}>
                                        <p className={styles.ticketDescription}>{tex.ticketeventextra.toUpperCase()}</p>
                                    </Row>
                                )
                            }):<></>}

                            <Row style={{marginBottom:10}}></Row>
                            {originalData?.accessType === 'DYNAMIC_QR' && originalData.status!=='RESALE' &&
                                <Row >
                                    <Col xxl={19}>
                                        <p  className={styles.qrText}>ESTE QR CAMBIARÁ CADA {originalData.qrRefreshInterval} SEGUNDOS: </p>

                                    </Col>
                                    <Col xxl={4}>
                                        <p  className={styles.secondText}>{seconds} </p>
                                    </Col>
                                </Row>
                            }

                            <Row style={{marginBottom:10}}>
                                <Col xs={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24} sm={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24}
                                     md={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24} lg={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24}
                                     xl={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24} xxl={originalData?.accessType === 'DYNAMIC_QR' ? 16 : 24}>
                                    {originalData.town?(
                                        <Row style={{marginTop:10, marginLeft:10}}>
                                            <Space direction={"horizontal"}>
                                                <img className={styles.icon} src={locationImage} alt={'Location image'} />
                                                <p className={styles.eventInfoText} >{originalData.town.town} </p>
                                                <Button onClick={openMap} type={'text'} className={styles.mapButton}> (Ver mapa)
                                                </Button>
                                            </Space>

                                        </Row>
                                    ):<></>}
                                    {originalData.date?(
                                        <>
                                            <Row style={{marginTop:10, marginLeft:10}}>
                                                <Space direction={"horizontal"}>
                                                    <img className={styles.icon} src={clockImage} alt={'Clock image'}/>
                                                    <p className={styles.eventInfoText}>{formatHourToString(originalData.date)}</p>
                                                </Space>
                                            </Row>
                                            <Row style={{marginTop:10, marginLeft:10}}>
                                                <Space direction={"horizontal"}>
                                                    <img className={styles.icon} src={calendarImage} alt={'Calendar image'}/>
                                                    <p className={styles.eventInfoText}>{ticketDateFormatted}</p>
                                                </Space>
                                            </Row>
                                        </>
                                    ):<></>}
                                    {originalData?.accessType !== 'DYNAMIC_QR' && originalData.status!=='RESALE' &&

                                            <Row style={{marginTop:10, marginLeft:10,marginRight:10}} justify={'end'} >

                                                <Button style={{margin:0, padding:0}} type={'text'} onClick={downloadTicket} >
                                                    <Space direction={"horizontal"}>
                                                    <img className={styles.icon} src={downloadImage} alt={'Download image'}/>
                                                    <p className={styles.eventInfoText}>Descargar</p>
                                                    </Space>
                                                </Button>


                                            </Row>

                                    }

                                </Col>
                                {originalData?.accessType === 'DYNAMIC_QR' && originalData.status!=='RESALE' &&
                                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} style={{marginTop:20, marginLeft:10}}>
                                        <QRCode value={originalData.qr} size={85} />
                                    </Col>
                                }

                            </Row>
                            {originalData.status==='RESALE'?(
                                <Row style={{marginTop:20,}}>

                                    <Col xs={{span: 10, offset: 1}} sm={10} md={9} lg={8} xl={7} xxl={7}>
                                        <p className={styles.onResale}> EN REVENTA</p>
                                    </Col>
                                </Row>
                            ):<></>}

                        </>

                    ):<></>}
                </Col>

            </Row>
            {originalData && (originalData.status==='RESALE' || originalData.allowResale || originalData.allowRename || originalData.allowImprove)? (
                originalData.status !== 'RESALE' ? (
                    <>

                    <Row justify={'center'}  style={{marginTop:50,marginBottom:0}} >
                        {originalData.allowResale?(
                            <>
                                <Col xs={0} sm={0} md={{span:7,offset:0}} lg={{span:7,offset:0}} xl={{span:5,offset:0}} xxl={{span:3,offset:0}}>
                                    <Button className={'defaultButton'} onClick={onResale}>
                                        REVENDER
                                    </Button>
                                </Col>
                                <Col xs={{span:7,offset:0}} sm={{span:7,offset:0}} md={0} lg={0} xl={0} xxl={0} >
                                    <Button className={'defaultButton'}  style={{ fontSize: 9 }} onClick={onResale}>
                                        REVENDER
                                    </Button>
                                </Col>
                            </>
                        ):<></>}
                        {originalData.allowRename?(
                            <>
                                <Col xs={0} sm={0} md={{span:7,offset: originalData.allowResale ? 1 : 0}} lg={{span:7,offset: originalData.allowResale ? 1 : 0}} xl={{span:5,offset: originalData.allowResale ? 1 : 0}} xxl={{span:3,offset: originalData.allowResale ? 1 : 0}}>
                                    <Button className={'defaultButton'} onClick={onChangeTicketOwnership}>
                                        CAMBIO DE TITULAR
                                    </Button>
                                </Col>
                                <Col xs={{span:7,offset: originalData.allowResale ? 1 : 0}} sm={{span:7,offset: originalData.allowResale ? 1 : 0}} md={0} lg={0} xl={0} xxl={0}>
                                    <Button className={'defaultButton'} style={{fontSize:9}} onClick={onChangeTicketOwnership}>
                                        CAMBIO DE TITULAR
                                    </Button>
                                </Col>
                            </>

                        ):<></>}
                        {originalData.allowImprove?(
                            <>
                                <Col xs={0} sm={0} md={{span:7,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}} lg={{span:7,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}} xl={{span:5,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}} xxl={{span:3,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}}>
                                    <Button className={'defaultButton'} onClick={onImproveTicket} >
                                        MEJORAR MI ENTRADA
                                    </Button>
                                </Col>
                                <Col xs={{span:7,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}} sm={{span:7,offset: originalData.allowResale || originalData.allowRename ? 1 : 0}} md={0} lg={0} xl={0} xxl={0}>
                                    <Button className={'defaultButton'} style={{fontSize:9}} onClick={onImproveTicket}>
                                        MEJORAR MI ENTRADA
                                    </Button>
                                </Col>
                            </>
                        ):<></>}

                    </Row>


                    </>
                ):(
                    <>
                        <Row justify={'center'} style={{marginTop:15, marginBottom:0}} >
                            <Col xs={0} sm={0} md={{span:7,offset:0}} lg={{span:7,offset:0}} xl={{span:5,offset:0}} xxl={{span:4,offset:0}}>
                                <Button className={'defaultButton'} onClick={()=>openModal(setTicketResaleModalOpen)}>
                                    CANCELAR REVENTA
                                </Button>
                            </Col>
                            <Col xs={{span:10,offset:0}} sm={{span:10,offset:0}} md={0} lg={0} xl={0} xxl={0}>
                                <Button className={'defaultButton'} style={{fontSize:9}} onClick={()=>openModal(setTicketResaleModalOpen)}>
                                    CANCELAR REVENTA
                                </Button>
                            </Col>
                        </Row>

                    </>
                )

            ):<></>}

        </div>
    );
}