import {IEvent} from "../interfaces/IEvent";
import {ITown} from "../interfaces/ITown";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import React, {Dispatch} from "react";
import {ICategory} from "../interfaces/ICategory";
import {AnyAction} from "redux";

export function modifySearchText(text:string,setText:React.Dispatch<string>){
    setText(text)
}
export function filterEvents(originalEventData:IEvent[],filterValue:string,setFilterEventData:React.Dispatch<IEvent[]>){
    let filteredData:IEvent[];

    filteredData = originalEventData.filter(event =>
        event.title.toLowerCase().includes(filterValue.toLowerCase())
    );

    setFilterEventData(filteredData);
}

export function filterByPriceController(originalEvents:IEvent[],setFilteredEvents:React.Dispatch<IEvent[]>,min:number,max:number | null,setFilterMaxPriceValue:React.Dispatch<number|null>,setFilterMinPriceValue:React.Dispatch<number>){
    let filtered =[];
    if (max){
        filtered=originalEvents.filter(e=>e.price>=min && e.price<=max);
    }else{
        filtered=originalEvents.filter(e=>e.price>=min);
    }
    setFilteredEvents(filtered);
    setFilterMinPriceValue(0);
    setFilterMaxPriceValue(0);
}
export function filterByPublicationDateController(originalEvents:IEvent[],setFilteredEvents:React.Dispatch<IEvent[]>, value:String,setFilterDateValue:React.Dispatch<string>){
    const currentDate = new Date();
    let startDate:Date,endDate:Date;
    startDate=new Date(currentDate);
    if(value==='nextWeek'){
        startDate.setDate(currentDate.getDate()+1);
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate()+7);
        endDate.setHours(0, 0, 0, 0);


    }else if(value==='next24Hours'){
        endDate = new Date(currentDate);
        endDate.setHours(currentDate.getHours()+24);


    }else if (value==='nextMonth'){
        startDate.setMonth(currentDate.getMonth() + 1, 1);
        startDate.setDate(2);
        startDate.setHours(0, 0, 0, 0);

        endDate = new Date(currentDate);
        endDate.setMonth(currentDate.getMonth() + 2, 0);
        endDate.setDate(endDate.getDate()+1)
        endDate.setHours(23, 59, 59, 999);


    }else if(value==='nextYear'){
        startDate.setHours(0, 0, 0, 0);
        endDate = new Date(startDate);
        endDate.setFullYear(startDate.getFullYear()+1)
        endDate.setHours(23, 59, 59, 999);

    }else{
        setFilteredEvents(originalEvents);
    }
    const filteredEvents:IEvent[] = originalEvents.filter((event) => {
        if (!startDate || !endDate) {
            return true;
        }
        const eventDate = new Date(event.fromDate);

        return eventDate >= startDate && eventDate <= endDate;
    });
    setFilteredEvents(filteredEvents);
    setFilterDateValue('');
}
export function clearFilter(originalEvents:IEvent[],setFilteredEvents:React.Dispatch<IEvent[]>){
    setFilteredEvents(originalEvents);
}
export function filterByCountry(id:number,setFilterUbicationValue:React.Dispatch<number>, originalEvents:IEvent[],setFilteredEvents:React.Dispatch<IEvent[]>){
    let filtered = originalEvents.filter(ev=>ev.town.townid===id);
    setFilteredEvents(filtered);
    setFilterUbicationValue(0);
}
export function filterByCategory(id:number,setFilterEventTypeValue:React.Dispatch<number>, originalEvents:IEvent[],setFilteredEvents:React.Dispatch<IEvent[]>){
    let filtered = originalEvents.filter(ev=>ev.category.eventtypeid===id);
    setFilteredEvents(filtered);
    setFilterEventTypeValue(0);
}
export function toogleVisibility(visibility:boolean,setVisibility:React.Dispatch<boolean>){
    setVisibility(!visibility);
}
