import styles from "./TicketTypeResaleTicketFilter.module.css";
import Modal from "react-modal";
import { Col, ConfigProvider, Row, Select} from "antd";
import React from "react";
import {ITicket} from "../../solutions/interfaces/ITicket";
export default function TicketTypeResaleTicketFilter({options,isOpen,onFilter,onClose,selectedOption,setSelectedOption,setSelectedNameOption}:
                {options:ITicket[],isOpen:boolean,onFilter:Function,
                    onClose:Function,selectedOption:string | number | undefined,
                    setSelectedOption:React.Dispatch<string | number | undefined>
                    setSelectedNameOption:React.Dispatch<string>
                }) {
    // @ts-ignore
    const antdOptions = [
        { label: "Todas", value: 0 },
        ...getOptions(options).map((option) => ({
            label: option.ticketname,
            value: option.id
        }))
    ];
    function closeModal(){
        onClose();
    }

    function getOptions(options: {id: number, ticketname: string}[]) : {id: number, ticketname: string}[]{
        const aux = {};
        options.forEach(option => {
            //@ts-ignore
            aux[option.id] = option;
        })

        return Object.values(aux);
    }
    function handleSelectChange(option: any) {
        onFilter(option.value);
        closeModal();
        setSelectedOption(option.value);
        setSelectedNameOption(option.label);
    }

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={closeModal}
                className={styles.customModal}
                overlayClassName={styles.customOverlay}

            >
                <Row justify={'center'} align={'middle'} style={{marginTop:10,marginBottom:10}}>
                    <Col >
                        <h2 className={styles.modalTitle}>
                            Tipo de entrada
                        </h2>
                    </Col>
                    <Col offset={2}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    Select: {
                                        colorBgBase:'#1CEDAE',
                                        colorBorder:'black',
                                        algorithm: true,
                                        colorBgContainer:'#1CEDAE'
                                    }
                                },
                            }}
                        >
                            <Select
                                //@ts-ignore
                                defaultValue={antdOptions[0].label}
                                style={{ width: 300 }}
                                options={antdOptions}
                                value={selectedOption}
                                onChange={(value, option) => handleSelectChange(option)}
                            />
                        </ConfigProvider>
                    </Col>
                </Row>


            </Modal>
        </>
    )
}