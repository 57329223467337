import {Seat} from "./model/Seat";
import _ from "lodash";
import {Button, message, Row} from "antd";
import React from "react";


export function checkSeatRules(selectedSeats: Seat[], seatsBySector?: any) : boolean{
    console.log("Selected seat ",selectedSeats)
    let valid : boolean = true;
    if(selectedSeats && selectedSeats.length > 0){
        const seatBySector = _.groupBy(selectedSeats, seat => seat.sector);
        Object.keys(seatBySector).forEach(sector => {
            const seatByRow = _.groupBy(seatBySector[sector], seat => seat.row);
            Object.keys(seatByRow).forEach(row => {
                const allSeatByRow = getSeatsByRow(row, sector, seatsBySector).sort((a: Seat, b: Seat) => a.position < b.position ? -1 : 1);

                if(allSeatByRow.length > 0){//Si no está cargado el sector no se comprueba nada, ya se ha comprobado antes.
                    const seats = seatByRow[row].sort((a: Seat, b: Seat) => a.position < b.position ? -1 : 1);

                    if(lessThanTwoFreeSeats(seats, allSeatByRow)){
                        valid = false;
                        return;
                    }
                    if(freeSpacesBetweenSeats(seats, allSeatByRow)){
                        valid = false;
                        message.info({
                            key: "msgKey",
                            onClick:(() => message.destroy('msgKey')),
                            content: <>
                                <Row justify={"center"}>
                                    <h2>Ayúdanos a optimizar el recinto.</h2>
                                </Row>
                                <Row>
                                    <span>No puede dejar asientos libres entre su selección.</span>
                                </Row>
                                <Row style={{paddingTop: 20}} justify={"center"}>
                                    <Button type={"primary"} onClick={() => message.destroy('msgKey')}>OK</Button>
                                </Row>
                            </>,
                            duration: 0,
                            style: {
                                marginTop: '20vh',
                            },
                        })
                        return;
                    }
                }
            });
        })
    }

    return valid;
}

export function lessThanTwoFreeSeats(seats: Seat[], rowSeats: Seat[]) : boolean {
    const startPosition = seats[0].position;
    const endPosition = seats[seats.length-1].position;

    const startFreePosition = rowSeats.filter(seat => seat.position < startPosition)
        .sort((a: Seat, b: Seat) => b.position < a.position ? -1 : 1);

    let leftFree = 0;
    let left = true;
    let lastLeftSeatProcessed : Seat = seats[0];

    console.log("lastLeftSeatProcessed", lastLeftSeatProcessed)

    startFreePosition.forEach(seat => {
        if(seat.busy || seat.disabled || (lastLeftSeatProcessed && lastLeftSeatProcessed.position - seat.position) > 1){
            left = false;
        }
        else if(left){
            leftFree++;
        }
        lastLeftSeatProcessed = seat;
    });

    let rigthFree = 0;
    let rigth = true;
    let lastRigthSeatProcessed : Seat = seats[seats.length-1];
    const endFreePosition = rowSeats.filter(seat => seat.position > endPosition);

    endFreePosition.forEach(seat => {
        if(seat.busy || seat.disabled || (lastRigthSeatProcessed && seat.position - lastRigthSeatProcessed.position) > 1){
            rigth = false;
        }
        else if(rigth){
            rigthFree++;
        }
        lastRigthSeatProcessed = seat;
    });

    if(leftFree == 1 && rigthFree > 0){
        console.log("Asientos libres insuficientes por la izquierda")
        message.info({
            key: "msgKey",
            onClick:(() => message.destroy('msgKey')),
            content: <>
                <Row justify={"center"}>
                    <h2>Ayúdanos a optimizar el recinto.</h2>
                </Row>
                <Row>
                    <span>Por favor, mueve tu selección un asiento a la izquierda.</span>
                </Row>
                <Row style={{paddingTop: 20}} justify={"center"}>
                    <Button type={"primary"} onClick={() => message.destroy('msgKey')}>OK</Button>
                </Row>
            </>,
            duration: 0,
            style: {
                marginTop: '20vh',
            },
        })
        return true;
    }

    if(rigthFree == 1 && leftFree > 0){
        console.log("Asientos libres insuficientes por la derecha")
        message.info({
            key: "msgKey",
            onClick:(() => message.destroy('msgKey')),
            content: <>
                <Row justify={"center"}>
                    <h2>Ayúdanos a optimizar el recinto.</h2>
                </Row>
                <Row>
                    <span>Por favor, mueve tu selección un asiento a la derecha.</span>
                </Row>
                <Row style={{paddingTop: 20}} justify={"center"}>
                    <Button type={"primary"} onClick={() => message.destroy('msgKey')}>OK</Button>
                </Row>
            </>,
            duration: 0,
            style: {
                marginTop: '20vh',
            },
        })
        return true;
    }

    return false;
}

export function freeSpacesBetweenSeats(seats: Seat[], rowSeats: Seat[]) : boolean {
    const startPosition = seats[0].position;
    const endPosition = seats[seats.length-1].position;

    const freeSeats = rowSeats.filter(seat => seat.position > startPosition && seat.position < endPosition &&
        !seats.some(selectedSeat => selectedSeat.id === seat.id) && !seat.busy && !seat.disabled);
    // console.log("free seats", freeSeats)
    return freeSeats.length > 0;
}

export function getSeatsByRow(row: string, sector: string, seatsBySector?: any) : Seat[]{
    let seatByRow : Seat[] = [];

    if(!seatsBySector || ! seatsBySector[sector]){
        const groups = document.querySelectorAll(".seat");

        groups.forEach((group) => {
            const rowId = group.getAttribute("data-row");
            const sectorId = group.getAttribute("data-sector");

            if (rowId && rowId === row && sectorId && sectorId === sector) {
                seatByRow.push(getSeatByElement(group))
            }
        });
    }
    else{
        seatByRow = seatsBySector[sector][row];
    }

    return seatByRow;
}

export function getSeatsBySector(sectorId: string) : { }{
    let result = {};
    const allSeatsInSector = document.querySelectorAll(".seat");

    const seatBySector = _.groupBy(allSeatsInSector, seat => seat.getAttribute("data-sector"));
    Object.keys(seatBySector).forEach(sector => {
        const seatByRow = _.groupBy(seatBySector[sector], seat => seat.getAttribute("data-row"));
        //@ts-ignore
        result[sector] = {};
        Object.keys(seatByRow).forEach(row => {
            // @ts-ignore
            result[sector][row] = seatByRow[row].map(s => getSeatByElement(s));
        })
    })

    console.log("result", result)
    return result;
}

export function getSeatByElement(element: Element) {
    let ticketEventId = element.getAttribute("data-ticketeventid");
    let ticketSoldId = element.getAttribute("data-ticketsoldid");
    let nominativeElement = element.getAttribute("data-nominative");
    let busyElement = element.getAttribute("data-busy");
    let disabledElement = element.getAttribute("data-disabled");

    let seat = new Seat({
        id: parseInt(element.getAttribute("data-id") as string),
        seat: element.getAttribute("data-seat"),
        position: parseInt(element.getAttribute("data-position") as string),
        row: element.getAttribute("data-row"),
        price: parseFloat(element.getAttribute("data-price") as string),
        entrance: element.getAttribute("data-entrance"),
        sector: element.getAttribute("data-sector"),
        ticketeventid: ticketEventId ? parseInt(ticketEventId as string) : null,
        ticketsoldid: ticketSoldId ? parseInt(ticketSoldId as string) : null,
        commission: parseFloat(element.getAttribute("data-commission") as string),
        handicapped: 'true' == element.getAttribute("data-handicapped") as string,
        nominative: nominativeElement ? (nominativeElement as string === "true") : false,
        busy: busyElement ? (busyElement as string === "true") : false,
        disabled: disabledElement ? (disabledElement as string === "true") : false
    });
    return seat;
}