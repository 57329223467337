import {ITicketQuantities} from "../interfaces/ITicketQuantities";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {ITicket} from "../interfaces/ITicket";
import {ITicketInfo} from "../interfaces/ITicketInfo";
import {IUser} from "../interfaces/IUser";
import {generateUuidAndExecuteCallback, randomString} from "../Utils";
import {Seat} from "../../components/seatReservation/model/Seat";
import {AuthBody} from "../interfaces/server-info";
import {FormInstance, message} from "antd";
import React from "react";
import {IDiscount} from "../interfaces/IDiscount";
import {DiscountType} from "../interfaces/DiscountType";
import moment from "moment/moment";
import {SaleType} from "../PayUtils";

const taxes: number = 5;

export function getTaxes() {
    let tax: number = 5;
    return tax;
}

export function calculateTicketsNumber(ticketQuantities: ITicketQuantities): number {
    let result: number = 0;
    let quantity: number = 0;
    for (const ticketId in ticketQuantities) {
        quantity = ticketQuantities[ticketId];
        if (quantity > 0) {
            result += quantity;
        }
    }
    return result;
}

export function updateTicketQuantities(ticketId: number, value: number, setTicketQuantities: React.Dispatch<React.SetStateAction<ITicketQuantities>>) {
    setTicketQuantities((prevQuantities) => ({
        ...prevQuantities,
        [ticketId]: value,
    }));
}

export function checkIBuyButtonIsAvailable(ticketQuantities: ITicketQuantities, numberedTickets: Seat[], setCanBuy: React.Dispatch<React.SetStateAction<boolean>>) {
    let result: boolean = false;
    for (let id in ticketQuantities) {
        if (ticketQuantities[id] > 0) {
            result = true;
            break;
        }
    }
    setCanBuy(result || (numberedTickets && numberedTickets.length > 0));
}

export function getTicketListPerQuantity(tickets: ITicket[], ticketQuantities: ITicketQuantities): ITicketInfo[] {

    const ticketList: ITicketInfo[] = [];
    tickets.forEach((ticket) => {
        const ticketId = ticket.id;
        if (ticketQuantities.hasOwnProperty(ticketId)) {
            const quantity = ticketQuantities[ticketId];
            for (let i = 0; i < quantity; i++) {
                ticketList.push(
                    {
                        gift: false,
                        ticket: ticket,
                        email: '',
                        firstName: '',
                        lastName: '',
                        birthDateString: '',
                        phoneNumber: '',
                        postalCode: '',
                        isDatePickerVisible: false,
                        exhausted: false,
                        nominative: false,
                        commission: 0.0,
                        seatid: null
                    }
                );
            }
        }
    });


    return ticketList;
}

export default function copyTitularData(index: number, profile: IUser, ticketList: ITicketInfo[], setTicketList: React.Dispatch<React.SetStateAction<ITicketInfo[]>>, setIsCopied:React.Dispatch<boolean>) {
    const newTicketList = [...ticketList];
    const ticket = {...newTicketList[index]};

    ticket.email = profile.email;
    ticket.firstName = profile.name;
    //@ts-ignore
    ticket.birthdate = profile.birthdate ? profile.birthdate : null;
    ticket.phoneNumber = profile.phone;
    ticket.postalCode = profile.zipcode;

    newTicketList[index] = ticket;
    setTicketList(newTicketList);
    setIsCopied(true);
}
export function copyInvitedUser(index: number, ticketList: ITicketInfo[], setTicketList: React.Dispatch<React.SetStateAction<ITicketInfo[]>>, form: FormInstance<any>, setIsCopied:React.Dispatch<boolean>){
    const newTicketList = [...ticketList];
    const ticket = {...newTicketList[index]};
    let email = form.getFieldValue('email');
    let firstName = form.getFieldValue('firstName');
    let birthDate = form.getFieldValue('birthdate');
    let phoneNumber = form.getFieldValue('phoneNumber');
    let postalCode = form.getFieldValue('postalCode');
    let fields = form.getFieldsError().filter(value=>{
        let stringValue = value.name.toString();
        return stringValue==='email' || stringValue==='firstName' || stringValue==='email2' || stringValue==='birthdate' || stringValue==='phoneNumber' || stringValue==='postalCode'
    });
    let hasErrors = fields.some(value=>value.errors.length!==0);
    if (!hasErrors){
        if (email && firstName && birthDate && phoneNumber && postalCode){
            ticket.email=email;
            ticket.firstName=firstName;
            ticket.birthdate=birthDate;
            ticket.phoneNumber=phoneNumber;
            ticket.postalCode=postalCode;
            newTicketList[index] = ticket;
            setTicketList(newTicketList);
            setIsCopied(true);

        }else{
            message.error("Rellene los datos del comprador.")
        }
    }else{
        message.error("Rellene los datos del comprador.")
    }
}


export function changeTicketInfo(field: string, value: any, index: number, ticketList: ITicketInfo[], setTicketList: React.Dispatch<React.SetStateAction<ITicketInfo[]>>) {
    let ticketInfo = {...ticketList[index], [field]: value};
    let newTicketInfos = [...ticketList];
    newTicketInfos[index] = ticketInfo;

    setTicketList(newTicketInfos);
}

export function openLoginForm(setIsLoginVisible: any, setIsCreateAccountVisible: any) {
    setIsCreateAccountVisible(true);
    setIsLoginVisible(false);
}

export function openCreateForm(setIsLoginVisible: any, setIsCreateAccountVisible: any) {
    setIsLoginVisible(true);
    setIsCreateAccountVisible(false);
}

export function calculateMoneyAmountToBuy(tickets: ITicket[], ticketQuantities: ITicketQuantities, numberedTickes: Seat[]): number {

    let total = 0;
    tickets.forEach((ticket) => {
        const ticketId = ticket.id;
        if (ticketQuantities.hasOwnProperty(ticketId)) {
            const quantity = ticketQuantities[ticketId];
            for (let i = 0; i < quantity; i++) {
                total += ticket.price;
            }
        }
    });

    if (numberedTickes) {
        numberedTickes.forEach(value => total += value.price)
    }

    return total;
}

export function calculateTotalToPayWithTaxes(ticketList: ITicketInfo[], numberedTickets: Seat[], setTotal: React.Dispatch<number>,
                                             setTaxes: React.Dispatch<number>) {
    let total = 0;
    let taxes = 0;

    ticketList.forEach((ticket) => {
        taxes += ticket.ticket.commission;
        total += ticket.ticket.price + ticket.ticket.commission;
    })
    numberedTickets.forEach((ticket) => {
        taxes += ticket.commission || ticket._commission;
        total += (ticket.price || ticket._price ) + (ticket.commission || ticket._commission);
    })
    setTotal(total);
    setTaxes(taxes);
}

export function calculateTotalToPayWithTaxeAndReturn(ticketList: ITicketInfo[], numberedTickets: Seat[]) {
    let total = 0;
    let taxes = 0;

    ticketList.forEach((ticket) => {
        taxes += ticket.ticket.commission;
        total += ticket.ticket.price + ticket.ticket.commission;
    })
    numberedTickets.forEach((ticket) => {
        taxes += ticket.commission;
        total += (ticket.price ) + (ticket.commission);
    })

    return{
        total: total,
        taxes: taxes
    }
}

export function areNominativeInTicketList(setAreNominative: React.Dispatch<boolean>, ticketList: ITicketInfo[], numberedTickets: Seat[]) {
    setAreNominative(ticketList.some((t) => t.ticket.nominative) || numberedTickets.some((t) => t.nominative));
}

export function areNominativeInTicketListReturn(ticketList: ITicketInfo[], numberedTickets: Seat[]) {
    return ticketList.some((t) => t.ticket.nominative) || numberedTickets.some((t) => t.nominative);
}

export function areExtrasAndNotNominativeInTicketListReturn(ticketList: ITicketInfo[], numberedTickets: Seat[]) {
    return ticketList.some((t) =>
        !t.ticket.nominative &&
        t.ticket.ticketeventextras &&
        t.ticket.ticketeventextras.some((extra) => extra.active)
    ) || numberedTickets.some((t) =>
        //@ts-ignore
        t.ticketeventextras &&
        //@ts-ignore
        t.ticketeventextras.some((extra) => extra.active)
    );
}

export function buyTickets(ticketList: ITicketInfo[], loggedUser: any, invitedUser: any, idOper: string, orderId: string, totalPrice: number,
                           discountIds :any, paymentdetailid: number, rrpp: string, fromResale: boolean, callback: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {
            console.log("Transactionid", uuid)

            const ticketsToBuy = ticketList.map((ticket) => {
                return ({
                    ticketeventid: ticket.ticket.id,
                    ticketsoldid: ticket.ticket.ticketsoldid,
                    gift: ticket.gift,
                    seatid: ticket.seatid,
                    userInfoDTO: {
                        email: ticket.email,
                        name: ticket.firstName + (ticket.lastName ? (" " + ticket.lastName) : ""),
                        birthdate: ticket.birthdate,
                        zipCode: ticket.postalCode,
                        phone: ticket.phoneNumber
                    },
                    ticketeventextraList: ticket.ticketeventextrasadded
                })
            })


            const txlRequest = {
                idOper: idOper,
                orderId: orderId,
                amount: totalPrice,
                description: "Compra en Tixxlab",
                transactionId: uuid,
                paymentdetailid: paymentdetailid
            }


            const buyInfoDTO = {
                ticketInfoDTOList: ticketsToBuy,
                logguedUser: loggedUser,
                invitedUser: invitedUser,
                txlPaymentRequest: txlRequest,
                publicRelationsToken: rrpp,
                ticketeventdiscountList:[],
                fromResale: fromResale
            }

            if(discountIds && discountIds.length > 0){
                // @ts-ignore
                buyInfoDTO.ticketeventdiscountList= discountIds;
            }

            let url = rootServiceURL() + "completegraph/event/buyTickets";
            let rc = new RestClient(dispatch).url(url);

            message.loading({
                key: initPaymentMsg,
                onClick:(() => message.destroy(initPaymentMsg)),
                content: 'Iniciando proceso de compra...',
                duration: 10,
            })

            rc.doPost(buyInfoDTO, (results: any) => {
                if (rc.isSuccess()) {
                    callback && callback(results);
                } else {
                    alert("Error preauth  " + rc.getErrors())
                    message.destroy(initPaymentMsg)
                }
            }, false)
        }
    })
}

export function buyRedirect(ticketList: ITicketInfo[], loggedUser: any, invitedUser: any, paymentdetailid: number, orderId: string,
                            totalPrice: number, discountIds: any, rrpp: string | undefined, fromResale: boolean, callback: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {
            const ticketsToBuy = ticketList.map((ticket) => {
                return ({
                    ticketeventid: ticket.ticket.id,
                    ticketsoldid: ticket.ticket.ticketsoldid,
                    gift: ticket.gift,
                    seatid: ticket.seatid,
                    userInfoDTO: {
                        email: ticket.email,
                        name: ticket.firstName + (ticket.lastName ? (" " + ticket.lastName) : ""),
                        birthdate: ticket.birthdate,
                        zipCode: ticket.postalCode,
                        phone: ticket.phoneNumber
                    },
                    ticketeventextraList: ticket.ticketeventextrasadded
                })
            })

            const txlRequest = {
                orderId: orderId,
                amount: totalPrice,
                description: "Compra en Tixxlab",
                transactionId: uuid,
                paymentdetailid: paymentdetailid,
            }


            const buyInfoDTO = {
                ticketInfoDTOList: ticketsToBuy,
                logguedUser: loggedUser,
                invitedUser: invitedUser,
                txlPaymentRequest: txlRequest,
                publicRelationsToken: rrpp,
                fromResale: fromResale
            }

            if(discountIds && discountIds.length > 0){
                // @ts-ignore
                buyInfoDTO.ticketeventdiscountList= discountIds;
            }
            console.log("buyInfoDTO", buyInfoDTO)


            message.loading({
                key: initPaymentMsg,
                onClick:(() => message.destroy(initPaymentMsg)),
                content: 'Iniciando proceso de compra...',
                duration: 10,
            })

            let url = rootServiceURL() + "completegraph/event/buyTicketsRedirect";
            let rc = new RestClient(dispatch).url(url);

            rc.doPost(buyInfoDTO, (results: any) => {
                console.log("initRedirectionRequest ", results)
                if (rc.isSuccess()) {
                    callback && callback(results);
                } else {
                    alert("Error iniciando pago  " + rc.getErrors())
                    message.destroy(initPaymentMsg)
                }
            }, false)
        }
    })
}

// export function preauth(idOper: string, orderId: string, buyInfoDTO: any, totalPrice: number, callback: any, dispatch: any) {
//     generateUuidAndExecuteCallback((uuid) => {
//         if (!uuid) {
//             alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
//         } else {
//             console.log("Transactionid", uuid)
//             const txlRequest = {
//                 idOper: idOper,
//                 orderId: orderId,
//                 cardNumber:"4548814479727229",
//                 expirationDate:"1234",
//                 cvv2:"123",
//                 amount: totalPrice,
//                 description: "Compra en Tixxlab",
//                 transactionId: uuid
//             }
//
//             console.log("txlRequest", txlRequest)
//
//             let url = rootServiceURL() + "completegraph/payment/preauth";
//             let rc = new RestClient(dispatch).url(url);
//
//             rc.doPost(txlRequest, (results: any) => {
//                 console.log("preauth ", results)
//                 if (rc.isSuccess()) {
//                     callback && callback(results);
//                 } else {
//                     alert("Error preauth  " + rc.getErrors())
//                 }
//             }, false)
//         }
//     })
// }


export function auth(saleType: SaleType, idOper: string, orderId: string, totalPrice: number, authBody: AuthBody, callback: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {
            console.log("Transactionid", uuid)
            if ("threeDSServerTransID" in authBody.transactionData) {
                let txlRequest = {
                    idOper: idOper,
                    orderId: orderId,
                    cardNumber: "4548814479727229",
                    expirationDate: "1234",
                    cvv2: "123",
                    amount: totalPrice,
                    description: "Compra en Tixxlab",
                    transactionId: uuid,
                    browserInfo: authBody.clientInfo,
                    threeDSServerTransID: authBody.transactionData.threeDSServerTransID,
                    //@ts-ignore
                    threeDSProtocolVersion: authBody.transactionData.threeDSProtocolVersion
                }

                console.log("txlRequest", txlRequest)

                let url = rootServiceURL() + "completegraph/payment/auth";
                if(saleType === SaleType.RENAME){
                    url += "Rename"
                    txlRequest = {...txlRequest, description: "Cambio de nombre en Tixxlab"}
                }
                else if(saleType === SaleType.IMPROVE){
                    url += "Improve"
                    txlRequest = {...txlRequest, description: "Mejora de entrada en Tixxlab"}
                }
                let rc = new RestClient(dispatch).url(url);

                rc.doPost(txlRequest, (results: any) => {
                    console.log("auth ", results)
                    if (rc.isSuccess()) {
                        callback && callback(results);
                    } else {
                        console.error("Error auth " + rc.getErrors())
                        callback(null);
                    }
                }, false)
            }
        }
    })
}

//Test function
export function initRedirectionRequest(orderId: string, buyInfoDTO: any, totalPrice: number, callback: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {
            console.log("Transactionid", uuid)
            const txlRequest = {
                orderId: orderId,
                amount: totalPrice,
                description: "Compra en Tixxlab",
                transactionId: uuid,
                paymentdetailid: 1
            }

            const buyInfoDTO = {
                txlPaymentRequest: txlRequest
            }

            console.log("txlRequest redireccion", buyInfoDTO)

            let url = rootServiceURL() + "completegraph/payment/initRedirectionRequest";
            let rc = new RestClient(dispatch).url(url);

            rc.doPost(buyInfoDTO, (results: any) => {
                console.log("initRedirectionRequest ", results)
                if (rc.isSuccess()) {
                    callback && callback(results);
                } else {
                    alert("Error initRedirectionRequest  " + rc.getErrors())
                }
            }, false)
        }
    })
}

export function loadPayDetail (orderId: string, transactionId: string, setData: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/payment/getPayDetail/"+orderId+"/"+transactionId;
    let rc = new RestClient(dispatch).url(url);

    rc.doGet((results: any) => {
        if (rc.isSuccess()) {
            setData && setData(results);
        } else {
            alert("Error obteniendo datos de pago " + rc.getErrors())
        }
    })
}

export function getRandomOrderId () : string {
    const year = new Date().getFullYear();
    return year + randomString(8);
}

export function create3DSMethodForm (
    threeDSServerTransID: string,
    threeDSMethodURL:string,
    notificationURL: string
): any  {
    const methodData = {
        threeDSServerTransID: threeDSServerTransID,
        threeDSMethodNotificationURL: notificationURL
    };

    const json = JSON.stringify(methodData);

    return {
        url: threeDSMethodURL,
        body: {
            threeDSMethodData: btoa(json)
        }
    }
}

export let orderId: string | null = null;


export function generateAndGetOrderId() {
    orderId = getRandomOrderId();
    return orderId;
}

export let buyFormValue: boolean = false;

export function setBuyFormValueIsValid(valid: boolean){
    buyFormValue = valid;
}
export function isEmailDuplicate(ticketList:ITicketInfo[],email:string, id:number)  {
    const otherEmails = ticketList
        .filter((_, i) => i !== id)
        .map(ticket => ticket.email);

    return otherEmails.filter(emailObtained=>emailObtained===email).length>1;
}

export function getDiscount(setOriginalTotalPrice:React.Dispatch<number>,discountCode:string,tickets:ITicketInfo[],setDiscount:React.Dispatch<number>,
                            globalState:any,setGlobalState:React.Dispatch<any>,callback:Function,dispatch:any){
    let url = rootServiceURL() + "completegraph/event/getDiscount/"+globalState.eventId+"/"+discountCode;
    let rc = new RestClient(dispatch).url(url);
    rc.doGet((results: IDiscount[]) => {
        if (rc.isSuccess() && results) {
            console.log("ticket", tickets)
            console.log("globalState", globalState)

            const matchingTicketsWithDiscount = [];
            let discountToApply = 0;

            tickets.forEach(ticket => {
                const discountsForTicket : IDiscount[] = results.filter((d: IDiscount) =>
                    ticket.ticket.ticketeventid === d.ticketeventid.ticketeventid || ticket.ticket?.id === d.ticketeventid.ticketeventid);

                if(discountsForTicket && discountsForTicket.length > 0){
                    const discount = discountsForTicket[0];
                    const disconutQuantity = discount.discounttype===DiscountType.FIXED ? discount.quantity : parseFloat(((discount.quantity / 100) * ticket.ticket.price).toFixed(2))
                    matchingTicketsWithDiscount.push({
                        ticket: ticket,
                        discount: discount,
                        discountToApply: disconutQuantity
                    });

                    discountToApply += disconutQuantity;
                }
            })

            if (matchingTicketsWithDiscount.length > 0){
                const newPrice = globalState.total - discountToApply;
                // setOriginalTotalPrice(globalState.total);
                setDiscount(discountToApply);
                setGlobalState((prev: any) => ({ ...prev, total: newPrice, discountIds: results }));
                callback && callback('');
            }else{
                callback && callback('El descuento introducido no es válido para las entradas seleccionadas');
            }
        } else {
            callback && callback(rc.getErrors());
        }
    })
}
export function deleteDiscount(originalPrice:number,discount: number, setDiscount:React.Dispatch<number>,setGlobalState:React.Dispatch<any>,){
    setDiscount(0);
    setGlobalState((prev: any) => ({ ...prev, total: originalPrice +discount, discountIds: [] }));
}
export function validateMinimumAge(value:number,ticket:ITicket):boolean{
    const years = moment().diff(new Date(value), 'years');
    const minAge = ticket.minimumage;
    let result = false;
    if(minAge){
        if (years<minAge){
            result=true;
        }
    }
    return result;
}

export const initPaymentMsg = "initPay"
export const authPaymentMsg = "authPay"
export const challengePaymentMsg = "challengePay"