import {Button, Checkbox, Col, ConfigProvider, Form, Input, message, Row, Space} from "antd";
import LoginForm from "../../components/loginForm/LoginForm";
import RegisterForm from "../../components/registerForm/RegisterForm";
import React, {useEffect, useRef, useState} from "react";
import {IUser} from "../../solutions/interfaces/IUser";
import {getProfile, openCreateAccount, openLogin} from "../../solutions/controllers/UserController";
import styles from "./Buy.module.css";
import {ITicketInfo} from "../../solutions/interfaces/ITicketInfo";
import {useLocation, useNavigate} from "react-router-dom";
import copyTitularData, {
    areExtrasAndNotNominativeInTicketListReturn,
    areNominativeInTicketListReturn,
    auth,
    authPaymentMsg,
    buyRedirect,
    buyTickets,
    calculateTotalToPayWithTaxeAndReturn,
    challengePaymentMsg,
    changeTicketInfo,
    copyInvitedUser,
    create3DSMethodForm,
    deleteDiscount, generateAndGetOrderId,
    getDiscount,
    getRandomOrderId,
    isEmailDuplicate,
    orderId,
    setBuyFormValueIsValid,
    validateMinimumAge
} from "../../solutions/controllers/BuyController";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {FieldData} from "rc-field-form/lib/interface";
import {Seat} from "../../components/seatReservation/model/Seat";
import {AuthBody, ThreeDSBrowserClientInfo} from "../../solutions/interfaces/server-info";
import {rootServiceURL} from "../../Controller";
import {
    deleteUuid,
    generateUuid,
    waitForIframeToPostMessage
} from "../../solutions/Utils";
import RestClient from "../../Controller/rest/RestClient";
import shoppingCarImage from "../../assets/shoppingCar.png";
import userImage from "../../assets/user.png";
import summaryImage from "../../assets/summary.png";
import deleteDiscountImage from "../../assets/greenDelete.png";

import {
    cleanMessagesWebsocket,
    openWebSocket,
    subscribeTo,
    unsubscribe
} from "../../Controller/redux/websocket/WebSocketController";
import ReactPixel from "react-facebook-pixel";

import DatePicker from "react-date-picker";
import "../../components/registerForm/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Header from "../../components/header/Header";
import warningImage from "../../assets/bi_exclamation.svg";
import {IDiscount} from "../../solutions/interfaces/IDiscount";
import TicketEventExtraBox from "../../components/ticketEventExtraBox/TicketEventExtraBox";
import {ITicketEventExtra} from "../../solutions/interfaces/ITicketEventExtra";
import {addTicketEventExtraToSummary} from "../../solutions/controllers/TicketEventExtraController";
import {destroyBuyProccessMsg, SaleType} from "../../solutions/PayUtils";
import {IEventDetails} from "../../solutions/interfaces/IEventDetails";
import {IPaymentTypeDetail} from "../../solutions/interfaces/IPaymentTypeDetail";


type FieldType = {
    firstName1?: string;
    lastName1?: string;
    birthdate1?: string;
    email1?: string;
    discountCode?:string;
    cardHolder?:string;
    cardNumber?:number;
    expiry:number;
    cvc:number;
    phoneNumber1?: number;
    postalCode1?: number;
};
export default function Buy({setFormValidated, setPaymentInsite}:{setFormValidated: Function, setPaymentInsite: Function}){
    const [profile, setProfile] = useState<IUser>();
    const [originalTotalPrice, setOriginalTotalPrice] = useState<number>(0);
    const [isCreateAccountVisible, setIsCreateAccountVisible] = useState(true);
    const [isLoginVisible, setIsLoginVisible] = useState(false);
    const [form] = Form.useForm();
    const [discountForm] = Form.useForm();
    const [discount, setDiscount] = useState(0);
    const [discountCode,setDiscountCode] = useState<string>();
    const [ticketList, setTicketList] = useState<ITicketInfo[]>([]);
    const [groupedTickets, setGroupedTickets] = useState<any[]>([]);
    const [fieldsData, setFieldsData] = useState<FieldData[]>([]);
    const [total, setTotal] = useState(0);
    const [taxes, setTaxes] = useState(0);
    const [idOper, setIdOper] = useState<string | null>(null)
    // const [orderId , setOrderId] = useState(getRandomOrderId())
    const [areNominative,setAreNominative] = useState(false);
    const [isCopied, setIsCopied] = useState(false);
    const { state } = useLocation(); // state is any or unknown
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const authSelector = useSelector((state : any) => state.auth);
    const websocketSelector = useSelector((state : any) => state.websocket);
    const [globalState, setGlobalState] = useState<{
        eventId: number,
        pixelId: string,
        rrpp?:string,
        groupedTickets?: any[],
        total: number,
        taxes: number,
        areNominative?: boolean,
        areExtrasAndNotNominative?:boolean,
        discountIds?: IDiscount[],
        selectedTicketExtras?:ITicketEventExtra[],
        fromResale: boolean
    } | undefined>(undefined)
    const [checkPayment, setCheckPayment] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentTypeDetail[]>([]);
    const [paymentTypeSelected, setPaymentTypeSelected] = useState<IPaymentTypeDetail | null>(null);
    const [isFormValid, setFormValid] = useState(false)
    //Necesary for iframes for Redsys
    const [activeSection, setActiveSection] = useState<string>('');
    const [doingPayment, setDoingPayment] = useState(false)
    const [uuid, setUuid] = useState('')


    const sectionRefV1 = useRef(null);
    const sectionRefV2 = useRef(null);

    const threeDSMethodIframeEl = useRef<HTMLIFrameElement>();
    let threeDSMethodFormEl =  useRef<HTMLFormElement>()
    let threeDSMethodDataEl = useRef<HTMLInputElement>();

    let challengeV1IframeEl =  useRef<HTMLIFrameElement>();
    let challengeV1FormEl = useRef<HTMLFormElement> ();
    let challengeV1MDEl = useRef<HTMLInputElement>();
    let challengeV1PaReqEl = useRef<HTMLInputElement> ();
    let challengeV1TermUrlEl = useRef<HTMLInputElement>();

    let challengeV2IframeEl = useRef<HTMLIFrameElement>();
    let challengeV2FormEl= useRef<HTMLFormElement>();
    let challengeV2CreqEl= useRef<HTMLInputElement>();

    let redsysForm =  useRef<HTMLFormElement>();
    let dsSignatureVersionEl = useRef<HTMLInputElement>();
    let dsMerchantParametersEl = useRef<HTMLInputElement> ();
    let dsSignatureEl = useRef<HTMLInputElement>();

    let timerID: string | number | NodeJS.Timeout | null | undefined = null;

    function obtain3DSBrowserInfo(): ThreeDSBrowserClientInfo {
        return {
            browserLanguage: navigator.language,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight:  window.screen.height.toString(),
            browserScreenWidth:  window.screen.width.toString(),
            browserTZ: (new Date()).getTimezoneOffset().toString(),
            browserJavaEnabled: navigator.javaEnabled()
        }
    }

    function onCreateAccountDialog() {
        openCreateAccount(setIsCreateAccountVisible,setIsLoginVisible);
    }
    function onOpenLoginDialog(){
        openLogin(setIsCreateAccountVisible,setIsLoginVisible);
    }

    let merchantValidate = false;

    /*
   * Listen to reception of operation ID
   */
    function onReceiveRedsysMessage(event: any) {
        if (event?.data?.idOper) {
            setIdOper(event.data.idOper);
        } else if (event?.data?.error) {
            message.error("Se ha producido un error con sus datos de pago: " + event.data.error + ", revise sus datos o contacte con el servicio técnico")
        }

        if (event?.data == 'merchantValidation') {
            if (!merchantValidate) {
                merchantValidate = true;
                //@ts-ignore
                storeIdOper(event, 'token', 'errorCode');
            }
        }
    }

    useEffect(() => {
        setBuyFormValueIsValid(false);

        window.removeEventListener('message', onReceiveRedsysMessage);

        //Añadiendo listener para message
        window.addEventListener('message', onReceiveRedsysMessage);

        return () => {
            window.removeEventListener('message', onReceiveRedsysMessage);
            if(idOper){
                unsubscribe("refresh-redsys-pay-" + idOper);
            }
            if(timerID != null){
                clearTimeout(timerID);
            }
        }
    }, []);

    useEffect(() => {
        if(websocketSelector){
            let messageInt = websocketSelector.messages?.filter((message: { content: { topic: string; }; }) =>
                message.content
                &&
                message.content.topic
                &&
                message.content.topic === ("refresh-redsys-pay-" + idOper)
            )[0]

            if(messageInt && messageInt.content && messageInt.content.data){
                successBuy(dispatch)
            }
            else if(messageInt && messageInt.content && !messageInt.content.data){
                errorBuy(dispatch);
            }
        }

    }, [websocketSelector]);

    function openWebSocketRedsysResponse (){
        openWebSocket(dispatch, "redsys-idoper-"+idOper, () => {
            setTimeout(() =>{
                subscribeTo("refresh-redsys-pay-" + idOper)
            }, 1000)
        });
    }

    useEffect(() => {

        if(paymentMethods && paymentMethods.length === 1){
            setPaymentTypeSelected(paymentMethods[0]);
            setPaymentInsite(paymentMethods[0].insite);

        }

    }, [paymentMethods])

    useEffect(() => {
        let uuid = localStorage.getItem('frontId');

        if(state && state.tickets && uuid != null && uuid === state.uuid){

            let aux = _.groupBy(state.tickets, ticket => ticket.ticket.id);
            const groupTicket : any[] = [];
            Object.keys(aux).forEach((value, index, array) => {
                groupTicket.push({
                    num: aux[value].length,
                    // @ts-ignore
                    name: aux[value][0].ticket.ticketname,
                    //@ts-ignore
                    price: aux[value][0].ticket.price
                });
            })

            let newTicketList = [...state.tickets];

            //@ts-ignore
            state.numberedTickets.forEach((t: Seat) => {
                groupTicket.push({
                    num: 1,
                    name: t.description,
                    price: t.price || t._price
                });

                newTicketList.push({
                    ticket: {
                        nominative: t.nominative || t._nominative,
                        id: t.ticketeventid || t._ticketeventid,
                        price: t.price || t._price,
                        ticketsoldid: t.ticketsoldid || t._ticketsoldid,
                        ticketname: t.description,
                        //@ts-ignore
                        ticketeventextras: t.ticketeventextras || [],
                    },
                    gift: false,
                    seatid: t.id || t._id,
                    ticketeventextrasadded:[],

                });
            })

            const calculate = calculateTotalToPayWithTaxeAndReturn(state.tickets, state.numberedTickets);

            setGlobalState({
                eventId: state.eventId,
                pixelId: state.pixelId,
                rrpp:state.rrpp,
                groupedTickets: groupTicket,
                total: calculate.total,
                taxes: calculate.taxes,
                areNominative: areNominativeInTicketListReturn(state.tickets, state.numberedTickets),
                areExtrasAndNotNominative: areExtrasAndNotNominativeInTicketListReturn(state.tickets, state.numberedTickets),
                fromResale: state.fromResale
            });
            setOriginalTotalPrice(calculate.total);
            setPaymentMethods(state.paymentDetails)
            setTicketList(newTicketList);
            setUuid(uuid)
        }
        else{
            message.error({
                key: "msgKey",
                onClick:(() => message.destroy('msgKey')),
                content: <Row justify={"center"}>Carrito de la compra no encontrado, vuelva a iniciar el proceso de compra (haga click en el mensaje para cerrarlo)</Row>,
                duration: 0,
                style: {
                    marginTop: '20vh',
                },
            })
            navigate("/");
        }
    }, [state]);

    // useEffect(() => {
    //     const handlePageChange = () => {
    //         if (!doingPayment) {
    //             const uuid = localStorage.getItem('frontId')
    //
    //             message.warning("Petición al back para que quite las entradas")
    //
    //             let url = rootServiceURL() + "completegraph/event/deleteReservedTickets/" + uuid;
    //             let rc = new RestClient(dispatch).url(url);
    //
    //             rc.doGet(() => {
    //                 if (rc.isSuccess()) {
    //                     console.log ("tickets reservados eliminados correctamente")
    //                 }
    //             })
    //         }
    //     };
    //
    //     return () => {
    //         handlePageChange();
    //     };
    //     // No se necesita cleanup en este caso
    // }, [location]);

    useEffect(() => {
        if(idOper && idOper !== '-1' && paymentTypeSelected){
            openWebSocketRedsysResponse();
            //Iniciar proceso de compra
            onBuy(paymentTypeSelected)
        }
        else if(idOper && idOper === '-1'){
            // setOrderId(getRandomOrderId);
            message.error("Se ha producido un error al obtener los datos de tu tarjeta, recargue la página e inténtelo de nuevo",0)
        }
    }, [idOper]);



    useEffect(()=>{
        const datePickerInput = document.querySelector('#assistantForm_birthdate1');
        if(datePickerInput){
            // @ts-ignore
            datePickerInput.style.color= 'white';
        }

        if(ticketList){
            const fields : any[] = [];

            ticketList.forEach((ticket, index) => {
                const datePickerInput = document.querySelector('#assistantForm_birthdate1'+index);
                if(datePickerInput){
                    // @ts-ignore
                    datePickerInput.style.color= 'white';
                }

                if(ticket.ticket.nominative)
                {
                    fields.push({
                        name: ["email1" + index],
                        value: ticketList[index] ? ticketList[index].email : ''
                    });

                    fields.push({
                        name: ["firstName1" + index],
                        value: ticketList[index] ? ticketList[index].firstName : ''
                    });


                    fields.push({
                        name: ["birthdate1" + index],
                        value: ticketList[index] ? ticketList[index].birthdate : ''
                    });

                    fields.push({
                        name: ["phoneNumber1" + index],
                        value: ticketList[index] ? ticketList[index].phoneNumber : ''
                    });

                    fields.push({
                        name: ["postalCode1" + index],
                        value: ticketList[index] ? ticketList[index].postalCode : ''
                    });
                }

            })
            setFieldsData(fields);
            addTicketEventExtraToSummary(originalTotalPrice,discount, ticketList,globalState,setGlobalState);
        }

    }, [ticketList])

    useEffect(() => {
        if(authSelector && authSelector.logged){
            getProfile(authSelector, setProfile, dispatch);
        }
    }, [authSelector]);

    useEffect(() => {
        if(activeSection && activeSection ==="challenge-v1" ){
            if(sectionRefV1 && sectionRefV1.current){
                //@ts-ignore
                sectionRefV1.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
            else{
                window.scrollTo(0, 0);
            }
        }

        if(activeSection && activeSection ==="challenge-v2" ){
            if(sectionRefV2 && sectionRefV2.current){
                //@ts-ignore
                sectionRefV2.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }
            else{
                window.scrollTo(0, 0);
            }
        }

    }, [activeSection]);

    function onChangeTicketInfo (field: string, value: any, index: number) {
        changeTicketInfo(field,value,index, ticketList, setTicketList);
    }

    function onCopy(index: number) {
        if (profile){
            copyTitularData(index,profile, ticketList, setTicketList, setIsCopied);
        }
    }
    function onCopyInvitedUser(index: number) {
        copyInvitedUser(index,ticketList,setTicketList,form,setIsCopied)
    }

    function successBuy(dispatch:Function){
        destroyBuyProccessMsg()

        // message.success({
        //     key: "msgKey",
        //     onClick:(() => message.destroy('msgKey')),
        //     content: <Row justify={"center"}>Entradas compradas correctamente, revise su email, si el correo no aparece revise su bandeja de spam/correo no deseado. (haga click en el mensaje para cerrarlo)</Row>,
        //     style: {
        //         marginTop: '20vh',
        //     },
        // })
        deleteUuid();

        if(globalState?.pixelId){
            try{
                ReactPixel.init(globalState?.pixelId, undefined, {
                    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                    debug: true, // enable logs
                });

                //@ts-ignore
                ReactPixel.track('Purchase', {currency: "EUR", value: globalState?.total})
            }catch (e){
                console.error("Error in react pixel purchase success ",e)
            }
        }

        navigate("/paySuccess/" + orderId + "/" + uuid);
        cleanMessagesWebsocket(dispatch);
    }

    function errorBuy(dispatch:Function, errorMessage?: string){
        destroyBuyProccessMsg()
        message.error({
            key: "msgKey",
            onClick:(() => message.destroy('msgKey')),
            content: <Row justify={"center"}>{errorMessage ? errorMessage : "Error comprando entradas, vuelva a intentarlo"} {" (haga click en el mensaje para cerrarlo)"}</Row>,
            style: {
                marginTop: '20vh',
            },
        })
        deleteUuid();

        if(globalState?.pixelId){
            try{
                ReactPixel.init(globalState?.pixelId, undefined, {
                    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
                    debug: true, // enable logs
                });

                //@ts-ignore
                ReactPixel.trackCustom('PurchaseFail', {currency: "EUR", value: globalState?.total})
            }catch (e) {
                console.error("Error in reactpixcel purchase fail ",e)
            }
        }

        navigate("/payError/" + orderId + "/" + uuid);
        cleanMessagesWebsocket(dispatch);
    }

    async function processInsitePayment(preauthResponse: any) {
        let preauthResult = {};
        if (
            'threeDSMethodURL' in preauthResponse &&
            preauthResponse.threeDSMethodURL != null
        ) {
            // 3DS method required
            preauthResult = {
                // Inform the frontend about the 3DS version used
                threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
                threeDSServerTransID: preauthResponse.threeDSServerTransID,
                // Data for 3DS method
                // The frontend should create an iframe and use a POST request to submit this data
                threeDSMethodForm: create3DSMethodForm(preauthResponse.threeDSServerTransID, preauthResponse.threeDSMethodURL,
                    `${rootServiceURL() + "completegraph/payment/threeDSNotification"}/${orderId}`)
            };
        } else {
            preauthResult = {
                // Inform the frontend about the 3DS version used
                threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
                threeDSServerTransID: preauthResponse.threeDSServerTransID,
            };
        }

        if ('threeDSMethodForm' in preauthResult && preauthResult.threeDSMethodForm) {
            if (
                !threeDSMethodFormEl ||
                !threeDSMethodIframeEl ||
                !threeDSMethodDataEl
            ) {
                console.error("Faltan iframees para 3DS");
                return;
            }

            // Serialize the parameters of the body into JSON and encode them with base64url
            // Then place each encoded parameter in their respective input element
            // @ts-ignore
            threeDSMethodDataEl!.current.value = preauthResult.threeDSMethodForm.body.threeDSMethodData;

            // Fill out the form information and submit.
            // @ts-ignore
            threeDSMethodFormEl!.current.action = preauthResult.threeDSMethodForm.url;
            // @ts-ignore
            threeDSMethodFormEl!.current.submit();

            // @ts-ignore
            await waitForIframeToPostMessage({
                // @ts-ignore
                iframe: threeDSMethodIframeEl.current,
                condition: (event: MessageEvent) => {
                    return typeof event.data !== 'object' &&
                        event.data != null &&
                        'finished' in event.data &&
                        event.data.finished;
                },
                // Wait until 3DS method completes or a 10 seconds timeout
                timeout: 5 * 1000
            });

        }

        const clientInfo = obtain3DSBrowserInfo();

        const authBody: AuthBody = {
            transactionData: (
                //@ts-ignore
                preauthResult.threeDSProtocolVersion === '2.2.0' || preauthResult.threeDSProtocolVersion === '2.1.0'
                    ? {
                        type: '3ds-v2',
                        //@ts-ignore
                        threeDSServerTransID: preauthResult.threeDSServerTransID,
                        //@ts-ignore
                        threeDSProtocolVersion: preauthResult.threeDSProtocolVersion
                    }
                    : {
                        type: 'regular',
                        orderId,
                        paymentMethod: {
                            type: 'id-oper',
                            idOper
                        },
                        //@ts-ignore
                        threeDSProtocolVersion: preauthResult.threeDSProtocolVersion,
                        threeDSServerTransID: "",
                    }
            ),
            clientInfo
        };

        message.loading({
            key: authPaymentMsg,
            onClick: (() => message.destroy(authPaymentMsg)),
            content: 'Solicitando autorización bancaria...',
            duration: 10,
        })

        // @ts-ignore
        auth(SaleType.TICKET, idOper, orderId, globalState.total, authBody, async (authResponse: any) => {
            if (authResponse) {
                if (authResponse.status === "DONE") {
                    successBuy(dispatch);
                } else if (authResponse.status === "CHALLENGE") {
                    let iframe: HTMLIFrameElement;

                    if (authResponse.fromVersion === "1") {
                        if (
                            !challengeV1IframeEl ||
                            !challengeV1FormEl ||
                            !challengeV1MDEl ||
                            !challengeV1PaReqEl ||
                            !challengeV1TermUrlEl
                        ) {
                            throw new Error('Form for 3DS v1 challenge does not exist');
                        }

                        //@ts-ignore
                        iframe = challengeV1IframeEl.current;

                        //@ts-ignore
                        challengeV1MDEl.current.value = authResponse.MD;

                        //@ts-ignore
                        challengeV1PaReqEl.current.value = authResponse.paReq;

                        //@ts-ignore
                        challengeV1TermUrlEl.current.value = authResponse.TermUrl;

                        //@ts-ignore
                        challengeV1FormEl.current.action = authResponse.acsURL;

                        //@ts-ignore
                        challengeV1FormEl.current.submit();

                        setActiveSection('challenge-v1');
                        setCheckPayment(true)
                    } else {
                        if (
                            //@ts-ignore
                            !challengeV2IframeEl ||
                            !challengeV2FormEl ||
                            !challengeV2CreqEl
                        ) {
                            throw new Error('Form for 3DS v2 challenge does not exist');
                        }

                        //@ts-ignore
                        iframe = challengeV2IframeEl!.current;

                        //@ts-ignore
                        challengeV2CreqEl!.current.value = authResponse.creq;

                        //@ts-ignore
                        challengeV2FormEl!.current.action = authResponse.acsURL;

                        //@ts-ignore
                        challengeV2FormEl!.current.submit();

                        setActiveSection("challenge-v2")
                        setCheckPayment(true)
                    }


                    destroyBuyProccessMsg();
                    message.loading({
                        key: challengePaymentMsg,
                        onClick: (() => message.destroy(challengePaymentMsg)),
                        content: 'Verificando estado de pago...(haga click para cerrar este mensaje)',
                        duration: 0,
                    })

                    // await waitForIframeToPostMessage({
                    //     // @ts-ignore
                    //     iframe,
                    //     condition: (event: MessageEvent) => {
                    //         return typeof event.data !== 'object' &&
                    //             event.data != null &&
                    //             'finished' in event.data &&
                    //             (event as FinishedMessageEvent).data.finished;
                    //     }
                    // });

                    const checkPay = () => {
                        console.log("Check payment status ")
                        let url = rootServiceURL() + "fullgraph/payment/getPaymentStatus/" + idOper + "/" + orderId;

                        let rc = new RestClient(dispatch).url(url);
                        rc.doGet((results: any) => {
                            if (results) {
                                if (results.finish != null && results.finish && results.success != null && results.success === true) {
                                    // @ts-ignore
                                    successBuy(dispatch);
                                } else if (results.finish != null && results.finish && results.success != null && results.success === false) {
                                    console.log("Payment error " + results.error);
                                    // errorBuy("Error durante el pago "+results.error);
                                    errorBuy(dispatch,"Error durante el pago, inicie el proceso de compra de nuevo o contacte con el servicio técnico");

                                } else {
                                    timerID = setTimeout(() => {
                                        checkPay();
                                    }, 5000);
                                }
                            } else {
                                console.error("Error check payment status", rc.getErrors())
                            }
                        })
                    }

                    timerID = setTimeout(() => {
                        checkPay();
                    }, 15000);


                    // After the payment finishes, we let them see the postchallenge page for
                    // a few seconds before redirecting to our frontend page
                    await new Promise(resolve => setTimeout(resolve, 5 * 1000));
                } else {

                }
            } else {
                errorBuy(dispatch,"Error durante la autorización de su tarjeta, vuelva a iniciar el proceso y revise los datos.")
            }

        }, dispatch);
    }

    function onBuy(paymentTypeSelected : IPaymentTypeDetail) {
        if(form){
            form.validateFields().then(value => {
                let logguedUser = null;
                let invitedUser = null;

                if(!value.terms){
                    message.error("Debe aceptar los términos y condiciones");
                    return;
                }

                if(profile){
                    logguedUser = {
                        email: profile.email
                    }
                }
                else{
                    invitedUser = {
                        email: value.email,
                        name: value.firstName,
                        birthdate: value.birthdate.getTime(),
                        zipCode: value.postalCode,
                        phone: value.phoneNumber
                    }
                }

                //@ts-ignore
                buyTickets(ticketList, logguedUser, invitedUser, idOper!, orderId, globalState.total, globalState.discountIds,
                    paymentTypeSelected.paymenttypedetailid, globalState?.rrpp, globalState?.fromResale, async (preauthResponse: any) => {

                    if(preauthResponse.done){
                        successBuy(dispatch);
                        return;
                    }
                    else{
                        await processInsitePayment(preauthResponse);
                    }

                }, dispatch)
            })
                .catch(reason => {
                    message.error("Revise los errores en el formulario");
                    console.log("Error de validación ",reason)
                })
        }
        else{
            message.error("Ha ocurrido un error, recarge la página e inténtelo de nuevo", 0)
        }
    }

    function onBlur() {
        if (form.getFieldValue("terms")){
            validateFormAndNotifyRedsys();
        }
    }

    function validateFormAndNotifyRedsys(){
        form.validateFields().then(value => {
            if(value.terms) {
                setBuyFormValueIsValid(true);
                setFormValid(true);
                setFormValidated(true)
            }
            else{
                setBuyFormValueIsValid(false);
                setFormValidated(false)
                setFormValid(false);
            }
        }).catch(reason => {
            setBuyFormValueIsValid(false);
            setFormValidated(false);
            setFormValid(false);
        })
    }


    function redsysPost(paymentMethod: IPaymentTypeDetail){
        if(!redsysForm || !dsSignatureVersionEl || !dsMerchantParametersEl || !dsSignatureEl){
            message.error("Ha ocurrido un error conectando con la pasarela de pago, contacte con el servicio técnico")
            return;
        }

        if(form){
            form.validateFields().then(value => {
                let logguedUser = null;
                let invitedUser = null;

                if (!value.terms) {
                    message.error("Debe aceptar los términos y condiciones");
                    return;
                }
                if (profile) {
                    logguedUser = {
                        email: profile.email
                    }
                }
                else {
                    invitedUser = {
                        email: value.email,
                        name: value.firstName,
                        birthdate: value.birthdate.getTime(),
                        zipCode: value.postalCode,
                        phone: value.phoneNumber
                    }
                }

                const randomOrderId = generateAndGetOrderId()

                buyRedirect(ticketList, logguedUser, invitedUser, paymentMethod.paymenttypedetailid, randomOrderId, globalState!.total,
                    globalState!.discountIds, globalState!.rrpp, globalState!.fromResale, async (response: any) => {
                    if(!response?.dsSignatureVersion || !response.dsMerchantParameters || !response.dsSignature){
                        message.error("Error obteniendo datos para la pasarela de pago, inténtelo de nuevo o contacte con el servicio técnico.")
                        return;
                    }

                    if(response.done){
                        successBuy(dispatch);
                        return;
                    }
                    else{
                        dsSignatureVersionEl.current!.value = response.dsSignatureVersion;
                        dsMerchantParametersEl.current!.value = response.dsMerchantParameters;
                        dsSignatureEl.current!.value = response.dsSignature;

                        redsysForm.current!.submit();
                    }

                }, dispatch);
            })
        }
    }
    const verifyEmailDuplicate = (email:string, id:number) => {

        return  isEmailDuplicate(ticketList,email,id);
    };

    function onApplyDiscount() {
        discountForm.validateFields().then(value=>{
            if (value.discountCode!==''){
                if(!discount){
                    if (value.discountCode){
                        getDiscount(setOriginalTotalPrice,value.discountCode,ticketList,setDiscount,globalState,setGlobalState,((msg:string)=>{
                            if (msg===''){
                                message.success("Descuento aplicado correctamente")
                            }else{
                                message.error(msg)
                            }
                        }), dispatch)
                    }
                }else{
                    message.error({
                        key: "msgKey",
                        content: (
                            <>
                                <Row justify={"center"}>Solo puedes aplicar un código de descuento.</Row>
                                <Row justify={"center"}>Por favor, elimina el descuento anterior para añadir uno nuevo.</Row>
                            </>),
                        duration: 7,
                    })
                }
            }

        }).catch(err=>{
        })
    }

    function onDeleteDiscount() {
        if (globalState){
            deleteDiscount(globalState?.total,discount, setDiscount,setGlobalState);
        }
    }

    // @ts-ignore
    return (
        <div style={{width: '100%', backgroundColor: "black"}} >
            <Row style={{paddingTop: 30}} justify={'center'}>
                <Col span={24}>
                    <Header showFilters={false} showButton={false}></Header>
                </Col>
            </Row>
            {checkPayment && <Row justify={'center'} style={{paddingTop:30}}>
                <h1 style={{color: "white"}}>COMPROBANDO PAGO.....</h1>
            </Row>}
            <div hidden={true}>
                <iframe
                    // @ts-ignore
                    ref={threeDSMethodIframeEl}
                    name="threeDSMethodIframe" />
                <form
                    // @ts-ignore
                    ref={threeDSMethodFormEl}
                    method='post'
                    target="threeDSMethodIframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={threeDSMethodDataEl}
                        name="threeDSMethodData"
                        type="hidden" />
                </form>

                <form
                    //@ts-ignore
                    ref={redsysForm}
                    name="from"

                     action="https://sis.redsys.es/sis/realizarPago" method="POST">
{/*
                     action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST" >
*/}


                    <input
                        //@ts-ignore
                        ref={dsSignatureVersionEl} type="hidden" name="Ds_SignatureVersion"/>

                    <input
                        //@ts-ignore
                        ref={dsMerchantParametersEl} type="hidden" name="Ds_MerchantParameters"/>

                    <input
                        //@ts-ignore
                        ref={dsSignatureEl} type="hidden" name="Ds_Signature"/>
                </form>

            </div>

            <div ref={sectionRefV1} hidden={activeSection !== 'challenge-v1'}>
                {/* Challenge form */}
                <iframe
                    // @ts-ignore
                    ref={challengeV1IframeEl}
                    name="challengeV1Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV1FormEl}
                    method='post'
                    target="challengeV1Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV1MDEl}
                        type="hidden"
                        name="MD" />
                    <input
                        // @ts-ignore
                        ref={challengeV1PaReqEl}
                        type="hidden"
                        name="PaReq" />
                    <input
                        // @ts-ignore
                        ref={challengeV1TermUrlEl}
                        type="hidden"
                        name="TermUrl" />
                </form>
            </div>

            <div ref={sectionRefV2} hidden={activeSection !== 'challenge-v2'}>
                <iframe
                    // @ts-ignore
                    ref={challengeV2IframeEl}
                    name="challengeV2Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV2FormEl}
                    method='post'
                    target="challengeV2Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV2CreqEl}
                        type="hidden"
                        name="creq" />
                </form>
            </div>

            {   //@ts-ignore
                globalState &&
                <div hidden={activeSection === 'challenge-v2'} className={`${isLoginVisible?styles.loginBackground:styles.registerBackground}`}>
                    {!profile ?
                        <Row justify={'center'} style={{paddingTop:30}}>
                            <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                                <Button type={'default'} className={isCreateAccountVisible?'defaultButton':'buttonNotVisible'} onClick={onCreateAccountDialog}>USUARIO INVITADO</Button>
                            </Col>
                            <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3} offset={1}>
                                <Button type={'default'} className={isLoginVisible?'defaultButton':'buttonNotVisible'} onClick={onOpenLoginDialog}>TENGO CUENTA</Button>
                            </Col>
                        </Row>
                        :
                        <Form
                            form={form}
                            name="loggedForm"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                            style={{ width:'100%' }}
                            initialValues={{ remember: true }}
                            autoComplete="off"
                            onBlur={onBlur}
                            fields={fieldsData}
                        >
                            <div style={{paddingTop:30}}>
                                <Row justify={'center'} align={'middle'}>
                                    <Space direction={'horizontal'}>
                                        <img src={shoppingCarImage} alt={'Shopping car image'} className={styles.shoppingImage}/>
                                        <h1 className={styles.title}>DATOS DEL COMPRADOR</h1>
                                    </Space>
                                </Row>
                                <Row justify={'center'} style={{paddingBottom: 20}}>
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                                </Row>
                                <Row justify={'center'}>
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>

                                        <Form.Item

                                            label={<span className={styles.label}>CORREO ELECTRÓNICO DEL COMPRADOR</span>}
                                            //@ts-ignore
                                            name={"emailBuyer" }
                                            style={{ width: '100%' }}
                                            initialValue={profile.email}
                                        >
                                            <p className={styles.buyerInputData} style={{width:'100%'}}>{profile.email}</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify={'center'}>
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                        <Form.Item
                                            label={<span className={styles.label}>NOMBRE Y APELLIDOS DEL COMPRADOR</span>}
                                            name={"nameBuyer"}
                                            style={{ width: '100%', }}
                                            initialValue={profile.name}
                                        >
                                            <p className={styles.buyerInputData} style={{width:'100%'}}>{profile.name}</p>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    }

                    {isLoginVisible?(
                        <LoginForm loginSuccessCallback={() => {
                            message.success("Sesión iniciada correctamente");
                            onCreateAccountDialog();
                        }}/>
                    ):null}
                    {isCreateAccountVisible?(
                        <>
                            {!profile && <>
                                <Row justify={'center'} >
                                    <Space direction={'horizontal'}>
                                        <img src={shoppingCarImage} alt={'Shopping car image'} className={styles.shoppingImage}/>
                                        <h1 className={styles.title}>DATOS DEL COMPRADOR</h1>
                                    </Space>
                                </Row>


                                <Row justify={'center'} style={{paddingBottom: 20,paddingTop:20}}>
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                                </Row>
                                <RegisterForm isBuy={true} onBlur={onBlur} form={form}></RegisterForm>

                            </>}

                            {globalState?.areExtrasAndNotNominative &&
                                <>
                                    <Row justify={'center'} style={{paddingBottom: 20,paddingTop:20}}>
                                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                                    </Row>
                                    <Row justify="center" style={{paddingBottom:0}}>
                                        <h1 className={styles.assistantTitle} style={{margin:0}}>COMPLEMENTOS</h1>
                                    </Row>

                                        <>
                                            {ticketList.filter(t => !t.ticket.nominative).map((ticketInfo, ticketInfoIndex) => (
                                                <>
                                                    {ticketInfo.ticket.ticketeventextras && ticketInfo.ticket.ticketeventextras.some(ticketEventExtra => ticketEventExtra.active)
                                                    &&
                                                        <>
                                                            <Row justify={'center'} style={{marginTop:30,marginBottom:0}}>
                                                                <h1 className={styles.assistantTitle}>ENTRADA {ticketInfoIndex+1}</h1>
                                                            </Row>
                                                            <Row justify={'center'} key={'ticketName' + ticketInfoIndex} className={styles.summaryRow} style={{marginTop:0}}>
                                                                <h1 className={styles.assistantTicketTitle}>{ticketInfo.ticket.ticketname}</h1>
                                                            </Row>
                                                            <Row justify={'center'} key={'ticketPrice' + ticketInfoIndex } className={styles.summaryRow}>
                                                                <h1 className={styles.assistantTicketTitle}>{ticketInfo.ticket.price}€</h1>
                                                            </Row>
                                                            <Row justify={'center'} key={'ticketDescription' + ticketInfoIndex} className={styles.summaryRow} style={{marginBottom:30}}>
                                                                <h1 className={styles.assistantTicketTitle}>{ticketInfo.ticket.description}</h1>
                                                            </Row>
                                                        </>
                                                    }

                                                    {ticketInfo.ticket.ticketeventextras.filter(ticketEventExtra => ticketEventExtra.active).map((ticketEventExtra, ticketEventExtraIndex) => (
                                                        <>
                                                            <TicketEventExtraBox
                                                                key={'ticketEventExtraBox' + ticketInfoIndex + '-' + ticketEventExtraIndex}
                                                                index={ticketInfoIndex}
                                                                ticketeventextra={ticketEventExtra}
                                                                ticketList={ticketList}
                                                                setTicketList={setTicketList}
                                                            />
                                                        </>
                                                    ))}
                                                </>
                                            ))}
                                        </>

                                    <Row justify="center" style={{paddingBottom:20}}>
                                    </Row>

                                </>
                            }

                            {globalState?.areNominative &&
                                <>

                                    <Row justify={'center'} style={{paddingTop: 20}}>
                                        <Space direction={'horizontal'}>
                                            <img src={userImage} alt={'Assistant image'} className={styles.shoppingImage}/>
                                            <h1 className={styles.title}>DATOS DE LOS ASISTENTES</h1>
                                        </Space>

                                    </Row>
                                    <Row justify={'center'}  style={{paddingBottom: 20,paddingTop:20}}>
                                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                                    </Row>


                                    <Row justify={'center'} style={{marginBottom:20,marginTop:20}}>
                                        <Space direction={'horizontal'}>
                                            <img src={warningImage} alt={'Warning image'} className={styles.warningIcon}/>
                                            <h1 className={styles.warningTitle}>Las entradas son nominales y deben ir a nombre de los asistentes</h1>

                                        </Space>
                                    </Row>
                                    <Form
                                        form={form}
                                        name="assistantForm"
                                        labelCol={{ span: 24 }}
                                        wrapperCol={{ span: 24 }}
                                        style={{ width:'100%' }}
                                        initialValues={{ remember: true }}
                                        autoComplete="off"
                                        onBlur={onBlur}
                                        fields={fieldsData}
                                    >
                                        {//@ts-ignore
                                            ticketList.map((ticket, index) =>
                                                {
                                                    // @ts-ignore
                                                    return (ticket.ticket.nominative ?
                                                        <>

                                                            <Row justify={'center'}  style={{paddingBottom: 20,paddingTop:20}}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                                                            </Row>
                                                            <Row justify={'center'}>
                                                                <h1 className={styles.assistantTitle}>ASISTENTE {index+1}</h1>
                                                            </Row>

                                                            <Row justify={'center'} key={'summary_ticket'+index} className={styles.summaryRow}>
                                                                <h1 className={styles.assistantTicketTitle}>{ticket.ticket.ticketname}</h1>
                                                            </Row>
                                                            <Row justify={'center'} key={'summary_price'+index} className={styles.summaryRow}>
                                                                <h1 className={styles.assistantTicketTitle}>{ticket.ticket.price}€</h1>
                                                            </Row>
                                                            <Row justify={'center'} key={'summary_description'+index} className={styles.summaryRow} >
                                                                <h1 className={styles.assistantTicketTitle}>{ticket.ticket.description}</h1>
                                                            </Row>

                                                            {!isCopied ? profile?(
                                                                    <Row justify={'center'} style={{marginBottom:30,marginTop:30}}>
                                                                        <Col xs={16} sm={14} md={8} lg={7} xl={5} xxl={4}>
                                                                            <Button
                                                                                className={'defaultButton'}
                                                                                onClick={()=>onCopy(index)}
                                                                                key={'touchableCopy'+index}
                                                                            >
                                                                                COPIAR DATOS DEL COMPRADOR
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                ):
                                                                (
                                                                    <Row justify={'center'}>
                                                                        <Col xs={16} sm={14} md={8} lg={7} xl={5} xxl={4} style={{marginBottom:30,marginTop:30}}>
                                                                            <Button
                                                                                className={'defaultButton'}
                                                                                onClick={()=>onCopyInvitedUser(index)}
                                                                                key={'touchableCopy'+index}
                                                                            >
                                                                                COPIAR DATOS DEL COMPRADOR
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                ): <></>
                                                            }
                                                            <Row justify={'center'}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                                    <Form.Item
                                                                        label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                                                                        //@ts-ignore
                                                                        name={"email1" + index}
                                                                        style={{ width: '100%' }}
                                                                        rules={[
                                                                            { required: true, message: 'Debe introducir un correo electrónico válido', type: 'email' },
                                                                            {
                                                                                validator: (_, value) => {
                                                                                    if (verifyEmailDuplicate(value, ticket.ticket.id)) {
                                                                                        return Promise.reject('Este correo electrónico ya está en uso para otro asistente');
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                },
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input value={ticketList[index].email || ''} className={styles.input} placeholder={''} style={{width:'100%'}}
                                                                               onChange={(e) => onChangeTicketInfo("email", e.target.value, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={'center'}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                                    <Form.Item
                                                                        label={<span className={styles.label}>NOMBRE Y APELLIDOS DEL TITULAR</span>}
                                                                        name={"firstName1"+index}
                                                                        style={{ width: '100%' }}
                                                                        rules={[{ required: true, message: 'Debe introducir los nombres y apellidos del titular', type:'string' }]}
                                                                    >
                                                                        <Input value={ticketList[index].firstName || ''}
                                                                               className={styles.input} placeholder={''} style={{width:'100%'}}
                                                                               onChange={(e) => onChangeTicketInfo("firstName", e.target.value, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            <Row justify={'center'}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                                    <Form.Item<FieldType>
                                                                        label={<span className={styles.label}>FECHA DE NACIMIENTO</span>}
                                                                        //@ts-ignore
                                                                        name={"birthdate1"+index}
                                                                        style={{ width: '100%' }}
                                                                        rules={[
                                                                            { required: true, message: 'Debe rellenar la fecha de nacimiento del titular' },
                                                                            {
                                                                                validator: (_, value) => {
                                                                                    if (validateMinimumAge(value, ticket.ticket)) {
                                                                                        return Promise.reject('La edad mínima es de '+ticket.ticket.minimumage+" años");
                                                                                    }
                                                                                    return Promise.resolve();
                                                                                },
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <DatePicker onChange={(value) => {
                                                                            //@ts-ignore
                                                                            onChangeTicketInfo("birthdate", value ? (value.getTime()) : null, index);
                                                                        }}
                                                                                    className={styles.datepicker} />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={'center'}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                                    <Form.Item
                                                                        label={<span className={styles.label}>TELÉFONO</span>}
                                                                        name={"phoneNumber1"+index}
                                                                        style={{ width: '100%', color:'white' }}
                                                                        rules={[{ required: true, message: 'Debe rellenar el número de teléfono del titular' }]}
                                                                    >
                                                                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%',color:'white'}}
                                                                               onChange={(e) => onChangeTicketInfo("phoneNumber", e.target.value, index)}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                            <Row justify={'center'}>
                                                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                                                    <Form.Item
                                                                        label={<span className={styles.label}>CÓDIGO POSTAL</span>}
                                                                        name={"postalCode1"+index}
                                                                        style={{ width: '100%' }}
                                                                        rules={[{ required: true, message: 'Debe introducir el código postal del titular' }]}
                                                                    >
                                                                        <Input type={'number'} className={styles.input} placeholder={''} style={{width:'100%'}}
                                                                               onChange={(e) => onChangeTicketInfo("postalCode", e.target.value, index)}

                                                                        />
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>

                                                            {ticket.ticket.ticketeventextras && ticket.ticket.ticketeventextras.length>0 &&ticket.ticket.ticketeventextras.some((t:ITicketEventExtra)=>t.active) ?(
                                                                <>
                                                                    <Row justify={'center'} style={{marginBottom:30}}>
                                                                        <h1 className={styles.assistantTitle} style={{marginTop:30}}>COMPLEMENTOS</h1>
                                                                    </Row>
                                                                    {ticket.ticket.ticketeventextras.map((tExtra)=>{
                                                                        if (tExtra.active){

                                                                            return (
                                                                                <TicketEventExtraBox ticketeventextra={tExtra} index={index} ticketList={ticketList} setTicketList={setTicketList}></TicketEventExtraBox>
                                                                            ) ;

                                                                        }else{

                                                                            return (<></>);

                                                                        }

                                                                    })}
                                                                    <Row justify={'center'} style={{marginBottom:30}}>
                                                                    </Row>
                                                                </>

                                                            ):(<></>)}

                                                        </> : <></> )
                                                }
                                            )}
                                    </Form>

                                </>
                            }

                            <Row justify={'center'}>
                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                            </Row>
                            <Form
                                form={discountForm}
                                name="discountForm"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width:'100%' }}
                                initialValues={{ remember: true }}
                                autoComplete="off"

                                fields={fieldsData}
                            >
                                <Row justify={'center'} style={{marginTop:30}}>
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                        <Form.Item
                                            label={<span className={styles.label}>CÓDIGO DE DESCUENTO</span>}
                                            name={"discountCode"}
                                            style={{ width: '100%' }}
                                            rules={[{ required: false, type:'string',message:'Introduzca un código de descuento' }]}
                                        >
                                            <Space.Compact style={{ width: '100%' }}>
                                                <Input className={styles.input} placeholder={''}
                                                       onChange={(e) => setDiscountCode(e.target.value)}
                                                ></Input>
                                                <ConfigProvider
                                                    theme={{
                                                        components: {
                                                            Button: {
                                                                colorBgBase:'#1CEDAE',
                                                                colorBorder:'black',
                                                                algorithm: true,
                                                                colorBgContainer:'#1CEDAE',
                                                                colorBgContainerDisabled:'#034d37',
                                                                colorTextDisabled:'black',
                                                            }
                                                        },
                                                    }}
                                                >
                                                    <Button className={styles.applyDiscountButton} type="default" onClick={onApplyDiscount}
                                                            disabled={!discountCode}
                                                    >Aplicar</Button>
                                                </ConfigProvider>
                                            </Space.Compact>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>

                            <Row justify={'center'}>
                                <Space direction={'horizontal'}>
                                    <img src={summaryImage} alt={'Summary image'} className={styles.shoppingImage}/>
                                    <h1 className={styles.title} style={{marginTop:10}}>RESUMEN DE TU PEDIDO</h1>
                                </Space>

                            </Row>

                            <Row justify={'center'}>
                                <p className={styles.summaryBoldText}>Nº de tickets: {
                                    //@ts-ignore
                                    ticketList.length
                                } ticket(s)</p>
                            </Row>

                            {//@ts-ignore
                                globalState.groupedTickets && globalState.groupedTickets.map((ticket, index) =>
                                    <Row justify={'center'} className={styles.summaryRow}>
                                        <p className={styles.summaryText}>{ticket.num} x {ticket.name} {(ticket.num * ticket.price).toFixed(2)}€</p>
                                    </Row>
                                )
                            }

                            {ticketList?ticketList.map(t =>
                            {
                                return ((t.ticketeventextrasadded || []).map(tex =>
                                    <Row justify={'center'} className={styles.summaryRow}>
                                        <p className={styles.summaryText}>1 x {tex.ticketeventextra} {(tex.quantity).toFixed(2)}€</p>
                                    </Row>
                                ))
                            }

                            ):<></>}

                            <Row justify={'center'} className={styles.summaryRow}>
                                <p className={styles.summaryText}>Gastos de gestión: {
                                    //@ts-ignore
                                    globalState.taxes?.toFixed(2)}€</p>
                            </Row>
                            {discount?(
                                <Row justify={'center'} className={styles.summaryRow} style={{marginBottom:16}}>
                                    <Space direction={'horizontal'}>
                                        <img src={deleteDiscountImage} alt={'Delete discount image'} className={styles.deleteDiscountImage} onClick={onDeleteDiscount}/>
                                        <p className={styles.summaryText} style={{marginBottom:0}}>Descuento: -{discount.toFixed(2)}€</p>
                                    </Space>

                                </Row>
                            ):(<></>)}
                            <Row justify={'center'} className={styles.summaryRow} style={{marginBottom:30}}>
                                <p className={styles.summaryText}>Total: {
                                    //@ts-ignore
                                    globalState.total?.toFixed(2)}€</p>
                            </Row>

                            <Row justify={'center'}>
                                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                            </Row>

                            <Form
                                form={form}
                                name="payForm"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                style={{ width:'100%' }}
                                initialValues={{ remember: true }}
                                onBlur={onBlur}
                                autoComplete="off"
                            >
                                <Row justify="center">
                                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                        <Form.Item label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Debe aceptar los términos y condiciones para proceder al pago'}]}>
                                            <Checkbox onChange={() => validateFormAndNotifyRedsys()}><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                            <Row justify={'center'} style={{paddingBottom:30}}>
                                <Col xs={12} sm={10} md={8} lg={7} xl={5} xxl={4}>
                                    {paymentMethods.length > 1 &&
                                    <>
                                        {paymentMethods.map(paymentMethod => {
                                            return (
                                                <Button type={'default'} className={'defaultButton'} onClick={() => {
                                                    if(!isFormValid) {
                                                        message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago");
                                                        return;
                                                    }
                                                    setPaymentTypeSelected(paymentMethod);
                                                    setPaymentInsite(paymentMethod.insite);
                                                    setDoingPayment(true)

                                                    if(!paymentMethod.insite){
                                                        redsysPost(paymentMethod);
                                                    }
                                                }}>
                                                    {paymentMethod.description}
                                                </Button>
                                            )
                                        })}
                                    </>}
                                    {paymentMethods.length === 1 && !isFormValid && paymentTypeSelected && paymentTypeSelected.insite &&
                                                <Button type={'default'} className={'defaultButton'} onClick={() => {
                                                    message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago")
                                                }}>
                                                    CONFIRMAR PAGO
                                                </Button>
                                    }

                                    {paymentMethods.length === 1 && paymentTypeSelected && !paymentTypeSelected.insite &&

                                                <Button type={'default'} className={'defaultButton'} onClick={() => {
                                                    if(!isFormValid) {
                                                        message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago");
                                                        return;
                                                    }
                                                    setDoingPayment(true);
                                                    redsysPost(paymentTypeSelected);
                                                }}>
                                                    CONTINUAR CON EL PAGO
                                                </Button>
                                    }

                                </Col>
                            </Row>
                        </>
                    ):null}
                </div>
            }

        </div>
    );
}