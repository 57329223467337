import React from "react";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";

export function  onSearch  (value:string, originalData:any[], filteredData:any[],
                                setSearchText:React.Dispatch<React.SetStateAction<string>>
                                ,setFilteredData:React.Dispatch<React.SetStateAction<any[]>>)  {
    if (originalData && filteredData){
        const filtered = originalData.filter((record) =>
            Object.values(record).some((field) =>
                field && field.toString().toLowerCase().includes(value.toLowerCase())
            )
        );
        setFilteredData(filtered);
        setSearchText(value);
    }

}

export function getTicketsByLoggedUser(setTickets:React.Dispatch<React.SetStateAction<any>>, auth: any, callback:any, dispatch:any){
    let url = rootServiceURL() + "completegraph/ticket/getTicketSoldByLoggedUser";
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doGet((results: any) => {
        if(results){
            setTickets(results)
        }
        else{
            console.error("Error cargando entradas compradas", rc.getErrors())
            setTickets([])
        }
    })
}