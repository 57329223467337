import styles from "./ImproveTicket.module.css";
import {Button, Checkbox, Col, Form, message, Row} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ITicketProfileDetails} from "../../solutions/interfaces/ITicketProfileDetails";
import {loadTicketDetails} from "../../solutions/controllers/TickeDetailsController";
import {
    calculateAmountToPay,
    getImproveTickets, improveTicketPost,

    showInfo
} from "../../solutions/controllers/ImproveTicketController";
import {calculateAmountToPayWithCommission, closeModal} from "../../solutions/Utils";
import ImproveTicketMoreInfo from "../../improveTicketMoreInfo/ImproveTicketMoreInfo";
import {ITicketAvailable, TicketImproveInfoDTO} from "../../solutions/interfaces/DTO/TicketImproveInfoDTO";

import ImproveTicketBox from "../../components/improveTicketBox/ImproveTicketBox";
import BuySummary from "../../components/buySummary/BuySummary";
import {IBuySummary} from "../../solutions/interfaces/IBuySummary";
import {TaxType} from "../../solutions/interfaces/ITax";
import arrowImage from "../../assets/arrow.svg";

export default function ImproveTicket(){

    const navigate = useNavigate();
    const { ticketId } = useParams();
    const dispatch = useDispatch();
    const [originalData, setOriginalData] = useState<ITicketProfileDetails>();
    const [improveTicket, setImproveTicket] = useState<TicketImproveInfoDTO>();
    const [selectedTicket, setSelectedTicket] = useState<ITicketAvailable | null>(null);
    const [isInfoVisible, setIsInfoVisible] = useState(false);
    const [info, setInfo] = useState('');
    const [dataForSummary,setDataForSummary] = useState<IBuySummary>();
    const [form] = Form.useForm();
    const [amountToPay,setAmountToPay] = useState<number>(0);
    const [amountToPayWithCommision,setAmountToPayWithCommision] = useState<number>(0);
    const [commission,setCommission] = useState<number>(0);
    // @ts-ignore
    const auth = useSelector(state => state.auth);

    function onShowInfo(description:string) {
        showInfo(description,setInfo,setIsInfoVisible);
    }
    function onClose(){
        closeModal(setIsInfoVisible);
    }

    function onSelectTicket(ticket:ITicketAvailable) {
        if (ticket){
            setSelectedTicket(ticket);
        }
    }

    function handleCheckboxChange(checked: boolean) {
        if (!checked) {
            form.resetFields();
        }
    }
    function onBuy() {
        if(improveTicket && selectedTicket){
            form.validateFields().then(value => {
                console.log('validado')
                //TODO enviar aqui a la pasarela de pago
                const callback = ()=>{
                    navigate('/profile');
                }
                //improveTicketPost(improveTicket.ticketSoldId,selectedTicket.ticketeventid,auth,callback);

            }).catch(reason => {
                console.log(reason)
                message.error('Debe  aceptar los términos y condiciones para proceder al pago');
            })
        }
    }
    function goToProfile(){
        window.history.back();
    }
    useEffect(() => {
        if(auth && auth.logged && ticketId){
            // @ts-ignore
            loadTicketDetails(parseInt(ticketId, 10), auth, setOriginalData, (results) => {}, dispatch);
        }else{
            navigate('/');
        }
    }, [auth]);

    useEffect(()=>{
        if (originalData){
            if (!originalData.allowImprove || originalData.status==='RESALE'){
                navigate('/');
                message.error('Esta entrada no se puede mejorar.');
            }else{
                getImproveTickets(auth,()=>{ window.scrollTo(0, 0)},setImproveTicket, originalData.ticketsoldid);
            }
        }
    },[originalData])

    useEffect(()=>{
        if (selectedTicket && improveTicket){
            calculateAmountToPay(selectedTicket.price, improveTicket.actualPrice,setAmountToPay);
            calculateAmountToPay(selectedTicket.commission, improveTicket.actualCommission,setCommission);
        }
    },[selectedTicket,improveTicket]);
    useEffect(()=>{
        if (amountToPay  ){
            calculateAmountToPayWithCommission(amountToPay,commission,TaxType.FIXED,setAmountToPayWithCommision);
        }
    },[amountToPay, commission])
    useEffect(()=>{
        if (amountToPayWithCommision  && originalData && selectedTicket){
            const summary:IBuySummary = {
                total:amountToPayWithCommision,
                commission:commission,
                title:'Mejora de '+originalData.ticketName +' a '+selectedTicket.ticketname.toUpperCase()+': '+amountToPay.toString() +'€',
                buyDetails:[],
            }
            setDataForSummary(summary);
        }
    },[amountToPayWithCommision])
    return (
        <div className={`${styles.container} footerPaddingSeparation`}>
            <Row align={'middle'}>
                <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    <img src={arrowImage} alt="Arrow image" onClick={goToProfile} style={{cursor:'pointer'}}/>
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    <h1 className={styles.comeBackTitle}  onClick={goToProfile}>
                        VOLVER
                    </h1>
                </Col>
            </Row>
            <Row >
                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                    <h1 className={styles.title}>MODIFICAR ENTRADA</h1>
                </Col>
                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                    <h1 className={styles.smallTitle}>MODIFICAR ENTRADA</h1>
                </Col>
            </Row>
            {info?(
                <ImproveTicketMoreInfo isInfoVisible={isInfoVisible} onClose={onClose} info={info}></ImproveTicketMoreInfo>

            ):<></>}
            {originalData && improveTicket?(
                <>
                    {improveTicket.ticketsAvailable.length!==0?(
                        <>
                            <Row >
                                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                                    <h1 className={styles.title}>TU ENTRADA ES:</h1>
                                </Col>
                                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                                    <h1 className={styles.smallTitle}>TU ENTRADA ES:</h1>

                                </Col>
                            </Row>
                            <ImproveTicketBox name={originalData.ticketName} price={improveTicket.actualPrice} description={originalData.ticketDescription} isSelected={false}></ImproveTicketBox>

                            <Row >
                                <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                                    <h1 className={styles.title}>PUEDES MEJORARLA A:</h1>
                                </Col>
                                <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                                    <h1 className={styles.smallTitle}>PUEDES MEJORARLA A:</h1>
                                </Col>
                            </Row>
                        </>
                    ):(
                        <Row >
                            <Col xs={0} sm={0} md={24} lg={24} xl={24} xxl={24}>
                                <h1 className={styles.title}>TU ENTRADA NO TIENE MEJORAS ACTUALMENTE.</h1>
                            </Col>
                            <Col xs={24} sm={24} md={0} lg={0} xl={0} xxl={0}>
                                <h1 className={styles.smallTitle}>TU ENTRADA NO TIENE MEJORAS ACTUALMENTE.</h1>

                            </Col>
                        </Row>
                    )}

                    {improveTicket.ticketsAvailable.map((ticket, index) => (
                        <ImproveTicketBox
                            key={index}
                            name={ticket.ticketname}
                            price={ticket.price}
                            description={ticket.description}
                            onSelectTicket={() => onSelectTicket(ticket)}
                            isSelected={ticket === selectedTicket}
                        />
                    ))};

                </>
            ):<></>}
            {selectedTicket!== null && improveTicket ? (
                <>
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Row justify={'center'}>

                        <h1 className={styles.title} style={{marginTop:0}}>RESUMEN DE TU PEDIDO</h1>
                    </Row>
                    {dataForSummary?(
                        <BuySummary data={dataForSummary}/>
                    ):(<></>)}
                    <Row justify={'center'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                    </Row>
                    <Form
                        form={form}
                        name="form"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ width:'100%' }}
                        initialValues={{ remember: true }}

                        autoComplete="off"
                    >
                        <Row justify="center">

                            <Form.Item style={{marginBottom:20}} label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Debe aceptar los términos y condiciones'}]}>
                                <Checkbox onChange={(e) => handleCheckboxChange(e.target.checked)}><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                            </Form.Item>
                        </Row>
                    </Form>
                </>
            ):<></>}

            <Row justify={'center'} style={{paddingBottom:0}}>
                <Col xs={23} sm={20} md={18} lg={14} xl={10} xxl={6} >
            {selectedTicket!== null && improveTicket ? (
                <>

                            <Button
                                className={'defaultButton'}
                                onClick={onBuy}

                            >
                                <span className={styles.buttonText}>CONTINUAR</span>
                            </Button>

                </>
            ):(
                <>
                    <Button
                        style={{color:'white',backgroundColor:'grey', borderColor:'grey',paddingBottom:20}}
                        disabled={true}
                        className={'defaultButton'}
                    >
                        <span className={styles.buttonText}>CONTINUAR</span>
                    </Button>

                </>
            )}
                </Col>
            </Row>

        </div>
    );
}