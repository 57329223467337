import Header from "../../components/header/Header";
import {Button, Col, message, Row} from "antd";
import React, {useEffect, useState} from 'react';
import styles from "./BuySuccessError.module.css";
import ticImage from "../../assets/typcn_tick.svg"
import summaryImage from "../../assets/ooui_text-summary-rtl.svg";
import {useMatches} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {loadPayDetail} from "../../solutions/controllers/BuyController";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import fileDownload from "js-file-download";
import {DownloadOutlined} from "@ant-design/icons";
export default function BuySuccess(){
    const initialMatches: any = useMatches();
    const dispatch = useDispatch();
    const auth = useSelector((state : any) => state.auth);

    const [data, setData] = useState<{
        orderId: string,
        historyPaymentId: number,
        total: number,
        commission: number,
        ticketQuantity: number,
        extrasQuantity: number,
        buyDetails: {
            quantity: number,
            amount: number,
            ticketPrice: number,
            ticketName: string,
            ticketDescription: string
        }[],
        buyExtrasDetails: {
            quantity: number,
            amount: number,
            ticketPrice: number,
            ticketName: string,
            ticketDescription: string
        }[]
    } | undefined>(undefined)

    useEffect(() => {
        if(initialMatches[0].params.orderId && initialMatches[0].params.transactionId){
            loadPayDetail(initialMatches[0].params.orderId, initialMatches[0].params.transactionId, setData, dispatch);
        }
    }, [initialMatches[0].params]);

    const downloadTickets = () => {
        let url = rootServiceURL() + "completegraph/ticket/downloadTicketSoldByTransactionId/" + data?.historyPaymentId+"/"+data?.orderId;
        let rc = new RestClient(dispatch).url(url).token(auth.token);
        rc.doGet((results: any) => {
            if(rc.isSuccess()){
                results.arrayBuffer().then((result:any)=>{
                    fileDownload(result,data?.orderId+".pdf")
                });
                message.success("Entrada descargada.");
            }
            else{
                console.error("Error descargando entradas compradas", rc.getErrors())
                message.error("Error descargando entradas compradas: "+ rc.getErrors())
            }
        })
    }

    // @ts-ignore
    return (
        <div className={'footerSeparation'}>
            <Row style={{paddingBottom:30, paddingTop: 30}} justify={'center'}>
                <Col span={24} style={{ position: 'relative' }}>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <Row justify={'center'}  >
                <Col xs={0} sm={0} md={18} lg={14} xl={10} xxl={8} className={styles.container} style={{height:'100%'}}>
                    <Row justify={'center'}>
                        <img src={ticImage} alt={'Error image'}/>
                    </Row>
                    <Row justify={'center'}>
                        <h1 className={styles.title} >¡GRACIAS POR TU COMPRA!</h1>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <p className={styles.text} >PAGO REALIZADO CORRECTAMENTE, ENCONTRARÁ SUS ENTRADAS EN SU CORREO ELECTRÓNICO O EN LA SECCIÓN "MIS ENTRADAS"</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={22} sm={22} md={0} lg={0} xl={0} xxl={0} className={styles.container} style={{height:'100%'}}>
                    <Row justify={'center'}>
                        <img src={ticImage} alt={'Error image'}/>
                    </Row>
                    <Row justify={'center'}>
                        <h1 className={styles.smallTitle}>¡GRACIAS POR TU COMPRA!</h1>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <p className={styles.smallText}>PAGO REALIZADO CORRECTAMENTE, ENCONTRARÁ SUS ENTRADAS EN SU CORREO ELECTRÓNICO O EN LA SECCIÓN "MIS ENTRADAS"</p>
                        </Col>
                    </Row>
                </Col>

            </Row>
            {data &&
            <>
                <Row justify={'center'} style={{marginTop:"3%"}}>
                    <Col className={styles.whiteTitle}>
                        <h3><DownloadOutlined /></h3>
                        <h3>DESCARGA AQUÍ TUS ENTRADAS</h3>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col >
                        <Button type={'default'} className={'defaultButton'} onClick={() => {
                            downloadTickets();
                        }}>
                            Descargar
                        </Button>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <h1 className={styles.greenTitle} style={{marginTop:30}}>PEDIDO: {data.orderId}</h1>
                </Row>
                <Row  style={{marginTop:30}} justify={'center'}>
                    <Col xs={{span:22}} sm={{span:22,offset:0}} md={{span:10,offset:0}} lg={{span:8,offset:0}} xl={{span:6,offset:0}} xxl={{span:4,offset:0}}>
                        <Row>
                            <img src={summaryImage} alt={'summary card image'}/>
                            <h1 className={styles.greenTitle} style={{marginLeft:15}}>Resumen</h1>
                        </Row>
                        <Row>
                            <p className={styles.whiteTitle}>Nº de tickets: {data.ticketQuantity} ticket(s) </p>
                        </Row>
                        {data.buyDetails && data.buyDetails.map((buyDetail) =>
                                <Row>
                                    <p style={{fontSize: 20}} className={styles.whiteTitle}>{buyDetail.quantity} x {buyDetail.ticketName} {(buyDetail.quantity * buyDetail.ticketPrice).toFixed(2)}€ </p>
                                </Row>
                            )};

                        {data.buyExtrasDetails && data.buyExtrasDetails.length > 0 &&
                            <>
                                <Row>
                                    <p className={styles.whiteTitle}>Nº de complementos: {data.extrasQuantity} complemento(s) </p>
                                </Row>
                                {data.buyExtrasDetails.map((buyDetail) =>
                                    <Row>
                                        <p style={{fontSize: 20}} className={styles.whiteTitle}>{buyDetail.quantity} x {buyDetail.ticketName} {(buyDetail.quantity * buyDetail.ticketPrice).toFixed(2)}€ </p>
                                    </Row>
                                )}
                            </>
                        }
                        <Row>
                            <p className={styles.whiteTitle}>Gastos de gestión: {data.commission.toFixed(2)}€ </p>
                        </Row>
                        <Row>
                            <p className={styles.whiteTitle}>Total: {data.total.toFixed(2)}€ </p>
                        </Row>


                    </Col>

                </Row>
                {/*<Row justify={'center'}>*/}
                {/*    <Col xs={22} sm={22} md={18} lg={14} xl={10} xxl={8}>*/}
                {/*        <Button type={'text'} className={styles.button}>Ver entradas</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
                {/*<Row justify={'center'}>*/}
                {/*    <Col xs={22} sm={22} md={18} lg={14} xl={10} xxl={8}>*/}
                {/*        <Button type={'text'} className={styles.button}>Descargar entradas</Button>*/}
                {/*    </Col>*/}
                {/*</Row>*/}
            </>
            }
        </div>
    );
}