import React from "react";
import {ITicketDetails} from "../interfaces/ITicketDetails";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {ChangeOwnershipDTO} from "../interfaces/DTO/ChangeOwnershipDTO";
import {dispatch} from "../../Controller/redux/websocket/WebSocketController";
import {message} from "antd";
import fileDownload from "js-file-download";

import {generateUuidAndExecuteCallback} from "../Utils";
import {initPaymentMsg} from "./BuyController";

export function loadTicketDetails(ticketSoldId: number, auth: any, setTicket:React.Dispatch<React.SetStateAction<ITicketDetails | undefined>>, callback: Function, dispatch: Function){
    let url = rootServiceURL() + "fullgraph/ticket/getTicketSoldInfo/"+ticketSoldId;
    let rc = new RestClient(dispatch).url(url).token(auth.token);

    // @ts-ignore
    rc.doGet((results: ITicketDetails) => {
        if(results){
            console.log("TicketDetail ",results)
            setTicket(results)
            callback && callback(results)
        }
        else{
            console.error("Error cargando detalle de entrada", rc.getErrors())
            setTicket(undefined)
        }
    })

}
// @ts-ignore
export function loadTicketQR(ticketSoldId: number, previousTicketInfo: any, auth: any, setTicket:React.Dispatch<React.SetStateAction<ITicketDetails | undefined>>, dispatch){
    let url = rootServiceURL() + "completegraph/ticket/getTicketSoldQR/"+ticketSoldId;
    console.log("auth", auth)
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    // @ts-ignore
    rc.doGet(results => {
        if(results){
            console.log("Previous ticket ",previousTicketInfo)
            console.log("Nuevo qr ",results)
            setTicket({...previousTicketInfo, qr: results})
        }
        else{
            console.error("Error cargando qr de entrada", rc.getErrors())
            setTicket({...previousTicketInfo, qr: undefined})
        }
    })
    //setTicket(ticketDetails);
}
export function changeTicketOwnership(ticketSoldId: number, changeOwnershipDTO: ChangeOwnershipDTO, auth: any, callback:any){

    if (changeOwnershipDTO){
        let url = rootServiceURL() + "completegraph/ticket/changeTicketOwnership/";
        let rc = new RestClient(dispatch).url(url).token(auth.token)

        const entityToPost = {
            userInfoDTO: {...changeOwnershipDTO},
            ticketsoldid: ticketSoldId
        }

        rc.doPost(entityToPost, (results: any) => {
            if(rc.isSuccess()){
                message.success('Se ha cambiado el nombre a la entrada.');
                if (callback && typeof callback === "function"){
                    callback();
                }
            }
            else{
                message.error("Error cambiando el nombre a la entrada."+rc.getErrors())
            }
        }, false)
    }
    else{
        message.error('Error cambiando el nombre a la entrada.')
    }
}

export function changeTicketOwnershipRedirect(ticketSoldId: number, changeOwnershipDTO: ChangeOwnershipDTO, paymentdetailid: number, orderId: string,
                            totalPrice: number, callback: any, auth: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {

            const txlRequest = {
                orderId: orderId,
                amount: totalPrice,
                description: "Cambio de nombre en Tixxlab",
                transactionId: uuid,
                paymentdetailid: paymentdetailid,
            }

            const changeOwnershipRequestDTO = {
                userInfoDTO: {...changeOwnershipDTO},
                ticketsoldid: ticketSoldId,
                txlPaymentRequest: txlRequest,
            }

            console.log("changeOwnershipRequestDTO", changeOwnershipRequestDTO)

            message.loading({
                key: initPaymentMsg,
                onClick:(() => message.destroy(initPaymentMsg)),
                content: 'Iniciando proceso de pago...',
                duration: 10,
            })

            let url = rootServiceURL() + "completegraph/ticket/changeTicketOwnershipRedirect/";
            let rc = new RestClient(dispatch).url(url).token(auth.token);

            rc.doPost(changeOwnershipRequestDTO, (results: any) => {
                if (rc.isSuccess()) {
                    callback && callback(results);
                } else {
                    alert("Error iniciando pago  " + rc.getErrors())
                    message.destroy(initPaymentMsg)
                }
            }, false)
        }
    })
}


export function changeTicketOwnershipInsite(ticketSoldId: number, changeOwnershipDTO: ChangeOwnershipDTO, paymentdetailid: number,
                                            orderId: string, idOper: string,
                                              totalPrice: number, callback: any, auth: any, dispatch: any) {
    generateUuidAndExecuteCallback(dispatch,(uuid) => {
        if (!uuid) {
            alert("Error recuperando identificador de sesión, contacte con el servicio técnico")
        } else {

            const txlRequest = {
                orderId: orderId,
                idOper: idOper,
                amount: totalPrice,
                description: "Cambio de nombre en Tixxlab",
                transactionId: uuid,
                paymentdetailid: paymentdetailid,
            }

            const changeOwnershipRequestDTO = {
                userInfoDTO: {...changeOwnershipDTO},
                ticketsoldid: ticketSoldId,
                txlPaymentRequest: txlRequest,
            }

            console.log("changeOwnershipRequestDTO changeTicketOwnershipInsite", changeOwnershipRequestDTO)

            message.loading({
                key: initPaymentMsg,
                onClick:(() => message.destroy(initPaymentMsg)),
                content: 'Iniciando proceso de pago...',
                duration: 10,
            })

            let url = rootServiceURL() + "completegraph/ticket/changeTicketOwnershipInsite/";
            let rc = new RestClient(dispatch).url(url).token(auth.token);

            rc.doPost(changeOwnershipRequestDTO, (results: any) => {
                if (rc.isSuccess()) {
                    callback && callback(results);
                } else {
                    alert("Error iniciando pago  " + rc.getErrors())
                    message.destroy(initPaymentMsg)
                }
            }, false)
        }
    })
}

export function downloadTicketPdf(auth:any,ticketName: string, eventName:string,ticketSoldId:number,callback:Function,dispatch:any){
    let url = rootServiceURL() + "completegraph/ticket/downloadTicketPDF/"+ticketSoldId;
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doGet((results: any) => {
        if(rc.isSuccess()){
            results.arrayBuffer().then((result:any)=>{
                fileDownload(result,ticketName+ "_"+eventName+".pdf")
            });
            callback && callback('');
        }
        else{
            callback && callback(results.getErrors());
        }
    })
}