import styles from "./ImproveTicketSummary.module.css";
import BuySummary from "../../components/buySummary/BuySummary";
import {Button, Checkbox, Col, Form, message, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {IBuySummary} from "../../solutions/interfaces/IBuySummary";
import {
    calculateAmountToPay,  improveTicketPost
} from "../../solutions/controllers/ImproveTicketController";
import {ITicketAvailable, TicketImproveInfoDTO} from "../../solutions/interfaces/DTO/TicketImproveInfoDTO";
import {ITicketProfileDetails} from "../../solutions/interfaces/ITicketProfileDetails";
import {useDispatch, useSelector} from "react-redux";

export default function ImproveTicketSummary(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const selectedTicketParam = queryParams.get("selectedTicket");
    const improvedTicketParam = queryParams.get("improvedTicket");
    const originalDataParam = queryParams.get("originalData");
    const [checkBoxValue, setCheckBoxValue] = useState<string>();
    const [amountToPay,setAmountToPay] = useState<number>(0);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    const [amountToPayWithCommision,setAmountToPayWithCommision] = useState<number>(0);
    const [dataForSummary,setDataForSummary] = useState<IBuySummary>();
    const dispatch = useDispatch();
    const [selectedTicket, setSelectedTicket] = useState<ITicketAvailable>(
        selectedTicketParam ? JSON.parse(decodeURIComponent(selectedTicketParam)) : null
    );
    const [improvedTicket, setImprovedTicket] = useState<TicketImproveInfoDTO>(
        improvedTicketParam ? JSON.parse(decodeURIComponent(improvedTicketParam)) : null
    );
    const [originalData, setOriginalData] = useState<ITicketProfileDetails>(
        originalDataParam ? JSON.parse(decodeURIComponent(originalDataParam)) : null
    );



    useEffect(()=>{
        if (selectedTicket && improvedTicket){
            calculateAmountToPay(selectedTicket.price, improvedTicket.actualPrice,setAmountToPay);
        }
    },[selectedTicket,improvedTicket]);
    useEffect(()=>{
        if (amountToPay){
            //calculateAmountToPayWithCommission(improvedTicket.actualCommission,amountToPay,setAmountToPayWithCommision);
        }
    },[amountToPay])

    useEffect(()=>{
        if (amountToPayWithCommision){
            const summary:IBuySummary = {
                total:amountToPayWithCommision,
                commission:improvedTicket.actualCommission,
                title:'Mejora de '+originalData.ticketName +' a '+selectedTicket.ticketname.toUpperCase()+': '+amountToPay.toString() +'€',
                buyDetails:[],

            }
            setDataForSummary(summary);
        }
    },[amountToPayWithCommision])

    useEffect(() => {
        if(!(auth && auth.logged )){
            navigate('/');
        }
    }, [auth]);

    function onBuy() {
        form.validateFields().then(value => {
            if(value.terms) {
                const callback = ()=>{
                    navigate('/profile');
                }
                improveTicketPost(improvedTicket.ticketSoldId,selectedTicket.ticketeventid,auth,callback);
            }
            else{

                message.error('Debe  aceptar los términos y condiciones para proceder al pago')
            }
        }).catch(reason => {
            message.error('Error: '+reason);
        })
    }

    return (
        <div className={styles.container}>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
            </Row>
            <Row justify={'center'}>

                <h1 className={styles.title}>RESUMEN DE TU PEDIDO</h1>
            </Row>

            {dataForSummary?(
                <BuySummary data={dataForSummary}/>
            ):(<></>)}
            <Form
                form={form}
                name="form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width:'100%' }}
                initialValues={{ remember: true }}

                autoComplete="off"
            >
                <Row justify="center">

                        <Form.Item label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Please accept!'}]}>
                            <Checkbox value={checkBoxValue}><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                        </Form.Item>
                </Row>
                <Row justify={'center'} >
                    <Col xs={12} sm={10} md={8} lg={7} xl={5} xxl={4}>
                        <Button
                            type={'default'}
                            className={'defaultButton'}

                            onClick={onBuy}
                        >
                            CONTINUAR CON EL PAGO
                        </Button>
                    </Col>
                </Row>
            </Form>


        </div>
    );
}