import React from 'react';
import { IEvent } from "../../solutions/interfaces/IEvent";
import {Col, Row, Space} from "antd";
import styles from "./EventsList.module.css";
import {imagesPath} from "../../Controller/Settings";
import {formatDateToString, formatHourToString} from "../../solutions/Utils";
import locationImage from "../../assets/grey_location.svg";

interface EventListProps {
    eventList: IEvent[];
    handleEventClick: (eventid : number) => void
}

const EventsList: React.FC<EventListProps> = ({ eventList , handleEventClick}) => {
    const now = new Date();
    const hours48InMilliseconds = 48 * 60 * 60 * 1000; // 48 horas en milisegundos

    return (
        <>
        {eventList ? eventList.map(event => {
            const fromDate = new Date(event.fromDate)
            const futureDate = new Date(fromDate.getTime() + hours48InMilliseconds);
                const isActive = futureDate > now;
                return (
                    <Row key={event.id} justify={'center'} style={{ marginTop: 10, }} >
                        <div style={{display: "flex", alignItems: "center",justifyContent:"center", position: "relative", width:"100%"}}>
                            {isActive ? "" : <div className={styles.overlay}>FINALIZADO</div>}
                        </div>
                        <Col xs={23} sm={18} md={16} lg={14} xl={12} xxl={10} className={isActive ? styles.ticketBox : styles.disabledTicketBox} style={isActive ? {cursor: 'pointer'} : {}}
                             onClick={() => isActive ? handleEventClick(event.id) : ""}>
                            <Row justify={'start'}>
                                <Col xs={{span:10,offset:0}} sm={{span:10,offset:0}} md={{span:6,offset:0}} lg={{span:6,offset:0}} xl={{span:6,offset:0}} xxl={{span:6,offset:0}} style={{display: "flex", alignItems: "center"}}>
                                    <img src={imagesPath + event.image} alt={'event image'}
                                         className={styles.ticketImage}/>
                                </Col>

                                <Col xs={{span: 11, offset: 2}} sm={{span: 11, offset: 2}} md={{span: 17, offset: 1}}
                                     lg={{span: 17, offset: 1}} xl={{span: 17, offset: 1}} xxl={{span: 16, offset: 2}}
                                     style={{marginTop: 10}}>
                                    <Row style={{marginLeft:10, marginRight:10, marginTop:0, justifyContent: "space-between"}}>
                                        <Col xs={15} sm={15} md={17} lg={17} xl={17} xxl={17}>
                                            <p className={styles.ticketDateHour}> {formatDateToString(event.fromDate)}</p>
                                        </Col>
                                        <Col xs={{span: 5, offset: 2}} sm={{span: 5, offset: 2}} md={5} lg={5} xl={5} xxl={5}>
                                            <p className={styles.ticketDateHour} style={{textAlign:"right"}}> {formatHourToString(event.fromDate)}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketDescription}> {event.description}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketDescription}> {event.description}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketTitle}> {event.title}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketDescription}> {event.description}</p>
                                        </Col>
                                    </Row>
                                    <Row justify={'end'} style={{paddingRight: 10}}>
                                        <Space direction={"horizontal"}>
                                            <img alt="example" src={locationImage} className={styles.boxImage}/>
                                            <p className={styles.townDescription}>{event.town.town}</p>
                                        </Space>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )
            }) : ""}
        </>
);
};

export default EventsList;
