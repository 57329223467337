import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, message, Row} from "antd";
import styles from "./Buy.module.css";
import {buyFormValue, generateAndGetOrderId} from "../../solutions/controllers/BuyController";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RedsysForm({callback}: {callback?: Function}){
    let orderId : string | null = null;

    useEffect(() => {
        orderId = generateAndGetOrderId();

        let inputStyle = "font-size: 14px; color: white;"
        let boxStyle ="width: 95%; border-width: 4px !important;  border-radius: 10px !important;" +
            "border-color: #333232 !important; background-color: #2A2727 !important; color: #F9F9F9";

        if(window ){
            const mediaQuery = window.matchMedia("(max-width: 480px)");
            if(mediaQuery?.matches){
                console.log("Estableciendo fuente para móvil")
                inputStyle = "font-size: 16px; color: white;"
                boxStyle ="width: 89%; border-width: 4px !important;  border-radius: 10px !important;" +
                    "border-color: #333232 !important; background-color: #2A2727 !important; color: #F9F9F9";
            }
        }

        const buttonStyle = "width: 70%; margin-top: 10px; border-radius: 10px; background-color: #1CEDAE; border-color: #1CEDAE; color: #F9F9F9"


        // console.log("Cargando iframe de redsys...")
        //@ts-ignore
        getInSiteForm('card-form', buttonStyle, '', boxStyle, inputStyle, 'Confirmar pago', '347486946', '1', orderId, 'ES', false, false, 'inline');

    }, []);

    // @ts-ignore
    return (
        // <div className={isLoginVisible?styles.loginBackground:styles.registerBackground}>
        <>
            <div style={{width: '100%', paddingBottom:100, backgroundColor: "black"}}>

                <Row justify={'center'}>
                    <h1 style={{marginTop: 0}} className={styles.title}>DATOS DE PAGO</h1>
                </Row>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <div style={{height: 250}} id="card-form" />
                    </Col>
                </Row>

                <input type="hidden" id="token" ></input>
                <input type="hidden" id="errorCode" ></input>
            </div>
        </>
    );
}