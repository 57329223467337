import {Col, Progress, Row} from "antd";
import React, {useEffect, useState} from 'react';
import styles from './Queue.module.css';


// @ts-nocheck
export default function Queue() {

    const [queueNumber, setQueueNumber] = useState(0);
    const [initialQueueNumber, setInitialQueueNumber] = useState(0);
    const [progress, setProgress] = useState(0);

    function loadQueue() {
        setQueueNumber(1412313);
        setInitialQueueNumber(3412313);
    }

    useEffect(() => {
        loadQueue();
    }, []);

    useEffect(() => {

        if (queueNumber!==0 && initialQueueNumber!==0){
            const progress:number = queueNumber*100/initialQueueNumber;
            setProgress(progress);
        }

    }, [queueNumber, initialQueueNumber]);

    return (
        <div className={'blackContainer'} style={{height: '100%'}}>

            {queueNumber !== 0 && initialQueueNumber!==0 ? (
                <>
                    <Row justify={'center'} style={{paddingTop: 50}}>
                        <h1 className={styles.title}>COLA DE ESPERA</h1>
                    </Row>
                    <Row justify={'center'} style={{marginTop:50}}>
                        <Col span={12}>
                            <Row justify={'center'}>
                                <h3 className={styles.progressLabel}>
                                    Está usted en la cola de espera del evento, en la posición {queueNumber} de {initialQueueNumber} personas.
                                </h3>
                                <Progress  percent={progress} size={['100%',40]} format={percent => {
                                    return (<p className={styles.progressText}>{percent?.toFixed(0)} %</p>)
                                }}/>

                            </Row>
                        </Col>
                    </Row>
                </>
            ) : (<></>)}

        </div>
    )
}