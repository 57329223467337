import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Col, Form, message, Row} from "antd";
import styles from "./Buy.module.css";
import {
    getRandomOrderId,
    initRedirectionRequest,
} from "../../solutions/controllers/BuyController";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

export default function BuyTestRedirection(){
    const [orderId , setOrderId] = useState(getRandomOrderId())
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation(); // state is any or unknown

    let redsysForm =  useRef<HTMLFormElement>()
    let dsSignatureVersionEl = useRef<HTMLInputElement>();
    let dsMerchantParametersEl = useRef<HTMLInputElement> ();
    let dsSignatureEl = useRef<HTMLInputElement>();

    function redsysPost(){
        if(!redsysForm || !dsSignatureVersionEl || !dsMerchantParametersEl || !dsSignatureEl){
            message.error("Ha ocurrido un error conectando con la pasarela de pago, contacte con el servicio técnico")
            return;
        }

        initRedirectionRequest(orderId, {}, 0.01, async (response: any) => {
            if(!response?.dsSignatureVersion || !response.dsMerchantParameters || !response.dsSignature){
                message.error("Error obteniendo datos para la pasarela de pago, inténtelo de nuevo o contacte con el servicio técnico.")
                return;
            }
            // dsSignatureVersionEl.current!.value = "HMAC_SHA256_V1";
            // dsMerchantParametersEl.current!.value = "eyJEU19NRVJDSEFOVF9NRVJDSEFOVENPREUiOiIzNDc0ODY5NDYiLCJEU19NRVJDSEFOVF9DVVJSRU5DWSI6Ijk3OCIsIkRTX01FUkNIQU5UX1RSQU5TQUNUSU9OVFlQRSI6IjAiLCJEU19NRVJDSEFOVF9URVJNSU5BTCI6IjIiLCJEU19NRVJDSEFOVF9PUkRFUiI6IlRTMTcwMTQyMjIyNCIsIkRTX01FUkNIQU5UX0FNT1VOVCI6MTEwMCwiRFNfTUVSQ0hBTlRfTUVSQ0hBTlRVUkwiOiJodHRwczpcL1wvdGlja2V0cy5vbmVjbGlpY2suY29tXC9hcGlcL3N1Y2Nlc3NfcGF5X3JlZHN5c19ldmVudF93ZWI/aWRfdHJhbnNhY3Rpb249NSIsIkRTX01FUkNIQU5UX1VSTE9LIjoiaHR0cHM6XC9cL3RpY2tldHMub25lY2xpaWNrLmNvbVwvZXZlbnRcLzI/dHlwZT1zdWNjZXNzJnRyYW5zYWN0aW9uX2lkPTUiLCJEU19NRVJDSEFOVF9VUkxLTyI6Imh0dHBzOlwvXC90aWNrZXRzLm9uZWNsaWljay5jb21cL2V2ZW50XC8yP3R5cGU9ZXJyb3IifQ==";
            // dsSignatureEl.current!.value = "7oVUoDBvpzoKoP1en3yGvGT10Qp1eqGSm4gpZw9U5Wo=";

            dsSignatureVersionEl.current!.value = response.dsSignatureVersion;
            dsMerchantParametersEl.current!.value = response.dsMerchantParameters;
            dsSignatureEl.current!.value = response.dsSignature;

            console.log("Redirigiendo a redsys con los siguientes datos: ",response)

            redsysForm.current!.submit();

        }, dispatch);

    }

    // @ts-ignore
    return (
        <>

        {/*    <form*/}
        {/*        //@ts-ignore*/}
        {/*        ref={redsysForm} name="from" action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST">*/}
        {/*        <input type="hidden" name="Ds_SignatureVersion" value="HMAC_SHA256_V1"/>*/}
        {/*        <input type="hidden" name="Ds_MerchantParameters" value="*/}
		{/*eyJEU19NRVJDSEFOVF9BTU9VTlQiOiAiMTQ1IiwiRFNfTUVSQ0hBTlRfQ1VSUkVOQ1kiOiAiOTc4IiwiRFNfTUVSQ0hBTlRfTUVSQ0hBTlRDT0RFIjogIjk5OTAwODg4MSIsIkRTX01FUkNIQU5UX01FUkNIQU5UVVJMIjogImh0dHA6Ly93d3cucHJ1ZWJhLmNvbS91cmxOb3RpZmljYWNpb24ucGhwIiwiRFNfTUVSQ0hBTlRfT1JERVIiOiAiMTQ0NjA2ODU4MSIsIkRTX01FUkNIQU5UX1RFUk1JTkFMIjogIjEiLCJEU19NRVJDSEFOVF9UUkFOU0FDVElPTlRZUEUiOiAiMCIsIkRTX01FUkNIQU5UX1VSTEtPIjogImh0dHA6Ly93d3cucHJ1ZWJhLmNvbS91cmxLTy5waHAiLCJEU19NRVJDSEFOVF9VUkxPSyI6ICJodHRwOi8vd3d3LnBydWViYS5jb20vdXJsT0sucGhwIn0="/>*/}
        {/*        <input type="hidden" name="Ds_Signature" value="PqV2+SF6asdasMjXasKJRTh3UIYya1hmU/igHkzhC+R="/>*/}
        {/*    </form>*/}

            <form
                //@ts-ignore
                ref={redsysForm}
                name="from"
                action="https://sis-t.redsys.es:25443/sis/realizarPago" method="POST">

                <input
                    //@ts-ignore
                    ref={dsSignatureVersionEl} type="hidden" name="Ds_SignatureVersion"/>

                <input
                    //@ts-ignore
                    ref={dsMerchantParametersEl} type="hidden" name="Ds_MerchantParameters"/>

                <input
                    //@ts-ignore
                    ref={dsSignatureEl} type="hidden" name="Ds_Signature"/>
            </form>

            <div style={{width: '100%', backgroundColor: "black"}}>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
                </Row>

                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}>
                        <Button onClick={redsysPost}>Iniciar pago redirección</Button>
                    </Col>
                </Row>

            </div>
        </>
    );
}