import {Button, Col, Form, Input, message, Row} from "antd";
import {useDispatch} from "react-redux";
import {useMatches, useNavigate} from "react-router-dom";
import styles from "../sendRecoveryEmail/SendRecoveryEmail.module.css";
import arrowImage from "../../assets/arrow.svg";
import React from "react";
import {resetPassword} from "../../solutions/controllers/UserController";
type FieldType = {
    password?: string;
    repeatPassword?: string;
};
export default function ResetPassword(){
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialMatches: any = useMatches();

    function handleResetPassword() {
        form.validateFields().then((value)=>{
            resetPassword(value.password, initialMatches[0].params.token, (errorMessage:string)=>{
                if (errorMessage===''){
                    message.success("Contraseña restablecida con éxito.");
                    navigate("/loginRegister")
                }else{
                    message.error("Error al restablecer la contraseña."+errorMessage)
                }
            },dispatch)
        }).catch(()=>{
            message.error('Solucione los errores del formulario.',10)
        })
    }
    function goToLoginRegister(){
        navigate('/loginRegister');
    }
    return (
        <div className={styles.container}>
            <Row align="middle" >
                <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    <img src={arrowImage} alt="Arrow image" onClick={goToLoginRegister} style={{cursor:'pointer'}}/>
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    <h1 className={styles.title} style={{ cursor:'pointer' }} onClick={goToLoginRegister}>
                        VOLVER
                    </h1>
                </Col>

                <Col xs={{span:14,offset:1}} sm={{span:10,offset:1}} md={{span:10,offset:2}} lg={{span:10,offset:2}} xl={{span:10,offset:2}} style={{textAlign:"center"}}>
                    <h1 className={styles.title} >RESTABLECER CONTRASEÑA</h1>
                </Col>
            </Row>
            <Row justify={'center'} style={{marginTop:30}}>
                <h1 style={{color:'grey', fontSize:18}}>Introduce tu nueva contraseña</h1>
            </Row>
            <Form
                form={form}
                name="forgotPasswordForm"
                labelCol={{ span: 24}}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
                initialValues={{ remember: true }}
                onFinish={handleResetPassword}

                autoComplete="off"
            >
                <Row justify={'center'} style={{marginTop:30}}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CONTRASEÑA NUEVA</span>}
                            name="password"
                            rules={[{ required: true, message: 'Debe introducir una contraseña nueva' }, { type: 'string', message: 'Contraseña nueva no válida' }]}
                            style={{ width: '100%' }}
                        >
                            <Input type={'password'} className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{marginTop:30}}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Form.Item<FieldType>
                        label={<span className={styles.label}>REPITA CONTRASEÑA</span>}
                        name="repeatPassword"
                        style={{ width: '100%' }}
                        rules={[
                            { required: true, message: 'Debe repetir la contraseña nueva' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Las contraseñas no coinciden'));
                                },
                            })
                        ]}
                        dependencies={['password']}

                    >
                        <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                    </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{paddingTop:30}}>
                    <Col xs={12} sm={10} md={7} lg={6} xl={4} xxl={3}>
                        <Button type={"default"} htmlType="submit" className={'defaultButton'}>CAMBIAR CONTRASEÑA</Button>
                    </Col>
                </Row>

            </Form>
        </div>
    );
}