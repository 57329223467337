import {IEventDate} from "./interfaces/IEventDate";
import React from "react";
import {Md5} from "ts-md5";
import {TaxType} from "./interfaces/ITax";
import {IEventDetails} from "./interfaces/IEventDetails";
import {cleanMessagesWebsocket, subscribeTo} from "../Controller/redux/websocket/WebSocketController";

export function formateDatesToString(dates: IEventDate[], setEventDateFormatted : React.Dispatch<React.SetStateAction<string>> ){
    let formattedDate = '';
    const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
    const monthNames = [
        'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
        'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
    ];
    if (dates.length===1){
        // @ts-ignore
        formattedDate = new Date(dates[0].date).toLocaleDateString('es-ES',dateOptions);

        if(dates[0].dateuntil != null){
            const untilDate = new Date(dates[0].dateuntil);
            const startDate = new Date(dates[0].date);

            if(startDate.getMonth() === untilDate.getMonth()){
                if(startDate.getDate() !== untilDate.getDate()){
                    //@ts-ignore
                    formattedDate = startDate.getDate()+"-"+untilDate.toLocaleDateString('es-ES',dateOptions);
                }
            }
            else{
                formattedDate = startDate.getDate()+" "+monthNames[startDate.getMonth()]+" a " +untilDate.getDate()+" "+monthNames[untilDate.getMonth()] +" "+untilDate.getFullYear();
            }
        }

        setEventDateFormatted(formattedDate);
    }else if(dates.length===2){
        formattedDate = new Date(dates[0].date).getDay() +', '+new Date(dates[1].date).getDay()+' de ';
        formattedDate+=monthNames[new Date(dates[0].date).getMonth()]
        setEventDateFormatted(formattedDate);
    }else if(dates.length>2){
        const startDay = new Date(dates[0].date).getDate();
        const endDay = new Date(dates[dates.length - 1].date).getDate();
        const monthName = monthNames[new Date(dates[0].date).getMonth()];
        formattedDate = `${startDay}-${endDay} de ${monthName}`;
        setEventDateFormatted(formattedDate);
    }
}
export function formatTicketDetailsDate(startDate:Date, endDate:Date, setFormattedDate:React.Dispatch<string>){
    if (endDate===null){
        endDate=startDate;
    }
    const start = new Date(startDate)
    const end = new Date(endDate)

    const isSameDay = start.getDate() === end.getDate() &&
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear();

    if (isSameDay) {
        // Si es el mismo día
        const dayOfMonth = start.getDate();
        const month = start.getMonth() + 1; // Sumamos 1 porque los meses en JavaScript son de 0 a 11

        setFormattedDate(`${dayOfMonth}/${month}`);
    } else if (end.getDate() - start.getDate() === 1 && end.getMonth() === start.getMonth()) {
        // Si hay un día de diferencia en el mismo mes
        const day1 = start.getDate();
        const day2 = end.getDate();
        const month = start.getMonth() + 1; // Sumamos 1 porque los meses en JavaScript son de 0 a 11

        setFormattedDate(`${day1}-${month}, ${day2}-${month}`);
    }else {
        // Si hay más de un día de diferencia o están en meses diferentes
        const day1 = start.getDate();
        const month1 = start.getMonth() + 1; // Sumamos 1 porque los meses en JavaScript son de 0 a 11
        const day2 = end.getDate();
        const month2 = end.getMonth() + 1; // Sumamos 1 porque los meses en JavaScript son de 0 a 11

        setFormattedDate(`${day1}-${month1}, ${day2}-${month2}`);
    }
}
export function formatDateToString(date: Date | undefined){
    if (date) {
        return new Date(date).toLocaleDateString('es-ES', {month: "2-digit", day: "2-digit",  year: "numeric"})
    } else {
        return "sin fecha"
    }
}

export function formatHourToString(date: Date){
    return new Date(date).toLocaleTimeString('es-ES', {hour: '2-digit', minute:'2-digit'})
}

export function formatDateToISOSString(date: Date | undefined) {
    if (date) {
        const dateObj = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date;
        return dateObj.toISOString();
    } else {
        return "sin fecha";
    }
}


export const waitForIframeToPostMessage = async ({
                                                     iframe,
                                                     condition,
                                                     timeout
                                                 }: {
    /** Iframe */
    iframe: HTMLIFrameElement
    /** Condition */
    condition?: ((event: MessageEvent) => boolean) | undefined
    /** Max time to wait, in milliseconds */
    timeout?: number | undefined
}): Promise<{ loaded: boolean }> => {
    const maybeTimeoutPromise = timeout != null
        ? new Promise(resolve => setTimeout(resolve, timeout))
        : undefined;

    let timedout = true;

    // Promise resolves after the iframe redirects, if it ever does
    const iframeMessagePromise = new Promise<void>((resolve) => {
        const onIframeMessage = (event: MessageEvent) => {
            // If condition matches or there is no condition, resolve
            if (condition?.(event) ?? true) {
                resolve();
                timedout = false;
                //@ts-ignore
                iframe.removeEventListener('message', onIframeMessage);
            }
        };
        //@ts-ignore
        iframe.addEventListener('message', onIframeMessage);

        // Clean everything afer timeout
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        maybeTimeoutPromise?.then(() => {
            //@ts-ignore
            iframe.removeEventListener('message', onIframeMessage);
        });
    });

    // Wait until iframe completes or timeout ellapses
    await Promise.race([iframeMessagePromise, maybeTimeoutPromise]);

    return { loaded: timedout };
};

export function generateUuid() {
    try {
        let frontId = localStorage.getItem('frontId');
        if(frontId == null){
            frontId = Math.random().toString() +
                Math.random().toString() +
                Math.random().toString();
            localStorage.setItem('frontId', frontId);
        }
        console.log("Recuperado frontId "+frontId)
        return frontId
    } catch (error) {
        console.error("Error recuperando frontId" ,error)
    }
}

export function generateUuidAndExecuteCallback(dispatch: Function, callback: (arg0: string | null) => any) {
    let storedFrontId = localStorage.getItem('frontId');
    let newFrontId = "";
    if(storedFrontId == null){
        newFrontId = Math.random().toString() +
            Math.random().toString() +
            Math.random().toString();
    }
    else{
        callback && callback(storedFrontId);
        return;
    }

    try {
        if(storedFrontId == null){
            localStorage.setItem('frontId', newFrontId);
            callback && callback(newFrontId);
        }
    }
    catch (error) {
        console.error("Error recuperando frontId" ,error)
        try{
            cleanMessagesWebsocket(dispatch);
            setTimeout(() =>{
                try{
                    localStorage.setItem('frontId', newFrontId);
                    callback && callback(newFrontId)
                }catch (e){
                    localStorage.clear();
                    try{
                        localStorage.setItem('frontId', newFrontId);
                        callback && callback(newFrontId)
                    }catch (e2){
                        callback && callback(null)
                    }
                }

            }, 1000)

        }catch (e){
            callback && callback(null)
        }
    }
}

export function cleanMessages(dispatch: Function) {
    let storedObject = localStorage.getItem('persist:root');

    cleanMessagesWebsocket(dispatch);

// Verificar si el objeto existe
    if (storedObject) {
        // Parsear el objeto recuperado
        let parsedObject = JSON.parse(storedObject);

        // Eliminar el objeto deseado dentro del objeto principal
        // Supongamos que el objeto a eliminar tiene una clave única 'objetoAEliminar'
        delete parsedObject['websocket'];

        console.log("JSON.stringify(parsedObject)", JSON.stringify(parsedObject))
        // Guardar de nuevo el objeto modificado en el localStorage

        try{
            localStorage.removeItem('persist:root');

        }catch (e){
            localStorage.removeItem('persist:root');
            localStorage.setItem('persist:root', JSON.stringify(parsedObject))
        }
    }
}

export function deleteUuid() {
    localStorage.removeItem('frontId');
}

export const encryptMD5 = (text: string) : string => {
    return Md5.hashStr(text);
}

export function randomString(length: number) {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return randomStringWithSpecificChars(length, chars);
}
export function randomStringWithSpecificChars(length: number, chars: string) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
}
// @ts-ignore
export function refreshQR(seconds:number,totalSeconds:number,setSeconds:React.Dispatch<React.SetStateAction<number>>, timeOutAction){
    //console.log("refreshQR seconds "+seconds+", "+totalSeconds)

    const timer = setTimeout(() => {
        //console.log("Set timeout seconds "+seconds+", "+totalSeconds)

        if (seconds > 0) {
            setSeconds(seconds - 1);
        }else{
            setSeconds(totalSeconds);
            timeOutAction && timeOutAction();
        }
    }, 1000);
    return () => clearTimeout(timer);
}
export function openModal(setModalOpen:React.Dispatch<boolean>){
    setModalOpen(true);
}
export function closeModal(setModalOpen:React.Dispatch<boolean>){
    setModalOpen(false);
}
export function calculateAmountToPayWithCommission(price:number,tax:number,type:string,setPrice:React.Dispatch<number>){
    let priceToSet = 0;
    if (price>0){
        if (type===TaxType.PERCENTAGE){
            priceToSet = price + (tax / 100) * price;
        }else{
            priceToSet=price+tax;
        }
    }
    setPrice(priceToSet);

}

export function capitalizeTitle (title: string | undefined): string {
    // @ts-ignore
    return title?.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export function generateEventStructuredData(event: IEventDetails, url:string) {
    return {
        "@context": "https://schema.org",
        "@type": "Event",
        "name": capitalizeTitle(event?.title),
        "startDate": formatDateToISOSString(event?.dates[0].date),
        "endDate": formatDateToISOSString(event?.dates[0].date),
        "location": {
            "@type": "Place",
            "name": event.address,
            "address": event.address,
        },
        "description": event.description,
        "url": url,
        "image": event.image,
    };
};
