import styles from "./FilterEvents.module.css";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {
    clearFilter, filterByCategory, filterByCountry,
    filterByPriceController,
    filterByPublicationDateController
} from "../../solutions/controllers/FilterController";
import { IEvent } from "../../solutions/interfaces/IEvent";
import { ICategory } from "../../solutions/interfaces/ICategory";
import { useDispatch } from "react-redux";
import {ITown} from "../../solutions/interfaces/ITown";

import type { MenuProps } from 'antd';
import {Col,  Menu, Row} from 'antd';
import {getCategories, getFilterTowns} from "../../solutions/controllers/EventController";

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: React.Key,
    children?: MenuItem[],
    type?: 'group',
    onClick?: () => void,


): MenuItem {
    return {
        key,
        children,
        label,
        type,
        onClick,
    } as MenuItem;
}

interface FilterEventsProps {
    originalEvents: IEvent[];
    filteredEvents: IEvent[];
    setFilteredEvents: Dispatch<SetStateAction<IEvent[]>>;
    categories: ICategory[];
    dispatch: ReturnType<typeof useDispatch>;
}
const FilterEvents: React.FC<FilterEventsProps> = ({originalEvents,filteredEvents,setFilteredEvents,categories,dispatch,}) => {

    const [towns, setTowns] = useState<ITown[]>()
    const [categoriesObtained, setCategoriesObtained] = useState<ICategory[] >()
    const [filterDateValue,setFilterDateValue] = useState('')
    const [filterMinPriceValue,setFilterMinPriceValue] = useState(0)
    const [filterMaxPriceValue,setFilterMaxPriceValue] = useState<number | null>(0)
    const [isClear,setIsClear] = useState(false);
    const [filterUbicationValue,setFilterUbicationValue] = useState(0)
    const [filterEventTypeValue,setFilterEventTypeValue] = useState(0)
    const [dateItems, setItems] = useState<MenuItem[]>([
        getItem('Fecha', 'startDate', [
            getItem('Cualquier momento', 'anytime',undefined, undefined, () => handleDateFilter('anytime')),
            getItem('Siguientes 24 horas', 'next24Hours',undefined, undefined, () => handleDateFilter('next24Hours')),
            getItem('Siguiente semana', 'nextWeek',undefined, undefined, () => handleDateFilter('nextWeek')),
            getItem('Siguiente mes', 'nextMonth',undefined, undefined, () => handleDateFilter('nextMonth')),
            getItem('Siguiente año', 'nextYear',undefined, undefined, () => handleDateFilter('nextYear')),
        ]),

    ]);
    const [priceItems, setPriceItems] = useState<MenuItem[]>([


            getItem(<span style={{width: '100%', textAlign: "center"}}>Precio</span>, 'price', [
            getItem('Menos de 10€', 'lessTen',undefined,undefined,()=> filterByPrice(1,10)),
            getItem('10-20€', 'tenTwenty',undefined,undefined,()=> filterByPrice(10,20)),
            getItem('20-30€', 'twentyThirty',undefined,undefined,()=> filterByPrice(20,30)),
            getItem('30-40€', 'thirtyFourty',undefined,undefined,()=> filterByPrice(30,40)),
            getItem('Más de 40€', 'moreFourty',undefined,undefined,()=> filterByPrice(40,null)),
        ]),

    ]);
    const [cleanItems, setCleanItems] = useState<MenuItem[]>([

        getItem('Limpiar','cleanFilters',undefined, undefined, () => onClear()),

    ]);
    const [ubicationItems, setUbicationItems] = useState<MenuItem[]>([

        getItem('Lugar','ubication',[], undefined,),

    ]);
    const [eventTypeItems, setEventTypeItems] = useState<MenuItem[]>([

        getItem('Tipo','eventType',[], undefined, ),

    ]);


    function onClear(){
        setIsClear(true);
    }
    function filterByPrice(min:number,max:number | null){
        setFilterMinPriceValue(min);
        setFilterMaxPriceValue(max);
    }

    function handleDateFilter(value:string){
        setFilterDateValue(value);
    }

    function handleUbicationFilter(value:number){
        setFilterUbicationValue(value);
    }

    function handleEventTypeFilter(value:number){
        setFilterEventTypeValue(value);
    }


    useEffect(()=>{
        if (filterEventTypeValue){
            filterByCategory(filterEventTypeValue,setFilterEventTypeValue, originalEvents,setFilteredEvents);
        }
    },[filterEventTypeValue]);

    useEffect(()=>{
        if (filterUbicationValue){
            filterByCountry(filterUbicationValue, setFilterUbicationValue,originalEvents,setFilteredEvents);
        }
    },[filterUbicationValue]);

    useEffect(()=>{
        if (isClear){
            clearFilter(originalEvents,setFilteredEvents);
            setIsClear(false);
        }
    },[isClear]);

    useEffect(()=>{
        if (filterDateValue){
            filterByPublicationDateController(originalEvents, setFilteredEvents,filterDateValue,setFilterDateValue);
        }
    },[filterDateValue]);

    useEffect(()=>{
        if (filterMinPriceValue)
            filterByPriceController(originalEvents, setFilteredEvents, filterMinPriceValue, filterMaxPriceValue,setFilterMaxPriceValue,setFilterMinPriceValue);
    },[filterMaxPriceValue,filterMinPriceValue]);

    useEffect(()=>{
        getFilterTowns(setTowns, dispatch);
        // @ts-ignore
        getCategories(setCategoriesObtained, dispatch);
    }, [])

    useEffect(() => {
        if (towns && towns.length > 0) {
            setUbicationItems((prevItems) => {
                const cityItems = towns.map((t) =>
                    getItem(t.town, `town-${t.townid}`, undefined, undefined, () => handleUbicationFilter(t.townid))
                );

                const newItems = [...prevItems];
                const ubicationIndex = newItems.findIndex((item) => item?.key === 'ubication');
                if (ubicationIndex !== -1) {
                    // @ts-ignore
                    newItems[ubicationIndex].children = cityItems;
                } else {
                    newItems.splice(0, 0, getItem('Ubicación', 'ubication', cityItems));
                }

                return newItems;
            });
        }
    }, [towns]);
    useEffect(() => {
        if (categories && categories.length > 0) {
            setEventTypeItems((prevItems) => {
                const items = categories.map((c) =>
                    getItem(c.eventtype, `eventType-${c.eventtypeid}`, undefined, undefined, () => handleEventTypeFilter(c.eventtypeid))
                );

                const newItems = [...prevItems];
                const index = newItems.findIndex((item) => item?.key === 'eventType');
                if (index !== -1) {
                    // @ts-ignore
                    newItems[index].children = items;
                } else {
                    newItems.splice(0, 0, getItem('Tipo de evento', 'eventType', items));
                }
                return newItems;
            });
        }
    }, [categories]);

    const [activeItem, setActiveItem] = useState<string>('');

    return (
        <>
            <Row justify={'center'}>
                <Col xs={{span:4,offset:0}} sm={{span:4,offset:0}} md={{span:4,offset:0}} lg={{span:3,offset:0}} xl={{span:2,offset:0}} className={`${styles.separationHorizontal} ${styles.separationVertical}`}>

                    <Menu
                        mode={'horizontal'}
                        items={eventTypeItems}
                        selectedKeys={[activeItem]}
                        onClick={(e) => {
                            setActiveItem(e.key.toString());
                        }}
                        className={styles.customMenu}
                        triggerSubMenuAction={'click'}
                    >
                    </Menu>
                    
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:4,offset:0}} md={{span:4,offset:0}} lg={{span:3,offset:0}} xl={{span:2,offset:0}} className={`${styles.separationHorizontal} ${styles.separationVertical}`}>
                    <Menu
                        mode={'horizontal'}
                        items={ubicationItems}
                        className={styles.customMenu}
                        triggerSubMenuAction={'click'}
                        selectedKeys={[activeItem]}
                        onClick={(e) => {
                            setActiveItem(e.key.toString());
                        }}
                    />
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:4,offset:0}} md={{span:4,offset:0}} lg={{span:3,offset:0}} xl={{span:2,offset:0}} className={`${styles.separationHorizontal} ${styles.separationVertical}`}>
                    <Menu
                        mode={'horizontal'}
                        items={dateItems}
                        className={styles.customMenu}
                        triggerSubMenuAction={'click'}
                        selectedKeys={[activeItem]}
                        onClick={(e) => {
                            setActiveItem(e.key.toString());
                        }}
                    />
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:4,offset:0}} md={{span:4,offset:0}} lg={{span:3,offset:0}} xl={{span:2,offset:0}} className={`${styles.separationHorizontal} ${styles.separationVertical}`}>
                    <Menu
                        mode={'horizontal'}
                        items={priceItems}
                        className={styles.customMenu}
                        triggerSubMenuAction={'click'}
                        selectedKeys={[activeItem]}
                        onClick={(e) => {
                            setActiveItem(e.key.toString());
                        }}
                    />
                </Col>

                <Col xs={{span:4,offset:0}} sm={{span:4,offset:0}} md={{span:4,offset:0}} lg={{span:3,offset:0}} xl={{span:2,offset:0}} className={`${styles.separationVertical}`}>
                    <Menu
                        mode={'horizontal'}
                        items={cleanItems}
                        className={styles.customMenu}
                        triggerSubMenuAction={'click'}
                        selectedKeys={[activeItem]}
                        onClick={(e) => {
                            setActiveItem(e.key.toString());
                        }}
                    />
                </Col>

            </Row>


        </>
    )
}
export default FilterEvents;