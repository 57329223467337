import {Col, Row} from "antd";
import styles from "./TicketEventExtraBox.module.css";
import ticketImage from "../../assets/ticketeventextraicon.svg";
import {CloseOutlined} from "@ant-design/icons";
import React, {useState} from "react";
import {ITicketEventExtra} from "../../solutions/interfaces/ITicketEventExtra";
import {
    addTicketEventExtraToShoppingCar,
    isTicketEventExtraSelected, removeTicketEventExtraFromShoppingCar
} from "../../solutions/controllers/TicketEventExtraController";


export default function TicketEventExtraBox({ticketeventextra,index, ticketList,setTicketList}:
                                                { ticketeventextra: ITicketEventExtra,index:number, ticketList:any[],setTicketList:Function}){

    function checkIsTicketSelected() {
        return isTicketEventExtraSelected(ticketList,index,ticketeventextra);
    }

    function onAddTicket() {
        addTicketEventExtraToShoppingCar(ticketList,setTicketList,ticketeventextra,index);

    }

    function onRemoveTicket() {
        removeTicketEventExtraFromShoppingCar(ticketList,setTicketList,ticketeventextra,index);
    }

    return (

        <Row justify={'center'}  key={index}>

            <Col className={checkIsTicketSelected()?styles.ticketBoxSelected:styles.ticketBox}   xs={23} sm={18} md={16} lg={14} xl={12} xxl={10}>

                <Row style={{ alignItems: "center", width:'100%' }}>

                    <Col xs={{offset:1,span:2}} sm={{offset:1,span:2}} md={{offset:1,span:2}} lg={{offset:1,span:2}} xl={{offset:1,span:2}}  xxl={{offset:1,span:2}}>
                        <img alt="ticketeventextraImg" src={ticketImage} className={styles.boxImage} style={{marginLeft: 0}}/>
                    </Col>

                    <Col xs={{span:12,offset:1}} sm={{span:12,offset:1}} md={{span:15,offset:0}} lg={{span:15,offset:0}} xl={{span:15,offset:0}}
                         xxl={{span:15,offset:0}}>
                        <p className={styles.ticketTitle} style={{marginLeft:5}}>{ticketeventextra.ticketeventextra}</p>
                    </Col>

                    <Col style={{textAlign:'center'}} xs={{span: 6, offset: 1 }} sm={{span: 6, offset: 1 }} md={{span: 4, offset: 1 }} lg={{span: 4, offset: 1 }} xl={{span: 4, offset: 1 }}  xxl={{ span: 4, offset: 1 }}>
                        <p className={styles.ticketTitle} >{ticketeventextra.quantity}€</p>
                    </Col>

                </Row>

                <Row style={{  width:'100%' }} align={'middle'}>

                    <Col xs={{span:15,offset:1}} sm={{span:15,offset:1}} md={{span:15,offset:3}} lg={{span:15,offset:3}} xl={{span:15,offset:3}} xxl={{span:15,offset:3}} >
                        <p className={styles.ticketMoreInfo} >{ticketeventextra.description} </p>
                    </Col>

                    {
                        ticketeventextra.exhausted ?
                            <Col xs={{span: 6, offset: 1 }} sm={{span: 6, offset: 1 }} md={{span: 4, offset: 1 }} lg={{span: 4, offset: 1 }} xl={{span: 4, offset: 1 }}  xxl={{ span: 4, offset: 1 }}>
                                <p className={styles.exhausted}>
                                    AGOTADO
                                </p>
                            </Col>
                            :
                            checkIsTicketSelected() ?
                                <Col xs={{span: 6, offset: 1 }} sm={{span: 6, offset: 1 }} md={{span: 4, offset: 1 }} lg={{span: 4, offset: 1 }} xl={{span: 4, offset: 1 }}  xxl={{ span: 4, offset: 1 }}>
                                    <p className={styles.add} onClick={() => onRemoveTicket()} style={{backgroundColor:'grey', cursor:'pointer',}}>
                                        <CloseOutlined  className={styles.deleteIcon} /> SELECCIONADA
                                    </p>
                                </Col>
                            :
                                <Col xs={{span: 6, offset: 1 }} sm={{span: 6, offset: 1 }} md={{span: 4, offset: 1 }} lg={{span: 4, offset: 1 }} xl={{span: 4, offset: 1 }}  xxl={{ span: 4, offset: 1 }}>
                                    <p className={styles.add} onClick={()=>onAddTicket()}>
                                        AÑADIR
                                    </p>
                                </Col>

                    }

                </Row>

            </Col>

        </Row>

    )
}