import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {IEvent} from "../../solutions/interfaces/IEvent";
import {ICategory} from "../../solutions/interfaces/ICategory";
import {useDispatch} from "react-redux";
import {
    getPublicRelationsEventsAndCategories
} from "../../solutions/controllers/EventController";
import {Col, message, Row} from "antd";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {IUser} from "../../solutions/interfaces/IUser";
import Header from "../../components/header/Header";
import EventsList from "../../components/eventsList/EventsList";

export default function EventPublicRelations () {
    const dispatch = useDispatch();
    const {publicRelationsUserId} = useParams();
    const navigate = useNavigate();

    const [publicRelations, setPublicRelations] = useState<IUser | undefined >(undefined)
    const [originalEventData, setOriginalEventData] = useState<IEvent[]>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);

    useEffect(() => {
        getPublicRelation()
        getPublicRelationsEventsAndCategories(setOriginalEventData, setCategories, dispatch, publicRelationsUserId);
    }, []);

    function getPublicRelation () {
        const url = rootServiceURL() + "completegraph/rrpp/getPublicRelations/" + publicRelationsUserId
        const rc = new RestClient(dispatch).url(url);
        rc.doGet((result:IUser) => {
            if (rc.isSuccess()) {
                setPublicRelations(result)
            } else {
                redirectNotPublicRelations()
            }
        })
    }

    const handleEventClick = (eventid : number) => {
        navigate("/eventDetails/"+eventid+"/"+publicRelationsUserId+"/"+publicRelations?.name.replace(/ /g, "-"))
    }

    const redirectNotPublicRelations = () => {
        message.warning("El usuario seleccionado no es un RRPP")
        navigate("/")
    }

    return (
        <div className={originalEventData.length > 2 ? 'footerSeparation' : 'footerPaddingSeparation'} style={originalEventData.length > 2 ? {}:{height:"88%"}}>
            <Row style={{paddingBottom:0, paddingTop: 0}} justify={'center'}>
                <Col span={24} style={{ position: 'relative' }}>
                    <Row style={{marginTop:10}}></Row>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <div style={{paddingBottom:15}}>
                <Row justify="center">
                    <Col xs={23} sm={18} md={16} lg={14} xl={12} xxl={10} style={{ textAlign: 'center' }}>
                        <h2 style={{ color: '#ffffff', marginBlockStart:0, marginBlockEnd:"2.5em"}}>{publicRelations?.name}</h2>
                    </Col>
                </Row>
                <EventsList eventList={originalEventData} handleEventClick={handleEventClick}/>
            </div>
        </div>
    )
}