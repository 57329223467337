import styles from './Header.module.css';
import {Button, Col, Input, message, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import searchImage from '../../assets/ic_outline-search.png';
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../solutions/controllers/UserController";
import {IUser} from "../../solutions/interfaces/IUser";
import {useNavigate} from "react-router-dom";
import {modifySearchText, toogleVisibility} from "../../solutions/controllers/FilterController";
import './styles.css';

export default function Header({showFilters, showButton = true, onFilter, onLoginRegisterCallback,isFilterVisible,setIsFilterVisible} : {showFilters: boolean, showButton?: boolean, onFilter?: Function, onLoginRegisterCallback?: Function,setIsFilterVisible?:React.Dispatch<boolean>,
    isFilterVisible?:boolean},){
    const [profile, setProfile] = useState<IUser>();
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState('');
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    //@ts-ignore
    const auth = useSelector(state => state.auth);
    const navigate = useNavigate();
    function goToLoginRegister(){
        navigate("/loginRegister")
    }

    function goToMyCollection(){
        message.warning("Página deshabilitada temporalmente")
        //TODO cambiar esto cuando se habilite el perfil
        //navigate("/profile")
    }

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        modifySearchText(e.target.value, setSearchValue);
    };


    useEffect(() => {
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);
        }
        else {
            setProfile(undefined);
        }
    }, [auth]);

    useEffect(()=>{
        if (onFilter){
            onFilter(searchValue);
        }
    },[searchValue])

    return (
            <>
                <Row >
                    <Col xs={{span: 5, offset: 1}}
                         sm={{span:23, offset: 1}}
                         md={{span:2, offset: 2}}
                         lg={{span:2, offset: 2}}
                         xl={{span:2, offset: 2}}
                         xxl={{span:2, offset: 2}}
                         style={{marginTop:10}}>
                        <a onClick={() => navigate("/")}><h1 className={styles.title}>TIXXLAB</h1></a>
                    </Col>
                    <Col xs={{span:8,offset:2}}
                         sm={5}
                         md={{ span: 12, offset: 1 }}
                         lg={{ span: 12, offset: 1 }}
                         xl={{ span: 15, offset: 0 }}
                         xxl={{ span: 12, offset: 2 }}
                         style={{marginTop:10}}>
                        {showFilters  &&
                            <div >
                                    {/*{isFilterVisible!==undefined && setIsFilterVisible!==undefined?(
                                        <button className={styles.searchButton} onClick={()=>toogleVisibility(isFilterVisible,setIsFilterVisible)}>
                                            <img width={32} height={32} src={filterImage} alt="Filter" />
                                        </button>
                                    ):(<></>)}*/}
                                <Row justify={'center'} style={{marginTop:0}}>
                                    <Col xs={0} sm={0} md={1} lg={1} xl={1} xxl={1}>
                                        <button  className={styles.searchButton} onClick={()=>toogleVisibility(isSearchVisible,setIsSearchVisible)}>
                                            <img  className={styles.searchImage} src={searchImage} alt="Search" />
                                        </button>
                                    </Col>
                                    <Col xs={0} sm={0} md={{span:8,offset:1}} lg={{span:8,offset:1}}  xxl={{span:8,offset:1}} xl={{span:8,offset:1}}>
                                        <Input className={styles.search} placeholder=""  onChange={onSearchChange}/>
                                    </Col>
                                </Row>
                                <Row style={{marginTop:0}}>
                                    <Col xs={5} sm={8} md={0} lg={0}  xxl={0}  xl={0}>
                                        <button  className={styles.searchButton} onClick={()=>toogleVisibility(isSearchVisible,setIsSearchVisible)}>
                                            <img  className={styles.searchImage} src={searchImage} alt="Search" />
                                        </button>
                                    </Col>
                                    <Col xs={{span:15,offset:1}} sm={8} md={0} lg={0}  xxl={0}  xl={0}>
                                        <Input className={styles.search} placeholder=""  onChange={onSearchChange}/>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </Col>
                    <Col xs={{ span: 7, offset: 1 }}
                         sm={{ span: 23, offset: 1 }}
                         md={{ span: 3, offset: 2 }}
                         lg={{ span: 3, offset: 2 }}
                         xl={{ span: 3, offset: 2 }}
                         xxl={{ span: 3, offset: 2 }}
                         style={{marginTop:10}}>
                        <Space direction={"horizontal"}>
                            {showButton === undefined || showButton && (

                                <>
                                    <Button onClick={ profile?goToMyCollection:goToLoginRegister} type={'default'} className={styles.headerButton}>Mis entradas
                                    </Button>
                                </>

                            )}
                        </Space>
                    </Col>
                </Row>
            </>
    );
}