import MyTickets from "../../components/myTickets/MyTickets";
import ProfileHeader from "../../components/profileHeader/ProfileHeader";

export default function Profile(){
    return (
        <div className={'footerSeparation'}>
            <div style={{paddingTop:0}}>
                <ProfileHeader></ProfileHeader>
            </div>
            <div style={{paddingTop:15, paddingBottom:15}}>
                <MyTickets></MyTickets>
            </div>
        </div>
    );
}