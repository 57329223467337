import {Button, Col, Form, Input, message, Row} from "antd";
import {useDispatch} from "react-redux";
import {useMatches, useNavigate} from "react-router-dom";
import styles from "./Contact.module.css";
import React from "react";
import TextArea from "antd/es/input/TextArea";
import Header from "../../components/header/Header";
type FieldType = {
    name?: string;
    lastName?: string;
    subject: string;
    email: string;
    message: string;
};
export default function ContactForm(){
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialMatches: any = useMatches();

    function handleSendContactForm() {
        form.validateFields().then((value)=>{
            message.success("Formulario de contacto enviado correctamente");
            form.resetFields();
        }).catch(()=>{
            message.error('Solucione los errores del formulario.',10)
        })
    }
    function goToLoginRegister(){
        navigate('/loginRegister');
    }

    return (
        <div className={styles.container}>
            <Row style={{paddingBottom:30, paddingTop: 30}} justify={'center'}>
                <Col span={24}>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <Row align="middle" >
                <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    {/*<img src={arrowImage} alt="Arrow image" onClick={goToLoginRegister} style={{cursor:'pointer'}}/>*/}
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    {/*<h1 className={styles.title} style={{ cursor:'pointer' }} onClick={goToLoginRegister}>*/}
                    {/*    VOLVER*/}
                    {/*</h1>*/}
                </Col>

                <Col xs={{span:14,offset:1}} sm={{span:10,offset:1}} md={{span:10,offset:2}} lg={{span:10,offset:2}} xl={{span:10,offset:2}} style={{textAlign:"center"}}>
                    <h1 className={styles.title} >FORMULARIO DE CONTACTO</h1>
                </Col>
            </Row>
            <Form
                form={form}
                name="contactForm"
                labelCol={{ span: 24}}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
                initialValues={{ remember: true }}
                onFinish={handleSendContactForm}

                autoComplete="off"
            >
                <Row justify={'center'} style={{marginTop:30}}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>NOMBRE</span>}
                            name="name"
                            rules={[{ required: true, message: 'Debe introducir un nombre' }]}
                            style={{ width: '100%' }}
                        >
                            <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>APELLIDOS</span>}
                            name="lastName"
                            rules={[{ required: true, message: 'Debe introducir los apellidos' }]}
                            style={{ width: '100%' }}
                        >
                            <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} >
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>EMAIL</span>}
                            name="email"
                            rules={[{ required: true, message: 'Debe introducir un email' }, {type:'email', message:'Formato de email incorrecto'}]}
                            style={{ width: '100%' }}
                        >
                            <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} >
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>ASUNTO</span>}
                            name="subject"
                            rules={[{ required: true, message: 'Debe introducir el asunto' }]}
                            style={{ width: '100%' }}
                        >
                            <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{marginTop:30}}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>MENSAJE</span>}
                            name="message"
                            rules={[{ required: true, message: 'Debe introducir el mensaje' }]}
                            style={{ width: '100%' }}
                        >
                            <TextArea className={styles.inputMultiline} rows={4} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{paddingTop:30}}>
                    <Col xs={12} sm={10} md={7} lg={6} xl={4} xxl={3}>
                        <Button type={"default"} htmlType="submit" className={'defaultButton'}>ENVIAR MENSAJE</Button>
                    </Col>
                </Row>

            </Form>
        </div>
    );
}