import React, {useEffect, useRef, useState} from "react";
import {Simulate} from "react-dom/test-utils";
import {Button, Col, Form, message, Row} from "antd";
import styles from "./Buy.module.css";
import {auth, create3DSMethodForm, getRandomOrderId} from "../../solutions/controllers/BuyController";
import {useDispatch, useSelector} from "react-redux";
import {rootServiceURL} from "../../Controller";
import {waitForIframeToPostMessage} from "../../solutions/Utils";
import {AuthBody, FinishedMessageEvent, ThreeDSBrowserClientInfo} from "../../solutions/interfaces/server-info";
import {useLocation, useNavigate} from "react-router-dom";
import {openWebSocket, subscribeTo, unsubscribe} from "../../Controller/redux/websocket/WebSocketController";


// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function BuyTest(){
    const [idOper, setIdOper] = useState<string | null>(null)
    const [orderId , setOrderId] = useState(getRandomOrderId())
    const navigate = useNavigate();
    const authSelector = useSelector((state : any) => state.auth);
    const dispatch = useDispatch();
    const { state } = useLocation(); // state is any or unknown

    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const inputValidator = (v: any) => {
        // Should validate input before sending form
        console.log("inputValidator ",v)
        return true;
    };




    // useEffect(() => {
    //     if(idOper && idOper !== '-1'){
    //         preauth(idOper, orderId, {}, 0.01, async (preauthResponse: any) => {
    //             console.log("preauthResponse", preauthResponse)
    //
    //             let preauthResult = {};
    //             if (
    //                 'threeDSMethodURL' in preauthResponse &&
    //                 preauthResponse.threeDSMethodURL != null
    //             )
    //             {
    //                 // 3DS method required
    //                 preauthResult = {
    //                     // Inform the frontend about the 3DS version used
    //                     threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
    //                     threeDSServerTransID: preauthResponse.threeDSServerTransID,
    //                     // Data for 3DS method
    //                     // The frontend should create an iframe and use a POST request to submit this data
    //                     threeDSMethodForm: create3DSMethodForm(preauthResponse.threeDSServerTransID, preauthResponse.threeDSMethodURL,
    //                         `${rootServiceURL() + "completegraph/payment/threeDSNotification"}/${orderId}`)
    //                 };
    //             } else {
    //                 preauthResult = {
    //                     // Inform the frontend about the 3DS version used
    //                     threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
    //                     threeDSServerTransID: preauthResponse.threeDSServerTransID,
    //                 };
    //             }
    //
    //             if ('threeDSMethodForm' in preauthResult && preauthResult.threeDSMethodForm) {
    //                 if (
    //                     !threeDSMethodFormEl ||
    //                     !threeDSMethodIframeEl ||
    //                     !threeDSMethodDataEl
    //                 ) {
    //                     console.error("Faltan iframees para 3DS");
    //                     return;
    //                 }
    //
    //                 console.log("threeDSMethodFormEl", threeDSMethodFormEl)
    //
    //                 // Serialize the parameters of the body into JSON and encode them with base64url
    //                 // Then place each encoded parameter in their respective input element
    //                 // @ts-ignore
    //                 threeDSMethodDataEl!.current.value = preauthResult.threeDSMethodForm.body.threeDSMethodData;
    //
    //                 // Fill out the form information and submit.
    //                 // @ts-ignore
    //                 threeDSMethodFormEl!.current.action = preauthResult.threeDSMethodForm.url;
    //                 // @ts-ignore
    //                 threeDSMethodFormEl!.current.submit();
    //
    //                 // @ts-ignore
    //                 await waitForIframeToPostMessage({
    //                     // @ts-ignore
    //                     iframe: threeDSMethodIframeEl.current,
    //                     condition: (event: MessageEvent) => {
    //                         return typeof event.data !== 'object' &&
    //                             event.data != null &&
    //                             'finished' in event.data &&
    //                             event.data.finished;
    //                     },
    //                     // Wait until 3DS method completes or a 10 seconds timeout
    //                     timeout: 10 * 1000
    //                 });
    //
    //             }
    //
    //             const clientInfo = obtain3DSBrowserInfo();
    //
    //             const authBody : AuthBody = {
    //                 transactionData:  (
    //                     //@ts-ignore
    //                     preauthResult.threeDSProtocolVersion === '2.2.0' || preauthResult.threeDSProtocolVersion === '2.1.0'
    //                         ? {
    //                             type: '3ds-v2',
    //                             //@ts-ignore
    //                             threeDSServerTransID: preauthResult.threeDSServerTransID,
    //                             //@ts-ignore
    //                             threeDSProtocolVersion: preauthResult.threeDSProtocolVersion
    //                         }
    //                         : {
    //                             type: 'regular',
    //                             orderId,
    //                             paymentMethod: {
    //                                 type: 'id-oper',
    //                                 idOper
    //                             },
    //                             //@ts-ignore
    //                             threeDSProtocolVersion: preauthResult.threeDSProtocolVersion
    //                         }
    //                 ),
    //                 clientInfo
    //             };
    //
    //
    //             console.log("Enviando petición autorización ",authBody)
    //             auth(idOper, orderId, 0.01, authBody ,async (authResponse: any) => {
    //                 console.log("AuthResponse ",authResponse);
    //
    //                 if(authResponse){
    //                     if(authResponse.status === "DONE"){
    //                         message.success("Compra finalizada correctamente con frictionless")
    //                     }
    //                     else if(authResponse.status === "CHALLENGE"){
    //                         let iframe: HTMLIFrameElement;
    //
    //                         if(authResponse.fromVersion === "1"){
    //
    //                         }
    //                         else{
    //                             if (
    //                                 !challengeV2IframeEl ||
    //                                 !challengeV2FormEl ||
    //                                 !challengeV2CreqEl
    //                             ) {
    //                                 throw new Error('Form for 3DS v2 challenge does not exist');
    //                             }
    //
    //                             //@ts-ignore
    //                             iframe = challengeV2IframeEl!.current;
    //
    //                             //@ts-ignore
    //                             challengeV2CreqEl!.current.value = authResponse.creq;
    //
    //                             //@ts-ignore
    //                             challengeV2FormEl!.current.action = authResponse.acsURL;
    //
    //                             //@ts-ignore
    //                             challengeV2FormEl!.current.submit();
    //
    //                             setActiveSection("challenge-v2")
    //                         }
    //
    //                         await waitForIframeToPostMessage({
    //                             // @ts-ignore
    //                             iframe,
    //                             condition: (event: MessageEvent) => {
    //                                 return typeof event.data !== 'object' &&
    //                                     event.data != null &&
    //                                     'finished' in event.data &&
    //                                     (event as FinishedMessageEvent).data.finished;
    //                             }
    //                         });
    //
    //                         // After the payment finishes, we let them see the postchallenge page for
    //                         // a few seconds before redirecting to our frontend page
    //                         await new Promise(resolve => setTimeout(resolve, 5 * 1000));
    //                     }
    //                     else{
    //
    //                     }
    //                 }
    //                 else{
    //                     message.error("Error en petición de autorización")
    //                 }
    //
    //             }, dispatch);
    //
    //         }, dispatch)
    //     }
    //
    // }, [idOper]);

    useEffect(() => {
        console.log("Cargando iframe de redsys...")

        const inputStyle = "font-size: 14px; color: #F9F9F9 !important;"

        const bodyStyle = "margin:-12px; padding:0px; height: 40px; border-width: 4px !important;  border-radius: 10px !important; width: 100% !important; " +
            "border-color: #333232 !important; background-color: #2A2727 !important; color: #F9F9F9 !important;"

        //@ts-ignore
        getCardInput('card-number', bodyStyle, '0000 0000 0000 0000', inputStyle)
        //@ts-ignore
        getExpirationInput('card-expiration', bodyStyle, 'MM/AA');
        //@ts-ignore
        getCVVInput('cvv', bodyStyle, 'CVV');
        const buttonStyle = "width: 100%; border-radius: 10px; background-color: #1CEDAE; border-color: #1CEDAE;"
        //@ts-ignore
        getPayButton('boton', buttonStyle, 'Confirmar pago', '347486946', '2', orderId);
        setTimeout(() => {
            setIdOper("123456")
        }, 1500)
    }, []);

    useEffect(() => {
        if(idOper && idOper !== "-1"){
            openWebSocketRedsysResponse()
        }

    }, [idOper]);

    const [activeSection, setActiveSection] = useState<string>('');

    const threeDSMethodIframeEl = useRef<HTMLIFrameElement>();
    let threeDSMethodFormEl =  useRef<HTMLFormElement>()
    let threeDSMethodDataEl = useRef<HTMLInputElement>();

    let challengeV1IframeEl =  useRef<HTMLIFrameElement>();
    let challengeV1FormEl = useRef<HTMLFormElement> ();
    let challengeV1MDEl = useRef<HTMLInputElement>();
    let challengeV1PaReqEl = useRef<HTMLInputElement> ();
    let challengeV1TermUrlEl = useRef<HTMLInputElement>();

    let challengeV2IframeEl = useRef<HTMLIFrameElement>();;
    let challengeV2FormEl= useRef<HTMLFormElement>();;
    let challengeV2CreqEl= useRef<HTMLInputElement>();;


    function obtain3DSBrowserInfo(): ThreeDSBrowserClientInfo {
        return {
            browserLanguage: navigator.language,
            browserColorDepth: window.screen.colorDepth.toString(),
            browserScreenHeight:  window.screen.height.toString(),
            browserScreenWidth:  window.screen.width.toString(),
            browserTZ: (new Date()).getTimezoneOffset().toString(),
            browserJavaEnabled: navigator.javaEnabled()
        }
    }

    const websocketSelector = useSelector((state : any) => state.websocket);

    useEffect(() => {
        if(websocketSelector){
           // console.log("websocketSelector ",websocketSelector.messages)
            let messageInt = websocketSelector.messages?.filter((message: { content: { topic: string; }; }) =>
                message.content.topic
                &&
                message.content.topic === ("refresh-redsys-pay-" + idOper)
            )[0]

            if(messageInt && messageInt.content && messageInt.content.data){
                message.success("Recibiendo notificación correctamente...")
                console.log("Recibiendo respuesta por websocket... "+messageInt.content.data)
            }
        }

    }, [websocketSelector]);

    function openWebSocketRedsysResponse (){
        console.log("Abriendo websocket.....")
        openWebSocket(dispatch, "redsys-idoper-"+idOper, () => {
            setTimeout(() =>{
                subscribeTo("refresh-redsys-pay-" + idOper)
            }, 1000)
        });
    }

    // @ts-ignore
    return (
        // <div className={isLoginVisible?styles.loginBackground:styles.registerBackground}>
        <>
            {/*<Button onClick={() => setIdOper("asdfasdf")}>*/}
            {/*    Probar pago*/}
            {/*</Button>*/}
            <iframe
                // @ts-ignore
                ref={threeDSMethodIframeEl}
                name="threeDSMethodIframe" />
            <form
                // @ts-ignore
                ref={threeDSMethodFormEl}
                method='post'
                target="threeDSMethodIframe">{/* Name of iframe */}
                <input
                    // @ts-ignore
                    ref={threeDSMethodDataEl}
                    name="threeDSMethodData"
                    type="hidden" />
            </form>

            <div hidden={activeSection !== 'challenge-v1'}>
                {/* Challenge form */}
                <iframe
                    // @ts-ignore
                    ref={challengeV1IframeEl!}
                    name="challengeV1Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV1FormEl!}
                    method='post'
                    target="challengeV1Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV1MDEl}
                        type="hidden"
                        name="MD" />
                    <input
                        // @ts-ignore
                        ref={challengeV1PaReqEl}
                        type="hidden"
                        name="PaReq" />
                    <input
                        // @ts-ignore
                        ref={challengeV1TermUrlEl}
                        type="hidden"
                        name="TermUrl" />
                </form>
            </div>
            <div hidden={activeSection !== 'challenge-v2'}>
                {/* Challenge form */}
                <iframe
                    // @ts-ignore
                    ref={challengeV2IframeEl}
                    name="challengeV2Iframe"
                    style={{height: '100vh', width: '100vw'}} />
                <form
                    // @ts-ignore
                    ref={challengeV2FormEl}
                    method='post'
                    target="challengeV2Iframe">{/* Name of iframe */}
                    <input
                        // @ts-ignore
                        ref={challengeV2CreqEl}
                        type="hidden"
                        name="creq" />
                </form>
            </div>

            <div style={{height: 800}}>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item
                            label={<span className={styles.label}>NÚMERO DE LA TARJETA</span>}
                            name="card-number"
                            style={{ width: '100%' }}
                            // rules={[{ required: true, message:'Please input the card number!'}]}
                        >
                            <div id="card-number" dangerouslySetInnerHTML={{ __html: `<div id="card-number"`}} />

                            {/*<div className={styles.input}  id="card-number"></div>*/}
                            {/*<Input type={'number'} className={styles.input} placeholder={'Número de la tarjeta'} style={{width:'100%'}}/>*/}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item
                            label={<span className={styles.label}>CADUCIDAD TARJETA</span>}
                            name="card-expiration"
                            style={{ width: '100%' }}
                            // rules={[{ required: true, message:'Please input the card number!'}]}
                        >
                            <div  id="card-expiration" dangerouslySetInnerHTML={{ __html: `<div id="card-expiration"`}} />

                            {/*<div className={styles.input} id="card-expiration"></div>*/}
                            {/*<Input type={'number'} className={styles.input} placeholder={'Número de la tarjeta'} style={{width:'100%'}}/>*/}
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item
                            label={<span className={styles.label}>CVV</span>}
                            name="card-expiration"
                            style={{ width: '100%' }}
                            // rules={[{ required: true, message:'Please input the card number!'}]}
                        >
                            <div  id="cvv" dangerouslySetInnerHTML={{ __html: `<div id="cvv"`}} />

                            {/*<div className={styles.input} id="cvv"></div>*/}
                            {/*<Input type={'number'} className={styles.input} placeholder={'Número de la tarjeta'} style={{width:'100%'}}/>*/}
                        </Form.Item>
                    </Col>
                </Row>

                {/*<div className="cardinfo-exp-date">*/}
                {/*    <label className="cardinfo-label" htmlFor="card-expiration">Caducidad</label>*/}
                {/*    <div className='input-wrapper' id="card-expiration"></div>*/}
                {/*</div>*/}
                {/*<div className="cardinfo-cvv">*/}
                {/*    <label className="cardinfo-label" htmlFor="cvv">CVV</label>*/}
                {/*    <div className='input-wrapper' id="cvv"></div>*/}
                {/*</div>*/}
                {/*<div id="boton"></div>*/}
                <Row justify={'center'} style={{paddingTop:30,paddingBottom:30}}>
                    <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                        <div id="boton" dangerouslySetInnerHTML={{ __html: `<div id="boton"`}} />

                        {/*<div id="boton"></div>*/}
                    </Col>
                </Row>
                <input type="hidden" id="token" ></input>
                <input type="hidden" id="errorCode" ></input>
            </div>


        </>
    );
}