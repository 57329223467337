export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
export const MESSAGE_CLEAN = 'MESSAGE_CLEAN'

export const newMessage = message => (
    dispatch => {
        const data = JSON.parse(message.data);
        dispatch({type: MESSAGE_RECEIVED, message: data})
    }
)

export const messageClean = message => (
    dispatch => {
        const data = {};
        dispatch({type: MESSAGE_CLEAN, message: data})
    }
)