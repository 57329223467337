import {Button, Col, Form, Input, message, Row} from "antd";
import styles from "./SendRecoveryEmail.module.css";
import React from "react";
import {
    sendRecoveryEmail
} from "../../solutions/controllers/UserController";
import {useDispatch} from "react-redux";
import arrowImage from "../../assets/arrow.svg";
import {useNavigate} from "react-router-dom";
type FieldType = {
    email?: string;
};
export default function SendRecoveryEmail(){
    const [sendRecoveryEmailForm] = Form.useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    function handleSendRecoveryEmail() {
        sendRecoveryEmailForm.validateFields().then((value)=>{
            sendRecoveryEmail(value.email,(errorMessage:string)=>{
                if (errorMessage===''){
                    message.success('Le hemos enviado un correo para que pueda restablecer su contraseña.',10);
                }else{
                    message.error('No se pudo enviar el correo, revise que ha escrito bien su correo o inténtelo más tarde',10);
                }
            },dispatch)
        }).catch(()=>{
            message.error('Solucione los errores del formulario.',10)
        })
    }
    function goToLoginRegister(){
        window.history.back();
    }

    return (
        <div className={styles.container}>
            <Row align="middle" >
                <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    <img src={arrowImage} alt="Arrow image" onClick={goToLoginRegister} style={{cursor:'pointer'}}/>
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    <h1 className={styles.title} style={{ cursor:'pointer' }} onClick={goToLoginRegister}>
                        VOLVER
                    </h1>
                </Col>

                <Col xs={{span:14,offset:1}} sm={{span:10,offset:1}} md={{span:10,offset:2}} lg={{span:10,offset:2}} xl={{span:10,offset:2}} style={{textAlign:"center"}}>
                    <h1 className={styles.title} >RECUPERAR CONTRASEÑA</h1>
                </Col>
            </Row>
            <Row justify={'center'} style={{marginTop:30}}>
                <h1 style={{color:'grey', fontSize:18}}>Introduce tu dirección de correo electrónico a continuación para restablecer tu contraseña</h1>
            </Row>
            <Form
                form={sendRecoveryEmailForm}
                name="forgotPasswordForm"
                labelCol={{ span: 24}}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
                initialValues={{ remember: true }}
                onFinish={handleSendRecoveryEmail}
                //onFinishFailed={handleLogin}
                autoComplete="off"
            >
                <Row justify={'center'} style={{marginTop:30}}>
                    <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                            name="email"
                            rules={[{ required: true, message: 'Debe introducir un correo electrónico' }, { type: 'email', message: 'Correo electrónico no válido' }]}
                            style={{ width: '100%' }}
                        >
                            <Input className={styles.input} placeholder={''} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} style={{paddingTop:30}}>
                    <Col xs={10} sm={8} md={7} lg={6} xl={4} xxl={3}>
                        <Button type={"default"} htmlType="submit" className={'defaultButton'}>ENVIAR CORREO</Button>
                    </Col>
                </Row>

            </Form>
        </div>
    );
}