import React from "react";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {dispatch} from "../../Controller/redux/websocket/WebSocketController";
import {message} from "antd";
import {TicketImproveInfoDTO} from "../interfaces/DTO/TicketImproveInfoDTO";
import {TicketImproveDTO} from "../interfaces/DTO/TicketImproveDTO";


export function getImproveTickets( auth: any, callback: Function,setTickets:React.Dispatch<TicketImproveInfoDTO>,ticketSoldId:number){
    let url = rootServiceURL() + 'completegraph/ticket/improveTicketData/' + ticketSoldId;
    let rc = new RestClient(dispatch).url(url).token(auth.token);

    // @ts-ignore
    rc.doGet((results: any) => {
        if (results) {
            setTickets(results)
            callback && callback();
        } else {
            message.error('Error al traer información de mejora de la entrada.' + rc.getErrors());
        }
    });
}

export function showInfo(info:string,setInfo:React.Dispatch<string>,setIsInfoVisible:React.Dispatch<boolean>){
    setInfo(info);
    setIsInfoVisible(true);
}

export function calculateAmountToPay(improvePriceTicket:number, actualPriceTicket:number,setAmountToPay:React.Dispatch<number>){
    let amountToPay = 0;
    if (improvePriceTicket && actualPriceTicket){
        amountToPay = improvePriceTicket-actualPriceTicket;
        if (amountToPay<0){
            amountToPay=0;
        }
    }
    setAmountToPay(amountToPay);
}

export function improveTicketPost(ticketSoldId:number,ticketEventId:number,auth:any,callback:Function){
    if (ticketSoldId && ticketEventId){
        const objectToPost:TicketImproveDTO = {
            ticketSoldId:ticketSoldId,
            ticketEventId:ticketEventId

        }
        let url = rootServiceURL() + 'completegraph/ticket/improveTicket';
        let rc = new RestClient(dispatch).url(url).token(auth.token);
        rc.doPost(objectToPost, (results: any) => {
            if(rc.isSuccess()){
                callback && callback();
            }
            else{
                message.error("No se ha podido efectuar la mejora del ticket."+rc.getErrors())
            }
        }, false)

    }else{
        message.error("Error al mejorar la entrada.")
    }
}