import React, {useEffect, useState} from "react";
import { Col, Row, Space} from "antd";
import styles from './MyTickets.module.css';
import locationImage from "../../assets/grey_location.svg";
import {useDispatch, useSelector} from "react-redux";
import {getProfile} from "../../solutions/controllers/UserController";
import {getTicketsByLoggedUser} from "../../solutions/controllers/CollectionController";
import {useNavigate} from "react-router-dom";
import {IUser} from "../../solutions/interfaces/IUser";
import ITicketList from "../../solutions/interfaces/ITicketList";
import {formatDateToString, formatHourToString} from "../../solutions/Utils";
import {imagesPath} from "../../Controller/Settings";
export default function MyTickets() {

    const dispatch = useDispatch();
    const [profile, setProfile] = useState<IUser>();
    const [ticketList, setTicketList] = useState([]);
    const auth = useSelector((state : any) => state.auth);
    const navigate = useNavigate();
    const now = new Date();
    const hours48InMilliseconds = 48 * 60 * 60 * 1000; // 48 horas en milisegundos
    const futureDate = new Date(now.getTime() + hours48InMilliseconds);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (auth && auth.logged) {
            getProfile(auth, setProfile, dispatch);
            getTicketsByLoggedUser(setTicketList, auth, () => {
            }, dispatch)
            console.log(ticketList)
        }
    }, [auth]);


    function handleTicketClick(id: number) {
        navigate(`/ticketDetails/${id}`);
    }

    return (
        <>
            {ticketList ? ticketList.map((ticket: ITicketList) => {
                const isActive = new Date(ticket.date) > futureDate && ticket.active && "RESALED" !== ticket.status;
                return (
                    <Row key={ticket.ticketsoldid} justify={'center'} style={{ marginTop: 10, }} >
                        <div style={{display: "flex", alignItems: "center",justifyContent:"center", position: "relative", width:"100%"}}>
                            {isActive ? "" : <div className={styles.overlay}>{"RESALED" === ticket.status ? "REVENDIDA" : "FINALIZADO"}</div>}
                        </div>
                        <Col xs={23} sm={18} md={16} lg={14} xl={12} xxl={10} className={isActive ? styles.ticketBox : styles.disabledTicketBox} style={isActive ? {cursor: 'pointer'} : {}}
                             onClick={() => isActive ? handleTicketClick(ticket.ticketsoldid) : ""}>
                            <Row justify={'start'}>
                                <Col xs={{span:10,offset:0}} sm={{span:10,offset:0}} md={{span:6,offset:0}} lg={{span:6,offset:0}} xl={{span:6,offset:0}} xxl={{span:6,offset:0}} style={{display: "flex", alignItems: "center"}}>
                                    <img src={imagesPath + ticket.image} alt={'ticket image'}
                                         className={styles.ticketImage}/>
                                </Col>

                                <Col xs={{span: 11, offset: 2}} sm={{span: 11, offset: 2}} md={{span: 17, offset: 1}}
                                     lg={{span: 17, offset: 1}} xl={{span: 17, offset: 1}} xxl={{span: 16, offset: 2}}
                                     style={{marginTop: 10}}>
                                    <Row>
                                        <Col xs={16} sm={16} md={18} lg={18} xl={18} xxl={18}>
                                            <p className={styles.ticketDateHour}> {formatDateToString(ticket.date)}</p>
                                        </Col>
                                        <Col xs={{span: 6, offset: 2}} sm={{span: 6, offset: 2}} md={4} lg={4} xl={4} xxl={4}>
                                            <p className={styles.ticketDateHour}> {formatHourToString(ticket.date)}</p>
                                        </Col>
                                    </Row>
                                    {ticket.status==='RESALE'?(
                                        <Row>
                                            <Col xs={16} sm={11} md={12} lg={13} xl={14} xxl={15}>
                                                <p className={styles.ticketTitle}> {ticket.eventName}</p>
                                            </Col>
                                            <Col xs={{span: 6, offset: 1}} sm={10} md={9} lg={8} xl={7} xxl={6}>
                                                <p className={styles.onResale}> EN REVENTA</p>
                                            </Col>
                                        </Row>
                                    ):<Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketTitle}> {ticket.eventName}</p>
                                        </Col>

                                    </Row>}


                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketTitle}
                                               style={{marginBottom: 0}}> {ticket.ticketName}</p>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                            <p className={styles.ticketDescription}> {ticket.ticketDescription}</p>
                                        </Col>

                                    </Row>
                                    <Row justify={'end'} style={{paddingRight: 10}}>
                                        <Space direction={"horizontal"}>
                                            <img alt="example" src={locationImage} className={styles.boxImage}/>
                                            <p className={styles.townDescription}>{ticket.town.town}</p>
                                        </Space>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                );
            }) : <></>}

        </>
    )
}