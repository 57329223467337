import {
    auth,
    authPaymentMsg,
    challengePaymentMsg,
    create3DSMethodForm,
    initPaymentMsg,
    orderId
} from "./controllers/BuyController";
import {rootServiceURL} from "../Controller";
import {waitForIframeToPostMessage} from "./Utils";
import {AuthBody, ThreeDSBrowserClientInfo} from "./interfaces/server-info";
import {message} from "antd";
import RestClient from "../Controller/rest/RestClient";
import {openWebSocket, subscribeTo} from "../Controller/redux/websocket/WebSocketController";


export function obtain3DSBrowserInfo(): ThreeDSBrowserClientInfo {
    return {
        browserLanguage: navigator.language,
        browserColorDepth: window.screen.colorDepth.toString(),
        browserScreenHeight:  window.screen.height.toString(),
        browserScreenWidth:  window.screen.width.toString(),
        browserTZ: (new Date()).getTimezoneOffset().toString(),
        browserJavaEnabled: navigator.javaEnabled()
    }
}

export function openWebSocketRedsysResponse (idOper: string, dispatch: any){
    // console.log("Abriendo websocket.....")
    openWebSocket(dispatch, "redsys-idoper-"+idOper, () => {
        setTimeout(() =>{
            subscribeTo("refresh-redsys-pay-" + idOper)
        }, 1000)
    });
}

export function destroyBuyProccessMsg(){
    message.destroy(initPaymentMsg);
    message.destroy(authPaymentMsg);
    message.destroy(challengePaymentMsg);
}

export enum SaleType{
    TICKET,
    RENAME,
    IMPROVE
}

export async function processInsitePayment(
    saleType: SaleType,
    preauthResponse: any,
    threeDSMethodFormEl: any,
    threeDSMethodIframeEl: any,
    threeDSMethodDataEl: any,
    challengeV1IframeEl: any,
    challengeV1FormEl: any,
    challengeV1MDEl: any,
    challengeV1PaReqEl: any,
    challengeV1TermUrlEl: any,
    challengeV2FormEl: any,
    challengeV2CreqEl: any,
    idOper: string,
    total: number,
    timerID: any,
    setActiveSection: Function,
    setCheckPayment: Function,
    successBuy: Function,
    errorBuy: Function,
    dispatch: any)
{
    let preauthResult = {};
    if (
        'threeDSMethodURL' in preauthResponse &&
        preauthResponse.threeDSMethodURL != null
    ) {
        // 3DS method required
        preauthResult = {
            // Inform the frontend about the 3DS version used
            threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
            threeDSServerTransID: preauthResponse.threeDSServerTransID,
            // Data for 3DS method
            // The frontend should create an iframe and use a POST request to submit this data
            threeDSMethodForm: create3DSMethodForm(preauthResponse.threeDSServerTransID, preauthResponse.threeDSMethodURL,
                `${rootServiceURL() + "completegraph/payment/threeDSNotification"}/${orderId}`)
        };
    } else {
        preauthResult = {
            // Inform the frontend about the 3DS version used
            threeDSProtocolVersion: preauthResponse.threeDSProtocolVersion,
            threeDSServerTransID: preauthResponse.threeDSServerTransID,
        };
    }

    if ('threeDSMethodForm' in preauthResult && preauthResult.threeDSMethodForm) {
        if (
            !threeDSMethodFormEl ||
            !threeDSMethodIframeEl ||
            !threeDSMethodDataEl
        ) {
            console.error("Faltan iframees para 3DS");
            return;
        }

        // Serialize the parameters of the body into JSON and encode them with base64url
        // Then place each encoded parameter in their respective input element
        // @ts-ignore
        threeDSMethodDataEl!.current.value = preauthResult.threeDSMethodForm.body.threeDSMethodData;

        // Fill out the form information and submit.
        // @ts-ignore
        threeDSMethodFormEl!.current.action = preauthResult.threeDSMethodForm.url;
        // @ts-ignore
        threeDSMethodFormEl!.current.submit();

        // @ts-ignore
        await waitForIframeToPostMessage({
            // @ts-ignore
            iframe: threeDSMethodIframeEl.current,
            condition: (event: MessageEvent) => {
                return typeof event.data !== 'object' &&
                    event.data != null &&
                    'finished' in event.data &&
                    event.data.finished;
            },
            // Wait until 3DS method completes or a 10 seconds timeout
            timeout: 5 * 1000
        });

    }

    const clientInfo = obtain3DSBrowserInfo();

    const authBody: AuthBody = {
        transactionData: (
            //@ts-ignore
            preauthResult.threeDSProtocolVersion === '2.2.0' || preauthResult.threeDSProtocolVersion === '2.1.0'
                ? {
                    type: '3ds-v2',
                    //@ts-ignore
                    threeDSServerTransID: preauthResult.threeDSServerTransID,
                    //@ts-ignore
                    threeDSProtocolVersion: preauthResult.threeDSProtocolVersion
                }
                : {
                    type: 'regular',
                    orderId,
                    paymentMethod: {
                        type: 'id-oper',
                        idOper
                    },
                    //@ts-ignore
                    threeDSProtocolVersion: preauthResult.threeDSProtocolVersion,
                    threeDSServerTransID: "",
                }
        ),
        clientInfo
    };

    message.loading({
        key: authPaymentMsg,
        onClick: (() => message.destroy(authPaymentMsg)),
        content: 'Solicitando autorización bancaria...',
        duration: 10,
    })


    auth(saleType, idOper, orderId!, total, authBody, async (authResponse: any) => {
        console.log("AuthResponse ", authResponse);
        if (authResponse) {
            if (authResponse.status === "DONE") {
                successBuy();
            } else if (authResponse.status === "CHALLENGE") {
                let iframe: HTMLIFrameElement;

                if (authResponse.fromVersion === "1") {
                    if (
                        !challengeV1IframeEl ||
                        !challengeV1FormEl ||
                        !challengeV1MDEl ||
                        !challengeV1PaReqEl ||
                        !challengeV1TermUrlEl
                    ) {
                        throw new Error('Form for 3DS v1 challenge does not exist');
                    }

                    //@ts-ignore
                    iframe = challengeV1IframeEl.current;

                    //@ts-ignore
                    challengeV1MDEl.current.value = authResponse.MD;

                    //@ts-ignore
                    challengeV1PaReqEl.current.value = authResponse.paReq;

                    //@ts-ignore
                    challengeV1TermUrlEl.current.value = authResponse.TermUrl;

                    //@ts-ignore
                    challengeV1FormEl.current.action = authResponse.acsURL;

                    //@ts-ignore
                    challengeV1FormEl.current.submit();

                    setActiveSection('challenge-v1');
                    setCheckPayment(true)
                } else {
                    if (
                        //@ts-ignore
                        !challengeV2IframeEl ||
                        !challengeV2FormEl ||
                        !challengeV2CreqEl
                    ) {
                        throw new Error('Form for 3DS v2 challenge does not exist');
                    }

                    //@ts-ignore
                    iframe = challengeV2IframeEl!.current;

                    //@ts-ignore
                    challengeV2CreqEl!.current.value = authResponse.creq;

                    //@ts-ignore
                    challengeV2FormEl!.current.action = authResponse.acsURL;

                    //@ts-ignore
                    challengeV2FormEl!.current.submit();

                    setActiveSection("challenge-v2")
                    setCheckPayment(true)
                }


                destroyBuyProccessMsg();
                message.loading({
                    key: challengePaymentMsg,
                    onClick: (() => message.destroy(challengePaymentMsg)),
                    content: 'Verificando estado de pago...(haga click para cerrar este mensaje)',
                    duration: 0,
                })

                const checkPay = () => {
                    console.log("Check payment status ")
                    let url = rootServiceURL() + "fullgraph/payment/getPaymentStatus/" + idOper + "/" + orderId;

                    let rc = new RestClient(dispatch).url(url);
                    rc.doGet((results: any) => {
                        if (results) {
                            if (results.finish != null && results.finish && results.success != null && results.success === true) {
                                // @ts-ignore
                                successBuy();
                            } else if (results.finish != null && results.finish && results.success != null && results.success === false) {
                                console.log("Payment error " + results.error);
                                // errorBuy("Error durante el pago "+results.error);
                                errorBuy("Error durante el pago, inicie el proceso de compra de nuevo o contacte con el servicio técnico");

                            } else {
                                timerID = setTimeout(() => {
                                    checkPay();
                                }, 5000);
                            }
                        } else {
                            console.error("Error check payment status", rc.getErrors())
                        }
                    })
                }

                timerID = setTimeout(() => {
                    checkPay();
                }, 15000);


                // After the payment finishes, we let them see the postchallenge page for
                // a few seconds before redirecting to our frontend page
                await new Promise(resolve => setTimeout(resolve, 5 * 1000));
            } else {

            }
        } else {
            errorBuy("Error durante la autorización de su tarjeta, vuelva a iniciar el proceso y revise los datos.")
        }

    }, dispatch);
}