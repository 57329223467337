
export function calculateAmountToPay(price:number,setPrice:React.Dispatch<number>){
    if (price<0){
        setPrice(0);
    }else{
        setPrice(price);
    }
}
export function manageIsRenameFree(price:number,tax:number,setIsFree:React.Dispatch<boolean>){
    if (price>0 && tax >0){
        setIsFree(false);
    }else{
        setIsFree(true);
    }
}

