import React from "react";
import {IUser} from "../interfaces/IUser";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {UpdateUserDTO} from "../interfaces/DTO/UpdateUserDTO";
import {UpdateUserPasswordDTO} from "../interfaces/DTO/UpdateUserPasswordDTO";
import {message} from "antd";
import { auth } from "./BuyController";

const profile = {
    firstName:'Juan Antonio',
    lastName:'Perez Gómez',
    email:'juan@gmail.com',
    birthDate:new Date(),
    phoneNumber:'678987653',
    zipcode:'43213',
    profilePhoto: 'https://weremote.net/wp-content/uploads/2022/08/mujer-sonriente-apunta-arriba.jpg',
    genre:'male',
};
export function getProfile(auth: any, setProfile:React.Dispatch<React.SetStateAction<IUser | undefined>>, dispatch: any) {
    let url = rootServiceURL() + "completegraph/users/getUserFromToken";
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doGet((results: IUser) => {
        if(results){
            //@ts-ignore
            setProfile(results)
        }
        else{
            //@ts-ignore
            setProfile(null)
        }
    })
}


export function registerUser(user: any, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/registerUser";
    let rc = new RestClient(dispatch).url(url);
    rc.doPost(user, (result: any) => {
        if(result){
            callback && callback("")
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}
export function updateUser(auth:any,user: UpdateUserDTO, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/updateUser";
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doPost(user, (result: any) => {
        if(result){
            callback && callback("");
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}
export function updatePassword(auth:any,userPassword: UpdateUserPasswordDTO, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/resetPasswordLoginUser";
    let rc = new RestClient(dispatch).url(url).token(auth.token);
    rc.doPost(userPassword, (result: any) => {
        if(result){
            callback && callback("");
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}


export function openCreateAccount(setIsCreateAccountVisible:React.Dispatch<boolean>,setIsLoginVisible:React.Dispatch<boolean>) {
    setIsCreateAccountVisible(true);
    setIsLoginVisible(false);
}

export function openLogin(setIsCreateAccountVisible:React.Dispatch<boolean>,setIsLoginVisible:React.Dispatch<boolean>){
    setIsLoginVisible(true);
    setIsCreateAccountVisible(false);
}

export function sendRecoveryEmail(email: string, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/forgotPassword";
    const headers = {
        'Content-Type': 'text/plain',
    };
    let rc = new RestClient(dispatch).url(url).contentType('text/plain');

    rc.doPost(email, (result: any) => {
        if(result){
            callback && callback("");
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}
export function resetPassword(password:string, token: string, callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/resetPassword";
    let rc = new RestClient(dispatch).url(url);
    rc.doPost({password: password, token: token}, (result: any) => {
        if(result){
            callback && callback("");
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}
export function getFirstBackgroundImage (setImage:React.Dispatch<string>)  {
    const index = 1;
    const image = require(`../../assets/profilePictures/fondo${index}.jpg`);
    setImage(image);
};
export function getProfileBackgroundImage(profileImage:string,setImage:React.Dispatch<string>){
    const image = require(`../../assets/profilePictures/fondo${profileImage}.jpg`);
    setImage(image);
}

export function getFirstBackgorundImage (setImage:React.Dispatch<string>) {
    const randomIndex = 1;
    const randomImage = require(`../../assets/profilePictures/fondo${randomIndex}.jpg`);
    setImage(randomImage);
}
export function updateProfileBackground(userId:number,photoId:number,callback: Function, dispatch: any){
    let url = rootServiceURL() + "completegraph/users/updateProfileBackground";
    let rc = new RestClient(dispatch).url(url);
    rc.doPost({userId:userId,photoId:photoId},(result: any) => {
        if(result){
            callback && callback("");
        }
        else{
            callback && callback(rc.getErrors());
        }
    }, false);
}