import moment from "moment";
import WebSocket from 'sockette';
import {newMessage, messageClean} from "./actions";
import {getWebSocketURL} from "../../index";

export let webSocket;
export let webSocketKpi;
export let dispatch;
export let connecting = true;
export let debugging = true;
let token;
const subscriptions = {};

export const openWebSocket = (dispatchFunction, newToken, callback) => {
    debugging && console.log("Opening websocket idOper");
    token = newToken;
    dispatch = dispatchFunction;
    webSocket = new WebSocket(getWebSocketURL(), {
        timeout: 10e3,
        onopen: e => handleWebSocketOpened(e, callback),
        onmessage: e => handleWebSocketMessage(e),
        onreconnect: e => handleWebSocketReconnect(e),
        onclose: e => handleWebSocketClosed(e),
        onerror: e => handleWebSocketError(e)
    });
}

export const openWebSocketKpi = (dispatchFunction, newToken) => {
    // debugging && console.log("Opening websocket");
    // token = newToken;
    // dispatch = dispatchFunction;
    // webSocketKpi = new WebSocket(getKpiWebSocketURL(), {
    //     timeout: 10e3,
    //     onopen: e => handleWebSocketOpenedKpi(e),
    //     onmessage: e => handleWebSocketMessage(e),
    //     onreconnect: e => handleWebSocketReconnect(e),
    //     onclose: e => handleWebSocketClosedKpi(e),
    //     onerror: e => handleWebSocketError(e)
    // });
}

const handleWebSocketOpened = (event, callback) => {
    connecting = false;
    debugging && console.log("Websocket opened event ",event);
    tryWebSocketAuthentication();
    debugging && console.log("Resuscribing to " + Object.keys(subscriptions).join(", "));
    Object.keys(subscriptions).forEach(k => subscribeTo(k));
    callback && callback();
}

const handleWebSocketOpenedKpi = () => {
    connecting = false;
    debugging && console.log("Websocket kpi opened");
    tryWebSocketAuthenticationKpi();
    debugging && console.log("Resuscribing to " + Object.keys(subscriptions).join(", "));
    Object.keys(subscriptions).forEach(k => subscribeTo(k));
}

const handleWebSocketReconnect = () => {

}

const tryWebSocketAuthentication = () => {
    debugging && console.log("Authenticating");
    if(!connecting){
        webSocket.json({
            messageType: 'MESSAGE_TYPE_COMMAND',
            generated: moment().unix() * 1000,
            content: {token: token, commandType: 'COMMAND_TYPE_AUTHENTICATE'}
        });
    }
}

const tryWebSocketAuthenticationKpi = () => {
    debugging && console.log("Authenticating kpi");
    webSocketKpi.json({
        messageType: 'MESSAGE_TYPE_COMMAND',
        generated: moment().unix() * 1000,
        content: {token: token, commandType: 'COMMAND_TYPE_AUTHENTICATE'}
    });
}

export const subscribeTo = topic => {
    debugging && console.log("Subscribing to " + topic);
    const subscription = {
        messageType: 'MESSAGE_TYPE_COMMAND',
        generated: moment().unix() * 1000,
        content: {token: token, topic: topic, commandType: 'COMMAND_TYPE_SUBSCRIBE'}
    };
    subscriptions[topic] = subscription;
    webSocket.json(subscription);
}

export const subscribeToKpi = topic => {
    debugging && console.log("Subscribing to kpi " + topic);
    const subscription = {
        messageType: 'MESSAGE_TYPE_COMMAND',
        generated: moment().unix() * 1000,
        content: {token: token, topic: topic, commandType: 'COMMAND_TYPE_SUBSCRIBE'}
    };
    subscriptions[topic] = subscription;
    webSocketKpi.json(subscription);
}

export const unsubscribe = topic => {
    debugging && console.log("Unsubscribing " + topic);
    webSocket.json({
        messageType: 'MESSAGE_TYPE_COMMAND',
        generated: moment().unix() * 1000,
        content: {token: token, topic: topic, commandType: 'COMMAND_TYPE_UNSUBSCRIBE'}
    })
    delete subscriptions[topic];
}

export const unsubscribeKpi = topic => {
    debugging && console.log("Unsubscribing kpi " + topic);
    webSocketKpi.json({
        messageType: 'MESSAGE_TYPE_COMMAND',
        generated: moment().unix() * 1000,
        content: {token: token, topic: topic, commandType: 'COMMAND_TYPE_UNSUBSCRIBE'}
    })
    delete subscriptions[topic];
}

const handleWebSocketClosed = event => {
    debugging && console.log("Web socket closed", event);
    if (!connecting) {
        connecting = true;
        setTimeout(webSocket.reconnect, 10e3);
    }
}

const handleWebSocketClosedKpi = event => {
    debugging && console.log("Web socket closed kpi", event);
    if (!connecting) {
        connecting = true;
        setTimeout(webSocketKpi.reconnect, 10e3);
    }
}

const handleWebSocketError = event => {
    debugging && console.log("Error: ", event);
}

const handleWebSocketMessage = event => {
    debugging && console.log("New message", event);
    debugging && console.log("Subcriptions.. ", subscriptions);

    dispatch(newMessage(event));
}

export const cleanMessagesWebsocket = (dispatch) =>{
    dispatch(messageClean())
}