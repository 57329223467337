import React, {useEffect, useState} from "react";
import {Row} from "antd";

import Buy from "./Buy";
import RedsysForm from "./RedsysForm";
import {useLocation} from "react-router-dom";


export default function BuyGlobal(){
    const [isFormValid, setFormValid] = useState(false)
    const [isPaymentInsite, setPaymentInsite] = useState(false)

    const { state } = useLocation(); // state is any or unknown

    useEffect(() => {
        if(state?.paymentDetails?.insite){
            setPaymentInsite(true)
        }
    }, [state]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Row justify={"center"}>
                <Buy setFormValidated={setFormValid} setPaymentInsite={setPaymentInsite}/>
            </Row>

            {isPaymentInsite &&
                <div hidden={!isFormValid} style={{height: "auto !important"}}>
                    <Row justify={"center"}>
                        <RedsysForm callback={(idOper: string, orderId: string) =>
                            console.log("Recibiendo callback de redsys idOper "+idOper+", orderId "+orderId)}></RedsysForm>
                    </Row>
                </div>
            }
        </>
    );
}