import JSOG from 'jsog'
import 'whatwg-fetch'
import {sessionExpiredAction} from '../redux/auth/actions'
import {pendingRequest} from '../redux/desktop/actions'
import {requestFinished, requestStarted} from '../redux/desktop/actions'
// import {error} from 'react-notification-system-redux';

import * as styles from '../forms/formStyles'
import {deepRemoveField} from "../common/Utils";

class RestClient {
    static _unauthenticated = false;

    constructor(dispatch, retryUnauthenticatedRequests = true, isLoginRequest = false) {
        this._dispatch = dispatch;
        this._server = '';
        this._token = '';
        this._ignoreNotFound = false;
        this._pathElements = [];
        this._result = {};
        this._contentType = "application/json";
        this._success = false;
        this._errors = '';
        this._url = undefined;
        this._isLoginRequest = isLoginRequest;
        this._retryUnauthenticatedRequests = retryUnauthenticatedRequests;
    }

    url(url) {
        this._url = url;
        return this;
    }

    contentType(contentType) {
        this._contentType = contentType;
        return this;
    }

    server(server) {
        this._server = server;
        return this;
    }

    target(target) {
        this._pathElements.push(target);
        return this;
    }

    token(token) {
        this._token = token;
        return this;
    }

    ignoreNotFound(ignoreNotFound) {
        this._ignoreNotFound = ignoreNotFound;
        return this;
    }

    getResult() {
        return this._result;
    }

    getErrors() {
        return this._errors;
    }

    getURL() {
        return this._url;
    }

    isSuccess() {
        return this._success;
    }

    doGet(callback) {
        let request = this.buildRequest('GET');
        this.processRequest(request, callback);
    }

    doPost(entity, callback, jsogEncoded = true, returnUpdatedObject = false) {
        deepRemoveField(entity, "__jsogObjectId");
        let stringifiedEntity = this.getStringifiedEntity(jsogEncoded, entity);
        let request = this.buildRequest('POST', stringifiedEntity);
        this.processRequest(request, callback, returnUpdatedObject);
    }

    doPostFile(contents, callback) {
        this._contentType = "application/octet-stream";
        let request = this.buildRequest('POST', contents);
        this.processRequest(request, callback);
    }

    doPut(entity, callback, jsogEncoded = true) {
        deepRemoveField(entity, "__jsogObjectId");
        let stringifiedEntity = this.getStringifiedEntity(jsogEncoded, entity);
        let request = this.buildRequest('PUT', stringifiedEntity);
        this.processRequest(request, callback);
    }

    getStringifiedEntity(jsogEncoded, entity) {
        let entityToSend = jsogEncoded ? JSOG.encode(entity) : entity;
        let stringifiedEntity = JSON.stringify(entityToSend)
        return stringifiedEntity;
    }

    doDelete(callback) {
        let request = this.buildRequest('DELETE');
        this.processRequest(request, callback);
    }

    buildRequest(method, body) {
        if (this._url === undefined) {
            this._url = "http://" + this._server + this._pathElements.join("/");
        }

        let headers = new Headers({
            'Accept': 'application/json, application/xml, application/pdf, application/vnd.ms-excel, application/octet-stream, application/zip, text/plain',
            'Content-Type': this._contentType
        });

        if (this._token !== '') {
            headers.set("token", this._token);
        }

        let request = {
            method: method,
            headers: headers
        }

        if (body !== undefined) {
            request['body'] = body;
        }
        return request;
    }

    /****
     * TODO: Should we get an updated object from server after POST or PUT?...
     */
    processRequest(request, callback, returnUpdatedObject = false) {
        let self = this;

        if (RestClient._unauthenticated && !self._isLoginRequest && self._retryUnauthenticatedRequests) {
            self._dispatch && self._dispatch(pendingRequest(self._url, request, callback));
        }

        if (!RestClient._unauthenticated || self._isLoginRequest) {
            this._dispatch && this._dispatch(requestStarted());
            console.log("Url: ",this.getURL())
            fetch(this._url, request)
                .then(response => {
                    self._dispatch && self._dispatch(requestFinished())
                    self._success = true;
                    if (response.status == 200) {
                        const contentType = response.headers.get('Content-type');
                        if (contentType && (contentType.toLowerCase() == "application/json")) {
                            const jsonToReturn = response.json();
                            return jsonToReturn;
                        }
                        else if(contentType && (contentType.toLowerCase() == "text/plain")){
                            return response.text();
                        }
                        else {
                            callback && callback(response)
                        }
                    } else if (response.status == 201) {
                        const contentType = response.headers.get('Content-type');
                        if (returnUpdatedObject && contentType && (contentType.toLowerCase() == "application/json")) {
                            const jsonToReturn = response.json();
                            return jsonToReturn;
                        }
                        const newResourceLocation = response.headers.get('Location');
                        callback && callback(newResourceLocation);
                    } else if (response.status == 204) {
                        callback && callback()
                    } else if (response.status == 401) {
                        RestClient._unauthenticated = true;
                        self._success = false;
                        self._dispatch && self._dispatch(pendingRequest(self._url, request, callback));
                        self._dispatch && self._dispatch(sessionExpiredAction());
                        this._errors = "Sin autenticación";
                        callback && callback(null);
                    } else if (response.status == 404) {
                        this._success = false;
                        this._errors = "No encontrado";
                        if (!this._ignoreNotFound) {
                            self.reportError(this._errors);
                        }
                        callback && callback(null);
                    } else {
                        this._success = false;
                        // this._errors = "Estado " + response.status;
                        this._errors ="";
                        response.text().then(body => {
                            this._errors += " " + body;
                            self.reportError(this._errors);
                            callback && callback()
                        }).catch(error => {
                            self.reportError(this._errors);
                            callback && callback()
                        });

                    }
                })
                .then((json) => {
                    if (json != null) {
                        callback && callback(JSOG.decode(json))
                    }
                })
                .catch(error => {
                    console.error("Error url" + self.getURL(), error)
                    self.reportError("Error: " + error, error);
                    callback && callback(null);
                    throw new Error(error)
                })
        }
    }

    reportError(textToReport, errorObject = null) {
        if (errorObject != null) console.log(errorObject.stack +" "+this.getURL())
        this._errors = textToReport;
        // this._dispatch && this._dispatch(error(styles.toastOptions("Error", textToReport)))
        this._dispatch && this._dispatch(requestFinished())
    }
}

export default RestClient;