import {ICategory} from "../interfaces/ICategory";
import {IEvent} from "../interfaces/IEvent";
import React, {Dispatch} from "react";
import RestClient from "../../Controller/rest/RestClient";
import {rootServiceURL} from "../../Controller";
import {AnyAction} from "redux";
import {ITown} from "../interfaces/ITown";
import {ZIndexUtils} from "primereact/utils";
import set = ZIndexUtils.set;
import {NewsDTO} from "../interfaces/DTO/NewsDTO";
import {INews} from "../interfaces/INews";

// @ts-nocheck
export function getEventAndCategories(setOriginalEventData: React.Dispatch<React.SetStateAction<IEvent[]>>,
                                      setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>, dispatch: Dispatch<AnyAction>) {
    // @ts-ignore

    let url = rootServiceURL() + "completegraph/event/eventtype";

    let rc = new RestClient(dispatch).url(url);
    rc.doGet((results: ICategory[]) => {
        if (results) {
            setCategories(results)
        } else {
            console.error("Error cargando tipo de eventos", rc.isSuccess(), rc.getErrors())
            setCategories([])
        }
    })

    url = rootServiceURL() + "completegraph/event/getResumedEvents";
    rc = new RestClient(dispatch).url(url);
    // @ts-ignore
    rc.doGet(r => {
        if (r) {
            const recordTransformed = r.map((event: any) => {
                return ({
                    id: event.eventId,
                    title: event.title,
                    price: event.minPrice,
                    image: event.image,
                    category: event.type,
                    fromDate: event.date,
                    town: event.town,
                    finished: false,
                })
            });
            setOriginalEventData(recordTransformed)
        } else {
            setOriginalEventData([])
            console.error("Error cargando eventos", rc.getErrors())
        }
    });
}

export function getPromoterEventAndCategories(setOriginalEventData: React.Dispatch<React.SetStateAction<IEvent[]>>,
                                              setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>, dispatch: Dispatch<AnyAction>, promoterId:string | undefined) {
    // @ts-ignore

    let url = rootServiceURL() + "completegraph/event/eventtype";

    let rc = new RestClient(dispatch).url(url);
    rc.doGet((results: ICategory[]) => {
        if (results) {
            setCategories(results)
        } else {
            console.error("Error cargando tipo de eventos", rc.isSuccess(), rc.getErrors())
            setCategories([])
        }
    })

    url = rootServiceURL() + "completegraph/event/getPromoterResumedEvents/" + promoterId;
    rc = new RestClient(dispatch).url(url);
    // @ts-ignore
    rc.doGet(r => {
        console.log(r)
        if (r) {
            const recordTransformed = r.map((event: any) => {
                return ({
                    id: event.eventId,
                    title: event.title,
                    price: event.minPrice,
                    image: event.image,
                    category: event.type,
                    fromDate: event.date,
                    town: event.town,
                    finished: false,
                })
            });
            setOriginalEventData(recordTransformed)
        } else {
            setOriginalEventData([])
            console.error("Error cargando eventos", rc.getErrors())
        }
    });
}

export function getPublicRelationsEventsAndCategories (setOriginalEventData: React.Dispatch<React.SetStateAction<IEvent[]>>,
                                                       setCategories: React.Dispatch<React.SetStateAction<ICategory[]>>,
                                                       dispatch: Dispatch<AnyAction>, publicRelationsUserId:string | undefined,) {
    // @ts-ignore

    let url = rootServiceURL() + "completegraph/event/eventtype";

    let rc = new RestClient(dispatch).url(url);
    rc.doGet((results: ICategory[]) => {
        if (results) {
            setCategories(results)
        } else {
            console.error("Error cargando tipo de eventos", rc.isSuccess(), rc.getErrors())
            setCategories([])
        }
    })

    url = rootServiceURL() + "completegraph/event/getPublicRelationsResumedEvents/" + publicRelationsUserId;
    rc = new RestClient(dispatch).url(url);
    // @ts-ignore
    rc.doGet(r => {
        console.log(r)
        if (r) {
            const recordTransformed = r.map((event: any) => {
                return ({
                    id: event.eventId,
                    title: event.title,
                    price: event.minPrice,
                    image: event.image,
                    category: event.type,
                    fromDate: event.date,
                    town: event.town,
                    finished: false,
                })
            });
            setOriginalEventData(recordTransformed)
        } else {
            setOriginalEventData([])
            console.error("Error cargando eventos", rc.getErrors())
        }
    });
}

export function getCategories(setCategories:React.Dispatch<React.SetStateAction<ICategory[]>>, dispatch: Dispatch<AnyAction>){

    let url = rootServiceURL() + "completegraph/event/eventtype";
    let rc = new RestClient(dispatch).url(url).token("thisIsAValidTokenButDontUseIt");
    rc.doGet((results: React.SetStateAction<ICategory[]>) => {
        if(results){
            setCategories(results)
        }
        else{
            setCategories([])
        }
    })

}

export function getFilterTowns(setTowns: React.Dispatch<ITown[]>, dispatch: any){
    let url = rootServiceURL() + "completegraph/event/town";

    let rc = new RestClient(dispatch).url(url).token("thisIsAValidTokenButDontUseIt");
    rc.doGet((results: ITown[]) => {
        if(results){
            setTowns(results)
        }
        else{
            setTowns([])
        }
    })
}
export function getNews(dto:NewsDTO,setNews:React.Dispatch<INews>, dispatch: any){
    let url = rootServiceURL() + "completegraph/news/getNews";
    let rc = new RestClient(dispatch).url(url).token("thisIsAValidTokenButDontUseIt");
    rc.doPost(dto,(results: INews) => {
        if(results){
            setNews(results);
        }
    },false);
}