import React from 'react';
import {Seat} from "./model/Seat";
import {
    CloseCircleOutlined
} from '@ant-design/icons';

export interface SeatInfoRowProps {
    seat: Seat,
    isTotal?: boolean,
    onDelete?: (seat: Seat, element?: Element) => void
}
const SeatInfoRow = ({seat, isTotal, onDelete}: SeatInfoRowProps) => {
    const seatInfo = isTotal ? seat.seat : `Asiento ${seat.seat}` + (seat.handicapped ? " (M)" : "")
    const seatDescription = isTotal ? seat.sector : `${seat.sector}, Fila ${seat.row}`;
    const priceLabel = `${seat.price.toFixed(2)}€`;

    return (
        <tr key={seat.id} className={`seat-row ${isTotal ? 'total' : ''}`}>
            <td className="seat-cell">
                <p className={`seat-seat ${isTotal ? 'total-label' : ''}`}>{seatInfo}</p>
                <p className={`seat-description ${isTotal ? 'total-details' : ''}`}>{seatDescription}</p>
                {seat.handicapped && (
                    <p className="handicapped-label">Accesible</p>
                )}
            </td>
            <td className={`price-cell ${isTotal ? 'total' : ''}`}>
                <p className={`price-label ${isTotal ? 'total-price' : ''}`}>
                    {priceLabel}
                </p>
            </td>
            <td className={'action-cell'}>
                { ! isTotal && <p className={'price-label'}>
                    <CloseCircleOutlined color={'yellow'} className={'action-icon'} onClick={() => onDelete && onDelete(seat)} />
                </p>  }
            </td>
        </tr>
    );
};

export default SeatInfoRow;
