import React, {useEffect, useState} from "react";
import "./styles.css";

import {Seat} from "./model/Seat";
import {Button, Col, Flex, message, Row} from "antd";
import SeatInfoRow from "./SeatInfoRow";
import {rootServiceURL} from "../../Controller";
import InlineSVG from 'react-inlinesvg';
import _ from "lodash";
import {checkSeatRules, getSeatByElement, getSeatsByRow, getSeatsBySector} from "./action";

export type SeatMapProps = {
    eventdate?: number;
    onSelectSeat?: (seat: Seat) => void
    onRemoveSeat?: (seat: Seat) => void
    onSeatReservationChanged?: (seats: Seat[]) => void
    onCloseSector?: (seatsBySector :any) => void
    maxSeatsSelection: number
    fromResale: boolean
};


export function SeatReservation(props: SeatMapProps) {
    const [url, setUrl] = useState<string | undefined>(undefined);

    const [selectedSector, setSelectedSector] = useState<string>()
    const [selectedSeats, setSelectedSeats] = useState<Seat[]>([]);

    const RESERVATON_URL = rootServiceURL() + "minimalgraph/reservation/" + (props.fromResale ? "getZonesResale/" : "getZones/");

    useEffect(() => {
        return () => disableListeners();
    }, []);


    useEffect(() => {
        if (selectedSector) {
            loadSector(selectedSector)
        } else {
            setUrl(RESERVATON_URL + props.eventdate)
        }
    }, [selectedSector]);

    useEffect(() => {
        props.onSeatReservationChanged && props.onSeatReservationChanged(selectedSeats)
        applyClassesToSeats();
        // checkSeatRules()
    }, [selectedSeats])

    function handleSeatClick(event: Event) {
        toggleSeatSelection(event.currentTarget as Element);
    }

    function handleSectorClick(event: Event) {
        let element = event.currentTarget as Element;
        //@ts-ignore
        setSelectedSector(element.getAttribute("data-code"));
    }

    function getTotalRow(): Seat {
        return new Seat({
            seat: "TOTAL",
            sector: selectedSeats.length + " asientos seleccionados",
            price: selectedSeats.reduce((p, c, i) => p + c.price, 0.0)
        })
    }

    function enableListeners() {
        let sectors = Array.from(document.querySelectorAll(".sector")).filter(sector => {
            if (sector) {
                let classAttr = sector.getAttribute("data-code");
                return classAttr && classAttr.length > 0
            }
            return false
        });
        sectors.forEach((sector) => {
            sector.addEventListener("click", handleSectorClick);
        });
        let seats = Array.from(document.querySelectorAll(".seat")).filter(seat => {
            if (seat) {
                let classAttr = seat.getAttribute("class");
                return classAttr && !classAttr.includes("busy") && !classAttr.includes("disabled")
            }
            return false
        });
        seats.forEach((seat) => {
            seat.addEventListener("click", handleSeatClick);
        });
    }

    function disableListeners() {
        let elements = document.querySelectorAll("[id^=sector]");
        elements.forEach((anElement) => {
            anElement.removeEventListener("click", handleSectorClick);
        });
        elements = document.querySelectorAll(".seat");
        elements.forEach((anElement) => {
            anElement.removeEventListener("click", handleSeatClick);
        });
    }

    function svgLoaded() {
        applyClassesToSeats();
        disableListeners();
        enableListeners();
    }

    function loadSector(id: string | null) {
        const url = rootServiceURL() + ("minimalgraph/reservation/" + (props.fromResale ? "svgResale/" : "svg/") + props.eventdate + "/" + id);
        setUrl(url);
    }

    const toggleSeat = (seat: Seat, element?: Element) => {
        setSelectedSeats((prevSelectedSeats) => {
            const seatIndex = prevSelectedSeats.findIndex((s) => s.id === seat.id);
            if (seatIndex !== -1) {
                const updatedSeats = [...prevSelectedSeats];
                updatedSeats.splice(seatIndex, 1);
                props.onRemoveSeat && props.onRemoveSeat([...prevSelectedSeats][seatIndex])
                element && element.classList.toggle("selected");
                return [...updatedSeats]
            } else {
                if (prevSelectedSeats && prevSelectedSeats.length >= props.maxSeatsSelection) {
                    message.warning("No puede seleccionar más de " + props.maxSeatsSelection + " asientos")
                    return [...prevSelectedSeats];
                } else {
                    props.onSelectSeat && props.onSelectSeat(seat)
                    element && element.classList.toggle("selected");
                    return [...prevSelectedSeats, seat];
                }
            }
        });
    }

    const applyClassesToSeats = () => {
        const groups = document.querySelectorAll(".seat");
        groups.forEach((group) => {
            const seatId = group.getAttribute("data-id");
            if (seatId) {
                const isSelected = selectedSeats.some((seat) => seat.id === parseInt(seatId, 10));
                group.classList.toggle("selected", isSelected);
            }
        });
    };

    function toggleSeatSelection(element: Element) {
        let seat = getSeatByElement(element);
        toggleSeat(seat, element)
    }

    function getSvgComponent() {
        //@ts-ignore
        return <InlineSVG
            style={{maxWidth: '100%'}}
            className={"svgComponent"}
            src={url!}
            loader={<div className={"loader-container"}><span className={"svg-loader"}>Cargando...</span></div>}
            cacheRequests={false}
            onLoad={(src, isCached) => svgLoaded()}
        />
    }

    return (
        <>
            {selectedSector &&  <Row justify="center" align="middle" style={{marginTop: 15}}>
                <Col span={22}>
                        <Button type={'default'} className={'defaultButton'} style={{fontWeight:'bold'}} onClick={() => {
                            if(checkSeatRules(selectedSeats)){
                                setSelectedSector(undefined)
                                props.onCloseSector && props.onCloseSector(getSeatsBySector(""))
                            }}}>VOLVER AL PLANO
                        </Button>
                </Col>
            </Row> }

            <Row justify="center" align="middle" style={{marginTop: 5}}>
                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    {getSvgComponent()}
                </Col>
            </Row>

            <Row style={{marginTop: 30}}>
                <Col span={24}>
                    <table className={"table"} id={"tableTickets"}>
                        <SeatInfoRow isTotal={true} seat={getTotalRow()}/>
                        {selectedSeats.map(seat => <SeatInfoRow onDelete={(seat, element) => toggleSeat(seat, undefined)} seat={seat}/>)}
                    </table>
                </Col>
            </Row>
        </>
    );
}
