import React from "react";
import {ITax, TaxType} from "../interfaces/ITax";
const tax:ITax ={
    taxid:1,
    type:TaxType.PERCENTAGE,
    amount:20,
}
export function calculateMoneyToReceiveOrPay(restrictedNum: number,setTaxes:React.Dispatch<React.SetStateAction<string>>,
                               setTotal:React.Dispatch<React.SetStateAction<number>>,isBuy:boolean) {
    let taxesApplied = 0;
    if (tax.type===TaxType.PERCENTAGE){
        setTaxes(tax.amount+'%');
        taxesApplied = (tax.amount / 100) * restrictedNum;
        if (isBuy){
            setTotal(restrictedNum+taxesApplied);
        }else{
            setTotal(restrictedNum-taxesApplied);
        }
    }else{
        setTaxes(tax.amount+'€');
        taxesApplied=tax.amount;
        let total = restrictedNum-taxesApplied;
        if (total<0){
            total=0;
        }
        setTotal(total);
    }

}
