import styles from "./ResaleTicket.module.css";
import {useMatches} from "react-router-dom";
import {Button, Checkbox, Col, ConfigProvider, Form, Input, InputNumber, message, Row, Space} from "antd";
import React, { useEffect, useState} from "react";
import arrowImage from "../../assets/arrow.svg";
import ImproveTicketBox from "../../components/improveTicketBox/ImproveTicketBox";
import {loadTicketDetails} from "../../solutions/controllers/TickeDetailsController";
import {useDispatch, useSelector} from "react-redux";
import {ITicketProfileDetails} from "../../solutions/interfaces/ITicketProfileDetails";
import warningImage from "../../assets/bi_exclamation.svg";
import {calculatePriceLessTax, calculateTax, resaleTicket} from "../../solutions/controllers/TicketResaleController";

import {ResaleTicketDTO} from "../../solutions/interfaces/DTO/ResaleTicketDTO";

export default function ResaleTicket(){
    const [form] = Form.useForm();
    const initialMatches: any = useMatches();
    const [originalData, setOriginalData] = useState<ITicketProfileDetails>();
    const dispatch = useDispatch();
    const [moneyToReceive,setMoneyToReceive] = useState<number>(0);
    const [taxes, setTaxes] = useState(0);
    const [priceInput, setPriceInput] = useState<string | number | null>('0');
    // @ts-ignore
    const auth = useSelector(state => state.auth);
    type FieldType = {
        priceResale?: number;
        bankAccount?: string;
        fullNameBankAccount?: string;
        bank?: string;
    };
    function goToProfile(){
        window.history.back();
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(auth && auth.logged && initialMatches[0].params.ticketId){
            // @ts-ignore
            loadTicketDetails(parseInt(initialMatches[0].params.ticketId, 10), auth, setOriginalData, (results) => {},dispatch);
        }
    }, [auth]);

    useEffect(()=>{
        if (originalData){
            if (!originalData.allowResale || originalData.status==='RESALE'){
                message.error('Esta entrada no se puede revender.');
            }
        }
    },[originalData]);
    useEffect(()=>{
        if (priceInput && originalData){
            if (typeof priceInput === "string") {
                calculateTax(setTaxes, originalData, parseInt(priceInput));
            }else{
                calculateTax(setTaxes, originalData, priceInput);
            }
        }
    },[priceInput]);
    useEffect(()=>{
        if (taxes && priceInput){
            if (typeof priceInput === "number") {
                calculatePriceLessTax(priceInput, taxes, setMoneyToReceive)
            }else{
                calculatePriceLessTax(parseInt(priceInput), taxes, setMoneyToReceive)
            }
        }
    },[taxes])


    const handlePriceInputChange = ((value:any) => {
            setPriceInput(value);
        }
    );

    function onResale() {
        if (originalData){
            form.validateFields().then(value => {
                const dto:ResaleTicketDTO = {
                    fullNameBankAccount:value.fullNameBankAccount,
                    bankAccount:value.bankAccount,
                    bank: value.bank,
                    ticketSoldId:originalData.ticketsoldid,
                    priceResale:value.priceResale
                }

                resaleTicket(originalData,dto, auth, (msg:string)=>{
                    if (msg===''){
                        message.success({
                            key: "msgKey",
                            onClick:(() => message.destroy('msgKey')),
                            content: <div>
                                <p style={{margin:0}}>Entrada puesta a la venta con éxito</p>
                                <p style={{margin:0}}>Puede retirarla de la reventa desde 'Mis entradas'</p>
                                <p style={{margin:0}}>Obtendrá confirmación cuando sea comprada y recibirá el dinero en su cuenta en un plazo máximo de 14 días.</p>
                                <p style={{margin:0}}>(Haga click en el mensaje para cerrarlo).</p>
                            </div>,
                            duration: 0,
                            style: {
                                marginTop: '20vh',
                            },
                        });
                        window.history.back();
                    }else{
                        message.error(msg);
                    }
                })
            }).catch(reason => {
                message.error("Debe rellenar todos los campos del formulario y aceptar los términos y condiciones para proceder al pago")
            })
        }
    }
    return (
        <div className={`blackContainer footerPaddingSeparation`}>
            {originalData && originalData.allowResale && originalData.status!=='RESALE'?(
                <>
                    <Row align={'middle'}>
                        <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                            <img src={arrowImage} alt="Arrow image" onClick={goToProfile} style={{cursor:'pointer'}}/>
                        </Col>
                        <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                            <h1 className={'comeBackTitle'}  onClick={goToProfile}>
                                VOLVER
                            </h1>
                        </Col>
                    </Row>

                    <Row justify={'center'}>
                        <Space direction={'vertical'}>
                            <h1 className={'defaultTitle'}>REVENDER ENTRADA</h1>
                            <Space direction={'horizontal'}>
                                <img src={warningImage} alt={'Warning image'} className={'warningIcon'}/>
                                <h1 className={'warningTitle'}>Advertencia</h1>
                            </Space>
                        </Space>
                    </Row>
                    <Row justify={'center'}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <p className={'warningMessage'} style={{margin:0}}>
                                Al poner la entrada a la venta, otro usuario puede comprarla.
                            </p>
                            <p className={'warningMessage'} style={{margin:0}}>
                                Obtendrá confirmación si se compra y su entrada dejará de ser válida.
                            </p>
                            <p className={'warningMessage'} style={{margin:0}}>
                            Una vez esté a la venta, podrá retirarla en cualquier momento.
                            </p>
                            <p className={'warningMessage'} style={{margin:0}}>
                            Si su entrada está en el carrito de alguien, no podremos retirarla a menos que abandone el carrito.
                            </p>
                        </Col>
                    </Row>


                    <Row justify={'center'} className={'defaultRowMarginTop'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={'dataSeparation'}></Col>
                    </Row>
                    <Row className={'defaultRowMarginTop defaultRowMarginBottom'} >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <h1 className={'defaultSubTitle'}>TU ENTRADA ES:</h1>
                        </Col>
                    </Row>

                    <ImproveTicketBox  name={originalData.ticketName} price={originalData.price} description={originalData.ticketDescription} isSelected={false}></ImproveTicketBox>
                    <Row justify={'center'} className={'defaultRowMarginTop'}>
                        <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={'dataSeparation'}></Col>
                    </Row>

                    <Row className={'defaultRowMarginTop'}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <h1 className={'defaultSubTitle'} >PRECIO DE VENTA</h1>
                        </Col>
                    </Row>
                    <Form
                        form={form}
                        name="resaleForm"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        style={{ width:'100%' }}
                        initialValues={{ remember: true }}
                        autoComplete="off"
                    >
                        <Row justify={'center'} className={'defaultRowMarginTop'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                    <ConfigProvider
                                        theme={{
                                            components: {
                                                InputNumber: {
                                                    colorError:'white',
                                                    colorText:'white',
                                                    algorithm: true,
                                                }
                                            },
                                        }}>
                                        <Form.Item<FieldType> label={<span className={'defaultLabel'}>
                                        {originalData.resaleminprice && originalData.resalemaxprice?
                                            `INTRODUZCA EL PRECIO DE VENTA ENTRE ${originalData.resaleminprice}€ Y ${originalData.resalemaxprice}€`:'INTRODUZCA EL PRECIO DE VENTA'}
                                    </span>}
                                                              name="priceResale"
                                                              style={{ width: '100%' }}
                                                              rules={[{ required: true, message: 'Debe rellenar el precio de venta' }]}
                                        >
                                        <InputNumber min={originalData.resaleminprice?originalData.resaleminprice:0}
                                                     max={originalData.resalemaxprice?originalData.resalemaxprice:undefined}
                                                     value={priceInput}
                                                     controls={false}
                                                     onChange={(e) => handlePriceInputChange(e)}
                                                     onInput={(e) => handlePriceInputChange(e)}
                                                     className={'defaultInput'} style={{color:'white'}}/>
                                        </Form.Item>
                                    </ConfigProvider>

                            </Col>
                        </Row>
                        {taxes?(
                        <Row justify={'center'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                    <h1 className={'warningMessage'}>REVENDER ESTA ENTRADA TIENE UNA COMISIÓN DE {taxes}€</h1>
                            </Col>
                        </Row>
                        ) :(<></>)}
                        <Row justify={'center'} className={'defaultRowMarginTop'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={'dataSeparation'}></Col>
                        </Row>
                        <Row style={{marginTop:50}}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <h1 className={'defaultSubTitle'}>DATOS DE COBRO</h1>
                            </Col>
                        </Row>
                        <Row justify={'center'} className={'defaultRowMarginTop'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={'defaultLabel'}>NÚMERO DE CUENTA BANCARIA (IBAN)</span>}
                                    name="bankAccount"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Debe rellenar el número de cuenta bancaria' }]}
                                >
                                    <Input className={'defaultInput'} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'} >
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={'defaultLabel'}>NOMBRE Y APELLIDOS DEL TITULAR</span>}
                                    name="fullNameBankAccount"
                                    style={{ width: '100%' }}
                                    rules={[{ required: true, message: 'Debe rellenar el nombre y los apellidos del titular' }]}
                                >
                                    <Input className={'defaultInput'} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'} >
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                                <Form.Item<FieldType>
                                    label={<span className={'defaultLabel'}>ENTIDAD BANCARIA</span>}
                                    name="bank"
                                    style={{ width: '100%' }}
                                >
                                    <Input className={'defaultInput'} placeholder={''} style={{width:'100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify={'center'} className={'defaultRowMarginTop'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={'dataSeparation'}></Col>
                        </Row>
                        <Row className={'defaultRowMarginTop'}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <h1 className={'defaultSubTitle'}>RESUMEN DE TU PEDIDO:</h1>
                            </Col>
                        </Row>
                        {taxes && moneyToReceive && priceInput ?(
                            <>
                                <Row justify={"center"}>
                                    <p className={styles.whiteTitle}>Precio de venta: {priceInput}€ </p>
                                </Row>
                                <Row justify={"center"}>
                                    <p className={styles.whiteTitle}>Comisión: {taxes}€ </p>
                                </Row>
                                <Row justify={"center"}>
                                    <p className={styles.whiteTitle}>Total a recibir: {moneyToReceive}€ </p>
                                </Row>
                            </>
                        ):(<></>)}
                        <Row justify={'center'} className={'defaultRowMarginTop'}>
                            <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={'dataSeparation'}></Col>
                        </Row>
                        <Row justify="center" className={'defaultRowMarginTop'}>
                            <Form.Item  label="" name="terms" valuePropName="checked" rules={[{required:true, message: 'Debe aceptar los términos y condiciones'}]}>
                                <Checkbox ><a style={{color: "white", fontSize: 14}} href={"/termAndConditions"}>Acepto los términos y condiciones de uso</a></Checkbox>
                            </Form.Item>
                        </Row>
                        <Row justify={'center'} style={{paddingBottom:0}}>
                            <Col xs={23} sm={20} md={18} lg={14} xl={10} xxl={6} >
                                <Button
                                    className={'defaultButton'}
                                    onClick={onResale}

                                >
                                    <span className={styles.buttonText}>CONTINUAR</span>
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </>
            ):(<></>)}
        </div>
    );
}