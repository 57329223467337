import styles from "./ResaleTicketPriceFilter.module.css";
import {Button, Col, ConfigProvider, FloatButton, InputNumber, Row} from "antd";
import React, {useEffect, useRef, useState} from "react";
import Modal from "react-modal";

export default function ResaleTicketPriceFilter({title,isOpen,onFilter,onClose,priceInput,setPriceInput}:
    {title:string,isOpen:boolean,onFilter:Function,onClose:Function,priceInput:string | number | null,setPriceInput:React.Dispatch<string | number | null>}){
    const inputNumberRef = useRef(null);
    function onOk(){
        if (priceInput || priceInput===0){
            onFilter(priceInput);
            onClose();
        }
    }
    function hiddeModal(){
        onClose();
    }

    return (
        <>
            <Modal
            isOpen={isOpen}
            className={styles.customModal}

            onRequestClose={hiddeModal}
            overlayClassName={styles.customOverlay}
            >
                <Row justify={'center'} align={'middle'} style={{marginTop:10,marginBottom:10}}>
                    <Col >
                        <h2 className={styles.modalTitle}>
                            {title}
                        </h2>
                    </Col>
                    <Col offset={2}>
                        <ConfigProvider
                            theme={{
                                components: {
                                    InputNumber: {
                                        hoverBorderColor:'black',
                                        handleHoverColor:'black',
                                        algorithm: true,
                                    }
                                },
                            }}
                        >
                            <InputNumber
                                autoFocus={true}
                                ref={inputNumberRef}
                                min={0}
                                value={priceInput}
                                onChange={setPriceInput}
                                controls={false}
                                className={styles.customInput}

                            />
                        </ConfigProvider>
                    </Col>
                </Row>
                <Row className={styles.buttonContainer}>
                    <Col span={12} className={styles.modalYesActions} >
                        <Button className={styles.resaleButton}   type="text" onClick={onOk}>
                            ACEPTAR
                        </Button>

                    </Col>
                    <Col span={12} className={styles.modalNoActions}>
                        <Button className={styles.resaleButton}  type="text" onClick={hiddeModal}>
                            CANCELAR
                        </Button>

                    </Col>
                </Row>

            </Modal>
        </>
    );
}