// export const serverName = localStorage.getItem("preferences.server") || window.location.hostname + ":8080";
export const serverName = window.location.hostname == "localhost" ? "http://localhost:8080" : window.location.hostname + ":8443";
export const server = serverName.startsWith("http") ? serverName + (serverName.endsWith("/") ? "" : "/") : "https://" + serverName + "/";

//export const server = "http://dev.tixxlab.com:8080/";

export const imagesPath = (serverName.includes("dev.tixxlab") || serverName.includes("localhost")) ? "https://dev.tixxlab.com/" : "https://www.tixxlab.com/";


export const CONFIG = {
    firstScreen: "Dashboard",
    restService: {
        restLoginUrl: server + "PhocusTXL/fullgraph/users/login",
        restRegistrationUrl: server + "phocus-kpi/fullgraph/phocusservice/register",
        restServiceRoot: server + "PhocusTXL/",
        kpiUrl: server + "phocus-kpi/",
        uiService: "minimalgraph/ui/",
        dataService: "minimalgraph/rest/",
        completeGraphDataService: "completegraph/rest/",
        fullGraphDataService: "fullgraph/rest/",
        uploadFileService: "completegraph/fileservice/",
        reportService: "completegraph/reportingService/",
        ws: "wss://" + (serverName.includes("dev.tixxlab") ? "dev.tixxlab.com:8443" : "www.tixxlab.com:8443") + "/PhocusTXL/phocus",
        wsKpi: "ws://" + serverName + "/phocus-kpi/phocus"

    }, firebaseSenderId: "313056414449"
}

export const CUSTOM_SERVICES_FOR_ENTITIES = {}

export const CUSTOM_SERVICES_FOR_REPORTS = {}

export const CUSTOM_FIELD_RENDERERS = {}
