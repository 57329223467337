import CarouselWeb from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Header from "../../components/header/Header";
import {Card, Carousel, Col, Row} from "antd";
import styles from './Events.module.css';
import React, { useEffect, useState} from 'react';
import {getEventAndCategories} from "../../solutions/controllers/EventController";
import {ICategory} from "../../solutions/interfaces/ICategory";
import {IEvent} from "../../solutions/interfaces/IEvent";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { formatDateToString} from "../../solutions/Utils";
import "./Carrousel.css";
import {filterEvents} from "../../solutions/controllers/FilterController";
import FilterEvents from "../../components/filterEvents/FilterEvents";
import OfferNews from "../../components/offerNews/OfferNews";
import {INewsType} from "../../solutions/interfaces/INewsType";
import {imagesPath} from "../../Controller/Settings";
const CarrouselMobile = ({ events, }: { events: IEvent[], })=>{

    return (
        <Carousel
            infinite={false}
            slidesToShow={events?.length > 2 ? 2.5 : events.length}
            slidesToScroll={1}
            dots={false}
        >
            {events.map((event, index) => (
                <Link to={"/eventDetails/"+event.id} key={event.id} className={styles.linkEvent} style={{ textDecoration: 'none', display: "flex", alignItems: "center", flexDirection: "column"}}>
                    <div style={{backgroundColor:'black', width: 180}}>
                        <img width={155} height={155} className={styles.eventImage}  alt="example" src={event ? (imagesPath + event.image) :''} />
                        <Row    >
                            <Col span={21} offset={2}>
                                <p className={styles.eventTitle} style={{marginLeft:0}}>{event.title} </p>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: 10}}>
                            <Col span={8} offset={2}>
                                <span className={styles.eventDate}>{formatDateToString(event.fromDate)}</span>
                            </Col>
                            <Col span={12} offset={2}>
                                <span className={styles.eventPrice} style={{marginLeft:0}}>DESDE {event.price}€</span>
                            </Col>
                        </Row>


                    </div>
                </Link>
            ))}

        </Carousel>
    );
}
const CarrouselEvents = ({ events }: { events: IEvent[] })=>{
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 9,
            slidesToSlide:7
        },
        desktop: {
            breakpoint: { max: 3000, min: 1555 },
            items: 7,
            slidesToSlide:7
        },
        tablet: {
            breakpoint: { max: 1555, min: 1100 },
            items: 5,
            slidesToSlide:5
        },
        midTable: {
            breakpoint: { max: 1100, min: 943 },
            items: 4,
            slidesToSlide:4
        },
        smallTablet: {
            breakpoint: { max: 943, min: 767 },
            items: 2,
            slidesToSlide:1
        },
        mobile: {
            breakpoint: { max: 767, min: 480 },
            items: 0,

        },
        smallMobile: {
            breakpoint: { max: 480, min: 0 },
            items: 0
        }
    };


    return (
        <CarouselWeb
            className={styles.carrousel}
            responsive={responsive}
            centerMode={false}
            swipeable={true}
            draggable={true}
            showDots={false}
            infinite={true}>
            {events.map((event, index) => (
                <Link to={"/eventDetails/"+event.id} key={event.id} style={{ textDecoration: 'none' }}>
                    <Card
                        key={event.id}
                        hoverable
                        className={styles.eventCard}
                        bodyStyle={{padding:0}}
                    >
                        <img width={180} height={180} className={styles.eventImage}  alt="example" src={event ? (imagesPath + event.image) :''} />

                        <Row>
                            <Col span={24}>
                                <p className={styles.eventTitle}>{event.title}</p>
                            </Col>
                        </Row>

                        <Row style={{marginLeft:10,marginRight:10,marginTop:0, justifyContent: "space-between"}}>
                            <span className={styles.eventDate}>{formatDateToString(event.fromDate)}</span>
                            <span className={styles.eventPrice}>DESDE {event.price}€</span>
                        </Row>
                    </Card>
                </Link>

            ))}
        </CarouselWeb>
    );

}
// @ts-nocheck
export default function Events(){
    const [originalEventData, setOriginalEventData] = useState<IEvent[]>([]);
    const [filterEventData, setFilterEventData] = useState<IEvent[]>(originalEventData);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const dispatch = useDispatch();
    const [filterValue, setFilterValue] = useState('');
    const [isFilterVisible, setIsFilterVisible] = useState(false);

    //@ts-ignore
    const auth = useSelector(state => state.auth);
    function onFilter(value: string) {
        setFilterValue(value);
    }



    useEffect(() => {
        getEventAndCategories(setOriginalEventData, setCategories, dispatch);
    }, []);


    useEffect(() =>{
        if (originalEventData && originalEventData.length>0){
            setFilterEventData(originalEventData);
        }

    }, [originalEventData])


    useEffect(() => {
        if (originalEventData){
            filterEvents(originalEventData,filterValue,setFilterEventData)
        }
    }, [filterValue, originalEventData]);





    return (
        <div className={'footerSeparation'}>
            <Row style={{paddingBottom:30, paddingTop: 0}} justify={'center'}>
                <Col span={24} style={{ position: 'relative' }}>
                    <OfferNews newsType={INewsType.EVENTS}></OfferNews>
                    <Row style={{marginTop:10}}></Row>
                    <Header isFilterVisible={isFilterVisible} setIsFilterVisible={setIsFilterVisible} showFilters={true} onFilter={onFilter} ></Header>

                    {/*{originalEventData && filterEventData && isFilterMenuVisible?(
                        <FilterModal  onClose={handleCloseFilterMenu}
                                      categories={categories}
                                      filteredEvents={filterEventData} setFilteredEvents={setFilterEventData}
                                      originalEvents={originalEventData} dispatch={dispatch}
                        ></FilterModal>
                    ):<></>}*/}

                </Col>
            </Row>
            {originalEventData && filterEventData && isFilterVisible?(
                <FilterEvents setFilteredEvents={setFilterEventData} originalEvents={originalEventData} dispatch={dispatch} filteredEvents={filterEventData} categories={categories}></FilterEvents>
            ):(<></>)}
            <Row justify={'center'}  style={{ height: '100%', paddingTop: 20}}>
                <h1 className={styles.categoryEventTitle}>EVENTOS DESTACADOS</h1>
            </Row>
            <div style={{marginLeft:20, marginRight:20, paddingBottom: 20}}>
                <CarrouselEvents events={filterEventData}></CarrouselEvents>
            </div>
            <Row justify={'center'}>
                <Col xs={{span:24}} sm={{span:24}} md={0} lg={0} xl={0} xxl={0}>
                    <CarrouselMobile events={filterEventData}></CarrouselMobile>
                </Col>
            </Row>
            {categories
                ? categories.map((category) => (
                    filterEventData.filter((e) => e.category.eventtypeid === category.eventtypeid).length > 0 ? (
                        <div style={{marginTop:30, paddingBottom: 20}}>
                            <Row justify={'center'} >
                                    <h1 className={styles.categoryEventTitle}>{category.eventtype}</h1>
                            </Row>
                            <div style={{marginLeft:20, marginRight:20}}>

                            <CarrouselEvents events={filterEventData.filter(e=>e.category.eventtypeid===category.eventtypeid)}></CarrouselEvents>
                            </div>
                            <Row justify={'center'}>
                                <Col xs={{span:22}} sm={{span:22}} md={0} lg={0} xl={0} xxl={0}>
                                    <CarrouselMobile events={filterEventData.filter(e=>e.category.eventtypeid===category.eventtypeid)}></CarrouselMobile>
                                </Col>
                            </Row>
                        </div>
                    ) : null
                ))
                : null}
        </div>
    );
}