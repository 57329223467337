import styles from './EditProfile.module.css';
import {Button, Carousel, Col, Form, Input, message, Row} from "antd";
import React, {useEffect, useState} from "react";
import DatePicker from "react-date-picker";
import {
    getProfile,
    updatePassword,
    updateProfileBackground,
    updateUser
} from "../../solutions/controllers/UserController";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {IUser} from "../../solutions/interfaces/IUser";
import editImage from "../../assets/white_edit_profile.svg";
import arrowImage from "../../assets/arrow.svg";
import {UpdateUserDTO} from "../../solutions/interfaces/DTO/UpdateUserDTO";
import {UpdateUserPasswordDTO} from "../../solutions/interfaces/DTO/UpdateUserPasswordDTO";
import image1 from "../../assets/profilePictures/fondo1.jpg";
import image2 from "../../assets/profilePictures/fondo2.jpg";
import image3 from "../../assets/profilePictures/fondo3.jpg";
import image4 from "../../assets/profilePictures/fondo4.jpg";
import image5 from "../../assets/profilePictures/fondo5.jpg";
import image6 from "../../assets/profilePictures/fondo6.jpg";
import image7 from "../../assets/profilePictures/fondo7.jpg";
import image8 from "../../assets/profilePictures/fondo8.jpg";
import image9 from "../../assets/profilePictures/fondo9.jpg";

type FieldType = {
    firstName?: string;
    birthdate?: string;
    email?: string;
    phoneNumber?: number;
    postalCode?: number;
    actualPassword?:string;
    password?:string;
    repeatPassword?:string;
};
export default function EditProfile(){
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const auth = useSelector((state : any) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState<number>(1);
    const [profile, setProfile] = useState<IUser>();
    const [editableFields, setEditableFields] = useState({
        email: false,
        firstName: false,
        birthdate: false,
        phoneNumber: false,
        postalCode: false,
    });


    function onUpdateProfile() {
        if (profile){
            form.validateFields().then(value => {
                const userDTO:UpdateUserDTO = {
                    userid:profile.userid,
                    profilePhoto:profile.profilePhoto,
                    name: value.firstName,
                    email: value.email,
                    birthdate: new Date(value.birthdate),
                    zipcode: value.postalCode,
                    phone: value.phoneNumber,
                }
                updateUser(auth,userDTO, (msg: string) =>{
                    if(msg === ""){
                        message.success("Perfil actualizado correctamente.");
                        getProfile(auth, setProfile, dispatch);
                    }
                    else{
                        message.error("Se ha producio un error durante la actualización del perfil: "+msg);
                    }
                }, dispatch);
            }).catch(reason => {
                message.error("Revise los errores en el formulario de los datos personales")
            })
        }else{
            message.error("Error al obtener el perfil.")
        }

    }
    function onUpdatePassword() {
        if (profile){
            passwordForm.validateFields().then(value => {
                const userPasswordDTO:UpdateUserPasswordDTO = {
                    userid:profile.userid,
                    actualPassword:value.actualPassword,
                    password:value.password,
                }
                updatePassword(auth,userPasswordDTO, (msg: string) =>{
                    if(msg === ""){
                        message.success("Contraseña actualizada correctamente.");

                    }
                    else{
                        message.error("Se ha producio un error durante la actualización de la contraseña: "+msg);
                    }
                }, dispatch);
            }).catch(reason => {
                message.error("Revise los errores en el formulario de la contraseña")
            })
        }else{
            message.error("Error al obtener el perfil.")
        }
    }
    function goToProfile(){
        window.history.back();
    }
    const handleEditClick = (field: keyof FieldType) => {
        setEditableFields((prevEditableFields) => ({
            ...prevEditableFields,
            [field]: true,
        }));
    };

    function onUpdatePhoto() {
        if (selectedImage) {
            if (profile && selectedImage){
                updateProfileBackground(profile.userid,selectedImage,(msg:string)=>{
                    if (msg===''){
                        message.success("Fondo de perfil actualizado correctamente.");
                    }else{
                        message.error("Error al actualizar el fondo de perfil "+msg);
                    }
                },dispatch);
            }
        } else {
            message.error("Debes seleccionar una imagen.");
        }
    }

    useEffect(() => {
        if(auth && auth.logged){
            getProfile(auth, setProfile, dispatch);
        }else{
            navigate('/');
        }
    }, [auth]);
    useEffect(()=>{
        if (profile){
            form.setFieldsValue({
                email: profile.email,
                firstName: profile.name,
                birthdate: profile.birthdate,
                phoneNumber: profile.phone,
                postalCode: profile.zipcode,
            });
        }
    },[profile]);

    const handleCarouselAfterChange = (currentSlide: number) => {
        setSelectedImage(currentSlide + 1);
    };

    return (
        <div className={styles.container}>
            <Row align={'middle'}>
                <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    <img src={arrowImage} alt="Arrow image" onClick={goToProfile} style={{cursor:'pointer'}}/>
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    <h1 className={styles.comeBackTitle}  onClick={goToProfile}>
                        VOLVER
                    </h1>
                </Col>


            </Row>
            <Row justify={'center'} >
                <h1 className={styles.title}>EDITAR MI PERFIL</h1>
            </Row>
            <Row justify={'center'}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10} className={styles.dataSeparation}></Col>
            </Row>

            <Row justify={'center'} >
                <h1 className={styles.subTitle} >CAMBIAR DATOS PERSONALES</h1>
            </Row>

            <Form
                form={form}
                name="updateProfileForm"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width:'100%' }}
                initialValues={{ remember: true }}
                autoComplete="off"
            >
                <Row justify={'center'}   align={'middle'} >

                    <Col xs={20} sm={20} md={14} lg={12} xl={10} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CORREO ELECTRÓNICO</span>}
                            name="email"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Por favor introduce un email válido', type:'email' }]}
                        >
                            <Input
                                   disabled={!editableFields.email}
                                   className={styles.input}
                                   placeholder={''}
                                   style={{width:'100%'}}
                            />

                        </Form.Item>
                    </Col>
                    <Col>
                        <img

                            src={editImage}
                            alt="Edit"
                            className={styles.editIcon}
                            onClick={() => handleEditClick('email')}
                        />
                    </Col>
                </Row>
                <Row justify={'center'} align={"middle"} >
                    <Col xs={20} sm={20} md={14} lg={12} xl={10} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>NOMBRE Y APELLIDOS</span>}
                            name="firstName"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Debe rellenar el campo nombre y apellidos' }]}
                        >
                            <Input disabled={!editableFields.firstName} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <img
                            onClick={() => message.warning("No está permitido el cambio de nombre")}
                            src={editImage}
                            alt="Edit"
                            className={styles.editIcon}
                        />
                    </Col>
                </Row>
                <Row justify={'center'} align={'middle'}>
                    <Col xs={20} sm={20} md={14} lg={12} xl={10} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>FECHA DE NACIMIENTO</span>}
                            name="birthdate"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Debe rellenar la fecha de nacimiento' }]}
                        >
                            <DatePicker disabled={!editableFields.birthdate} className={!editableFields.birthdate ? styles.datepickerDisabled : styles.datepicker} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <img
                            onClick={() => handleEditClick('birthdate')}
                            src={editImage}
                            alt="Edit"
                            className={styles.editIcon}
                        />
                    </Col>
                </Row>
                <Row justify={'center'}   align={'middle'}>
                    <Col xs={20} sm={20} md={14} lg={12} xl={10} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>TELÉFONO</span>}
                            name="phoneNumber"
                            style={{ width: '100%', color:'white' }}
                            rules={[{ required: true, message: 'Debe rellenar el número de teléfono' }]}
                        >
                            <Input disabled={!editableFields.phoneNumber} type={'number'} className={styles.input} placeholder={''} style={{width:'100%',color:'white'}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <img
                            onClick={() => handleEditClick('phoneNumber')}
                            src={editImage}
                            alt="Edit"
                            className={styles.editIcon}
                        />
                    </Col>
                </Row>
                <Row justify={'center'}   align={'middle'}>
                    <Col xs={20} sm={20} md={14} lg={12} xl={10} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CÓDIGO POSTAL</span>}
                            name="postalCode"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Debe rellenar el código postal' }]}
                        >
                            <Input disabled={!editableFields.postalCode} type={'number'}  className={styles.input} placeholder={''} style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <img
                            onClick={() => handleEditClick('postalCode')}
                            src={editImage}
                            alt="Edit"
                            className={styles.editIcon}
                        />
                    </Col>
                </Row>
                <Row justify={'center'} className={styles.buttonSeparation}>
                    <Col xs={20} sm={20} md={10} lg={6} xl={4} xxl={3}>
                        <Button type={'default'} className={'defaultButton'} onClick={onUpdateProfile}>EDITAR DATOS PERSONALES</Button>
                    </Col>
                </Row>
            </Form>
            <Row justify={'center'} >
                <Col xs={20} sm={20} md={14} lg={12} xl={11} xxl={8}  className={styles.dataSeparation}></Col>
            </Row>

            <Row justify={'center'} >
                <h1 className={styles.subTitle}>CAMBIAR CONTRASEÑA</h1>
            </Row>
            <Form
                form={passwordForm}
                name="updatePasswordForm"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width:'100%' }}
                initialValues={{ remember: true }}
                autoComplete="off"
            >
                <Row justify={'center'} >
                    <Col xs={20} sm={20} md={14} lg={12} xl={{span:10}} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CONTRASEÑA ACTUAL</span>}
                            name="actualPassword"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Debe rellenar la contraseña actual' }]}
                        >
                            <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row   justify={'center'} >
                    <Col xs={20} sm={20} md={14} lg={12} xl={{span:10}} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>CONTRASEÑA NUEVA</span>}
                            name="password"
                            style={{ width: '100%' }}
                            rules={[{ required: true, message: 'Debe rellenar la contraseña nueva' }]}
                        >
                            <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>


                </Row>
                <Row   justify={'center'} >
                    <Col xs={20} sm={20} md={14} lg={12} xl={{span:10}} xxl={8}   >
                        <Form.Item<FieldType>
                            label={<span className={styles.label}>REPITA CONTRASEÑA</span>}
                            name="repeatPassword"
                            style={{ width: '100%' }}
                            rules={[
                                { required: true, message: 'Debe repetir la contraseña' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Las contraseñas no coinciden'));
                                    },
                                })
                            ]}

                        >
                            <Input type={'password'} className={styles.input} placeholder={''} style={{width:'100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify={'center'} className={`${styles.buttonSeparation} footerPaddingSeparation`} style={{marginBottom:0}}>
                    <Col xs={20} sm={20} md={10} lg={6} xl={4} xxl={3}>
                        <Button type={'default'} className={'defaultButton'} onClick={onUpdatePassword} >CAMBIAR CONTRASEÑA</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}