import Header from "../../components/header/Header";
import {Button, Col, Row} from "antd";
import React from 'react';
import Modal from "react-modal";
import styles from "./BuySuccessError.module.css";
import errorImage from "../../assets/material-symbols-light_error-med.svg";
export default function BuyError(){

    return (
        <div  className={`${styles.buyErrorContainer} footerPaddingSeparation`}>
            <Row style={{paddingBottom:30, paddingTop: 30}} justify={'center'}>
                <Col span={24} style={{ position: 'relative' }}>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <Row justify={'center'}  >
                <Col xs={0} sm={0} md={18} lg={14} xl={10} xxl={6} className={styles.container} style={{height:'100%'}}>
                    <Row justify={'center'}>
                        <img src={errorImage} alt={'Error image'}/>
                    </Row>
                    <Row justify={'center'}>
                        <h1 className={styles.title} >SU PAGO HA SIDO RECHAZADO</h1>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <p className={styles.text} >LO SENTIMOS. PERO NO HEMOS PODIDO COMPLETAR SU PAGO. POR FAVOR, INTÉNTELO DE NUEVO MÁS TARDE.</p>
                        </Col>
                    </Row>
                </Col>
                <Col xs={22} sm={22} md={0} lg={0} xl={0} xxl={0} className={styles.container} style={{height:'100%'}}>
                    <Row justify={'center'}>
                        <img src={errorImage} alt={'Error image'}/>
                    </Row>
                    <Row justify={'center'}>
                        <h1 className={styles.smallTitle}>SU PAGO HA SIDO RECHAZADO</h1>
                    </Row>
                    <Row justify={'center'}>
                        <Col >
                            <p className={styles.smallText}>LO SENTIMOS. PERO NO HEMOS PODIDO COMPLETAR SU PAGO. POR FAVOR, INTÉNTELO DE NUEVO MÁS TARDE.</p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}