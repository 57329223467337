import {Button, Col, Collapse, CollapseProps, Form, Input, message, Row} from "antd";
import styles from "./Help.module.css";
import "./style.css";
import React, {useEffect, useState} from "react";
import Header from "../../components/header/Header";
import {rootServiceURL} from "../../Controller";
import RestClient from "../../Controller/rest/RestClient";
import {useDispatch, useSelector} from "react-redux";
import {IHelp} from "../../interfaces/IHelp";
import {MinusCircleOutlined, QuestionCircleOutlined} from "@ant-design/icons";

export default function Help(){
    const dispatch = useDispatch();

    const [helpList, setHelpList] = useState<IHelp[]>([])

    useEffect(() => {
        loadHelplist();

        return () => {
        };
    }, []);

    function loadHelplist () {
        const restClient = new RestClient(dispatch)
            .url(rootServiceURL() +"fullgraph/help/getHelp");
        restClient.doGet((result: any) => {
            console.log(result)
            result && setHelpList(result)
        });

    }

    const items: CollapseProps['items'] = helpList.map(item => {
        return ({
            key: item.helpid,
            label: <h2 className={styles.label}>{item.question}</h2>,
            children: <p className={styles.input}>{item.answer}</p>
        })
    })

    return (
        <div className={styles.container}>
            <Row style={{paddingBottom:30, paddingTop: 30}} justify={'center'}>
                <Col span={24}>
                    <Header showFilters={false}></Header>
                </Col>
            </Row>
            <Row align="middle" style={{justifyContent:"center"}}>
               {/* <Col xs={{span:3,offset:1}} sm={{span:2,offset:2}} md={{span:1,offset:2}} lg={{span:1,offset:2}} xl={{span:1,offset:2}} >
                    <img src={arrowImage} alt="Arrow image" onClick={goToLoginRegister} style={{cursor:'pointer'}}/>
                </Col>
                <Col xs={{span:4,offset:0}} sm={{span:2,offset:0}} md={{span:2,offset:0}} lg={{span:2,offset:0}}  xl={{span:2,offset:0}}>
                    <h1 className={styles.title} style={{ cursor:'pointer' }} onClick={goToLoginRegister}>
                        VOLVER
                    </h1>
                </Col>*/}

                <Col xs={{span:14,offset:1}} sm={{span:10,offset:1}} md={{span:10,offset:2}} lg={{span:10,offset:2}} xl={{span:10,offset:2}} style={{textAlign:"center", marginInlineStart:0}}>
                    <h1 className={styles.title}>AYUDA</h1>
                </Col>
            </Row>

            <Row justify={'center'} style={{marginTop:30, paddingBottom: 200}}>
                <Col xs={20} sm={18} md={16} lg={14} xl={12} xxl={10}>
                    <Collapse
                        expandIcon={({ isActive }) => isActive ? <MinusCircleOutlined style={{ fontSize: '1.5em'}} /> : <QuestionCircleOutlined style={{ fontSize: '1.5em'}}/>}
                        items={items} />;
                </Col>
            </Row>


        </div>
    );
}