import {Button, Col, Row} from "antd";
import Modal from "react-modal";
import React, {FC} from "react";
import styles from "./ImproveTicketMoreInfo.module.css";
interface ImproveTicketMoreInfoProps {
    isInfoVisible: boolean;
    onClose: () => void;
    info: string;
}
const ImproveTicketMoreInfo: FC<ImproveTicketMoreInfoProps> = ({ isInfoVisible, onClose, info }) => {
    return (
        <Modal
            isOpen={isInfoVisible}
            onRequestClose={onClose}
            className={styles.customModal}
            overlayClassName={styles.customOverlay}
        >
            <Row justify={'center'}>
                <Col span={22}>
                    <h2 className={styles.modalTitle}>
                        {info}
                    </h2>
                </Col>
            </Row>
            <Row className={styles.buttonContainer}>
                <Col span={24} className={styles.modalNoActions}>
                    <Button className={styles.resaleButton} type="text" onClick={onClose}>
                        CERRAR
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default ImproveTicketMoreInfo;